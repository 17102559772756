import { createSlice } from "@reduxjs/toolkit";

export const uiSlice = createSlice({
  name: "ui",
  initialState: {
    // isDateModalOpen: false,
    lang: "EN",
    blogPage: 1,
  },
  reducers: {
    onChangeLang: (state, { payload }) => {
      state.lang = payload;
    },
    onChangeBlogPage: (state, { payload }) => {
      state.blogPage = payload;
    },
  },
});

export const {
  onChangeLang,
  onChangeBlogPage
} = uiSlice.actions;
