import React from "react";
// import Banner from "../../assets/img/banner.jpg";
import { Link } from "react-router-dom";
import Banner from "../../assets/webp/banner3.webp";
import BannerMobile from "../../assets/webp/banner4.webp";
import BannerMedium from "../../assets/webp/banner5.webp";
import Grid from "@mui/material/Unstable_Grid2";
import { useUiStore } from "../../hooks/useUiStore";
import { Container, Box, Button, Typography } from "@mui/material";
import CallButtons from "../../components/CallButtons";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";

const FirstSection = () => {
  const { lang } = useUiStore();
  return (
    <>
      {/* SEO titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Blog
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Fast truck assistance
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        The roadside assistance you need
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        We offer our road service around this area 🚚
      </Typography>

      {/* Box Responsive Phones */}
      <Box
        alt="Banner 1"
        title="First Banner Landing Page"
        sx={{
          height: "auto",
          paddingBottom: 5,
          backgroundImage: `url(${BannerMobile})`,
          backgroundPosition: "center",
          display: {
            xs: "block",
            sm: "none",
            md: "none",
            lg: "none",
          },
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
            paddingTop: 5,
            marginLeft: 1,
            marginRight: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            mdOffset={6}
            md={6}
            lgOffset={6}
            lg={6}
            sx={{
              backgroundColor: "rgba(0,0,0,0.7)",
              // border: "8px dashed rgba(214,158,72,1)",
              color: "white",
              padding: 1,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                // border: "8px solid rgba(214,158,72,1)",
                // borderImage: 'linear-gradient(to left, #743ad5, #d53a9d)',
                borderWidth: "4px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, rgba(214,158,72,1), #E5E8E8) 1",
                padding: 3,
                borderRadius: 1,
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: 15.5,
                  transform: "scale(1.1, 1.5)",
                  fontFamily: "Ethnocentric",
                }}
              >
                CIOFFI EXPRESS SERVICES
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  fontSize: 20,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                {lang === "EN"
                  ? "Mobile Truck & Trailer Repair"
                  : "Reparación de camiones y remolques móviles"}
              </Typography>
              <Button
                variant="contained"
                href="tel:4076745769"
                sx={{
                  background: "none",
                  color: "#FFCB68",
                  fontWeight: "bold",
                  "&:hover": {
                    color: "white",
                    background: "transparent",
                  },
                  boxShadow: "none",
                  mt: 1,
                  mb: 2,
                }}
              >
                <PermPhoneMsgIcon sx={{ fontSize: 30, mr: 2 }} />
                <strong style={{ fontSize: 25 }}>407-674-5769</strong>
              </Button>
              <br />

              <Typography variant="body1" gutterBottom fontWeight={"bold"}>
                {lang === "EN"
                  ? "Call us today for a free quote!"
                  : "¡Llámenos hoy para una cotización gratis!"}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                fontWeight={"bold"}
                sx={{ color: "#FFCB68" }}
              >
                <Link
                  to="/location"
                  style={{
                    textDecoration: "none",
                    color: "#FFCB68",
                  }}
                >
                  {lang === "EN"
                    ? "150 miles around Orlando, 100 miles around Tampa & Jacksonville "
                    : "150 millas a la redonda de Orlando, 100 millas a la redonda de Tampa y Jacksonville"}
                </Link>
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                fontWeight={"bold"}
                sx={{ color: "#FFCB68" }}
              >
                <Link
                  to="/location"
                  style={{
                    textDecoration: "none",
                    color: "#FFCB68",
                  }}
                >
                  {lang === "EN"
                    ? "150 miles around Atlanta, 100 miles around Savannah"
                    : "150 millas a la redonda de Atlanta, 100 millas a la redonda de Savannah"}
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={"bold"}>
                {lang === "EN" ? (
                  <>
                    <span style={{ color: "#FFCB68" }}>24 Hour </span>Roadside
                    Assistance
                  </>
                ) : (
                  <>
                    Asistencia Vial{" "}
                    <span style={{ color: "#FFCB68" }}> 24 Horas</span>
                  </>
                )}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ marginBottom: 6 }}
                fontWeight={"bold"}
              >
                {lang === "EN"
                  ? "Fast Truck Assistance"
                  : "Asistencia Rápida a Camiones"}
              </Typography>
              <CallButtons />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Box Responsive Tablets */}
      <Box
        alt="Banner 3"
        title="First Banner Landing Page"
        sx={{
          height: "auto",
          paddingBottom: 5,
          backgroundImage: `url(${BannerMedium})`,
          backgroundPosition: "top center",
          display: {
            xs: "none",
            sm: "block",
            md: "block",
            lg: "none",
          },
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
            paddingTop: 5,
            marginLeft: 1,
            marginRight: 1,
          }}
        >
          <Grid
            item
            xs={12}
            sm={12}
            mdOffset={6}
            md={6}
            lgOffset={6}
            lg={6}
            sx={{
              backgroundColor: "rgba(0,0,0,0.7)",
              // border: "8px dashed rgba(214,158,72,1)",
              color: "white",
              padding: 1,
              borderRadius: 1,
            }}
          >
            <Box
              sx={{
                // border: "8px solid rgba(214,158,72,1)",
                // borderImage: 'linear-gradient(to left, #743ad5, #d53a9d)',
                borderWidth: "4px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, rgba(214,158,72,1), #E5E8E8) 1",
                padding: 3,
                borderRadius: 1,
                textAlign: "center",
              }}
            >
              <Typography
                variant="body1"
                gutterBottom
                sx={{
                  fontSize: 15.5,
                  transform: "scale(1.1, 1.5)",
                  fontFamily: "Ethnocentric",
                }}
              >
                CIOFFI EXPRESS SERVICES
              </Typography>
              <Typography
                variant="body2"
                gutterBottom
                sx={{
                  fontSize: 20,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                {lang === "EN"
                  ? "Mobile Truck & Trailer Repair"
                  : "Reparación de camiones y remolques móviles"}
              </Typography>
              <Button
                variant="contained"
                href="tel:4076745769"
                sx={{
                  background: "none",
                  color: "#FFCB68",
                  fontWeight: "bold",
                  "&:hover": {
                    color: "white",
                    background: "transparent",
                  },
                  boxShadow: "none",
                  mt: 1,
                  mb: 2,
                }}
              >
                <PermPhoneMsgIcon sx={{ fontSize: 30, mr: 2 }} />
                <strong style={{ fontSize: 25 }}>407-674-5769</strong>
              </Button>
              <br />

              <Typography variant="body1" gutterBottom fontWeight={"bold"}>
                {lang === "EN"
                  ? "Call us today for a free quote!"
                  : "¡Llámenos hoy para una cotización gratis!"}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                fontWeight={"bold"}
                sx={{ color: "#FFCB68" }}
              >
                <Link
                  to="/location"
                  style={{
                    textDecoration: "none",
                    color: "#FFCB68",
                  }}
                >
                 {lang === "EN"
                    ? "150 miles around Orlando, 100 miles around Tampa & Jacksonville "
                    : "150 millas a la redonda de Orlando, 100 millas a la redonda de Tampa y Jacksonville"}
                </Link>
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                fontWeight={"bold"}
                sx={{ color: "#FFCB68" }}
              >
                <Link
                  to="/location"
                  style={{
                    textDecoration: "none",
                    color: "#FFCB68",
                  }}
                >
                 {lang === "EN"
                    ? "150 miles around Atlanta, 100 miles around Savannah"
                    : "150 millas a la redonda de Atlanta, 100 millas a la redonda de Savannah"}
                </Link>
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={"bold"}>
                {lang === "EN"
                  ? "Call us today for a free quote!"
                  : "¡Llámenos hoy para una cotización gratis!"}
              </Typography>
              <Typography variant="body1" gutterBottom fontWeight={"bold"}>
                {lang === "EN" ? (
                  <>
                    <span style={{ color: "#FFCB68" }}>24 Hour </span>Roadside
                    Assistance
                  </>
                ) : (
                  <>
                    Asistencia Vial{" "}
                    <span style={{ color: "#FFCB68" }}> 24 Horas</span>
                  </>
                )}
              </Typography>
              <Typography
                variant="body1"
                gutterBottom
                sx={{ marginBottom: 6 }}
                fontWeight={"bold"}
              >
                {lang === "EN"
                  ? "Fast Truck Assistance"
                  : "Asistencia Rápida a Camiones"}
              </Typography>
              <CallButtons />
            </Box>
          </Grid>
        </Grid>
      </Box>

      {/* Box Responsive Desktop */}
      <Box
        alt="Banner 1"
        title="Fisrt Banner Landing Page"
        sx={{
          minWidth: "100%",
          maxWidth: "100%",
          height: "auto",
          paddingBottom: 10,
          backgroundImage: `url(${Banner})`,
          backgroundPosition: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "none",
            lg: "block",
          },
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              flexGrow: 1,
              paddingTop: 12,
            }}
          >
            <Grid
              item
              xs={12}
              sm={12}
              mdOffset={6}
              md={6}
              lgOffset={6}
              lg={6}
              sx={{
                backgroundColor: "rgba(0,0,0,0.7)",
                // border: "8px dashed rgba(214,158,72,1)",
                color: "white",
                padding: 1,
                borderRadius: 1,
              }}
            >
              <Box
                sx={{
                  // border: "8px solid rgba(214,158,72,1)",
                  // borderImage: 'linear-gradient(to left, #743ad5, #d53a9d)',
                  borderWidth: "4px",
                  borderStyle: "solid",
                  borderImage:
                    "linear-gradient(to right, rgba(214,158,72,1), #E5E8E8) 1",
                  padding: 5,
                  borderRadius: 1,
                  textAlign: "center",
                }}
              >
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ fontFamily: "Ethnocentric", fontSize: 24 }}
                >
                  CIOFFI EXPRESS SERVICES
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{
                    fontSize: 25,
                    fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                  }}
                >
                  {lang === "EN"
                    ? "Mobile Truck & Trailer Repair"
                    : "Reparación de camiones y remolques móviles"}
                </Typography>
                <Button
                  variant="contained"
                  href="tel:4076745769"
                  sx={{
                    background: "none",
                    color: "#FFCB68",
                    fontWeight: "bold",
                    "&:hover": {
                      color: "white",
                      background: "transparent",
                    },
                    boxShadow: "none",
                    mt: 1,
                    mb: 2,
                  }}
                >
                  <PermPhoneMsgIcon sx={{ fontSize: 40, mr: 2 }} />
                  <strong style={{ fontSize: 30 }}>407-674-5769</strong>
                </Button>
                <br />

                <Typography variant="subtitle1" gutterBottom>
                  {lang === "EN"
                    ? "Call us today for a free quote!"
                    : "¡Llámenos hoy para una cotización gratis!"}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "rgba(214,158,72,1)" }}
                >
                  <Link
                    to="/location"
                    style={{
                      textDecoration: "none",
                      color: "rgba(214,158,72,1)",
                    }}
                  >
                    {lang === "EN"
                    ? "150 miles around Orlando, 100 miles around Tampa & Jacksonville "
                    : "150 millas a la redonda de Orlando, 100 millas a la redonda de Tampa y Jacksonville"}
                  </Link>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "rgba(214,158,72,1)" }}
                >
                  <Link
                    to="/location"
                    style={{
                      textDecoration: "none",
                      color: "rgba(214,158,72,1)",
                    }}
                  >
                    {lang === "EN"
                    ? "150 miles around Atlanta, 100 miles around Savannah"
                    : "150 millas a la redonda de Atlanta, 100 millas a la redonda de Savannah"}
                  </Link>
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ color: "rgba(214,158,72,1)" }}
                >
                  {lang === "EN"
                    ? "24 Hour Roadside Assistance"
                    : "Asistencia Vial 24 Horas"}
                </Typography>
                <Typography
                  variant="body1"
                  gutterBottom
                  sx={{ marginBottom: 6 }}
                >
                  {lang === "EN"
                    ? "Fast Truck Assistance"
                    : "Asistencia Rápida a Camiones"}
                </Typography>
                <CallButtons />
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FirstSection;
