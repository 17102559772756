import React from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Banner3 from "../../assets/webp/banner3.webp";
import WrongPreventive from "../../assets/webp/wrong-preventive.webp";
import EquipTruck from "../../assets/webp/equip-truck.webp";
import TruckMaintenance from "../../assets/webp/truck-maintenance.webp";
import Tires from "../../assets/webp/tires.webp";
import TiresWallpaper from "../../assets/webp/tires-wallpaper.webp";
import Mechanics from "../../assets/webp/mechanics.webp";
import Mechanics2 from "../../assets/webp/mechanics2.webp";
import FullTruckLoad from "../../assets/webp/full-truck-load.webp";
import TruckNight from "../../assets/webp/truck-night.webp";
import CESLogo from "../../assets/webp/Trucking-Logo-08.webp";
import BestTruckRepairOrlando from "../../assets/webp/best-truck-repair-orlando.webp";
import RedTriangle from "../../assets/webp/red-triangle.webp";
import MetalFloor from "../../assets/webp/metal-floor-pattern.webp";
import TruckMaintenance2 from "../../assets/webp/truck-maintenance.webp";
import TruckDriver from "../../assets/webp/truck-driver.webp";
import FreightBrokerDispatcher from "../../assets/webp/freight-broker-vs-dispatcher.webp";
import ComputerDiagnostics from "../../assets/carousel/11.webp";
import HydraulicRepair from "../../assets/carousel/5.webp";
import RVServices from "../../assets/carousel/8.webp";
import JumpstartService from "../../assets/carousel/7.webp";
import BatteryReplacement from "../../assets/carousel/4.webp";
import WheelsRepair from "../../assets/carousel/25.webp";
import BearingsService from "../../assets/carousel/31.webp";
import OilChange from "../../assets/carousel/27.webp";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
} from "@mui/material";
import { Link } from "react-router-dom";
import Image28 from "../../assets/carousel/28.webp";
import Image33 from "../../assets/carousel/33.webp";
import Image5 from "../../assets/carousel/5.webp";
import Image6 from "../../assets/carousel/6.webp";
import Image7 from "../../assets/carousel/7.webp";

const ServiceArticleSection = ({ articleInfo }) => {
  const { lang } = useUiStore();
  return (
    <>
      <Helmet>
        <link
          href={"https://cestruckrepair.com/services/" + articleInfo.url}
          rel="canonical"
        />
        <title>
          {`${
            lang === "EN"
              ? articleInfo.eng_metatitle
              : articleInfo.esp_metatitle
          }`}{" "}
          | CES Mobile Truck & Trailer Repair 🚚
        </title>
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? articleInfo.eng_metadescription
              : articleInfo.esp_metadescription
          }`}
        />
      </Helmet>

      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
      </Typography>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* Títulos */}
        <Grid container sx={{ flexGrow: 1 }} spacing={1}>
          {/* Title */}
          <Grid item xs={12} textAlign="center" alignItems="center">
            {/* Desktop View */}
            <Box
              component="img"
              alt="Desktop banner"
              title="Desktop banner"
              m="auto"
              sx={{
                width: 0.35,
                height: 300,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
              src={
                articleInfo.position === 0
                  ? TruckNight
                  : articleInfo.position === 1
                  ? Mechanics2
                  : articleInfo.position === 2
                  ? FullTruckLoad
                  : articleInfo.position === 3
                  ? Banner3
                  : articleInfo.position === 4
                  ? Tires
                  : articleInfo.position === 5
                  ? Mechanics
                  : articleInfo.position === 6
                  ? TiresWallpaper
                  : articleInfo.position === 7
                  ? BestTruckRepairOrlando
                  : articleInfo.position === 8
                  ? RedTriangle
                  : articleInfo.position === 9
                  ? MetalFloor
                  : articleInfo.position === 10
                  ? TruckMaintenance2
                  : articleInfo.position === 11
                  ? TruckDriver
                  : articleInfo.position === 12
                  ? FreightBrokerDispatcher
                  : articleInfo.position === 13
                  ? ComputerDiagnostics
                  : articleInfo.position === 14
                  ? HydraulicRepair
                  : articleInfo.position === 15
                  ? RVServices
                  : articleInfo.position === 16
                  ? JumpstartService
                  : articleInfo.position === 17
                  ? BatteryReplacement
                  : articleInfo.position === 18
                  ? WheelsRepair
                  : articleInfo.position === 19
                  ? BearingsService
                  : articleInfo.position === 20
                  ? OilChange
                  : articleInfo.position === 21
                  ? Image7
                  : articleInfo.position === 22
                  ? Image5
                  : articleInfo.position === 23
                  ? Image6
                  : articleInfo.position === 24
                  ? Image28
                  : articleInfo.position === 25
                  ? Image33
                  : articleInfo.position === 26
                  ? Tires
                  : Tires
              }
            />
            {/* Mobile View */}
            <Box
              component="img"
              alt="Mobile banner"
              title="Mobile banner"
              sx={{
                width: 1,
                height: 200,
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
              src={
                articleInfo.position === 0
                  ? TruckNight
                  : articleInfo.position === 1
                  ? Mechanics2
                  : articleInfo.position === 2
                  ? FullTruckLoad
                  : articleInfo.position === 3
                  ? Banner3
                  : articleInfo.position === 4
                  ? Tires
                  : articleInfo.position === 5
                  ? Mechanics
                  : articleInfo.position === 6
                  ? TiresWallpaper
                  : articleInfo.position === 7
                  ? BestTruckRepairOrlando
                  : articleInfo.position === 8
                  ? RedTriangle
                  : articleInfo.position === 9
                  ? MetalFloor
                  : articleInfo.position === 10
                  ? TruckMaintenance2
                  : articleInfo.position === 11
                  ? TruckDriver
                  : articleInfo.position === 12
                  ? FreightBrokerDispatcher
                  : articleInfo.position === 13
                  ? ComputerDiagnostics
                  : articleInfo.position === 14
                  ? HydraulicRepair
                  : articleInfo.position === 15
                  ? RVServices
                  : articleInfo.position === 16
                  ? JumpstartService
                  : articleInfo.position === 17
                  ? BatteryReplacement
                  : articleInfo.position === 18
                  ? WheelsRepair
                  : articleInfo.position === 19
                  ? BearingsService
                  : articleInfo.position === 20
                  ? OilChange
                  : articleInfo.position === 21
                  ? Image7
                  : articleInfo.position === 22
                  ? Image5
                  : articleInfo.position === 23
                  ? Image6
                  : articleInfo.position === 24
                  ? Image28
                  : articleInfo.position === 25
                  ? Image33
                  : articleInfo.position === 26
                  ? Tires
                  : Tires
              }
            />
          </Grid>

          <Grid item xs={12} textAlign="center" sx={{ mt: 3 }}>
            <Typography
              variant="p"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
              }}
            >
              {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
            </Typography>
            <Typography
              variant="p"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                },
              }}
            >
              {lang === "EN" ? articleInfo.eng_title : articleInfo.esp_title}
            </Typography>
          </Grid>

          <Grid item xs={12} className="mt-5">
            {/* <Typography variant="body1">{articleInfo.text}</Typography> */}
            <div
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN" ? articleInfo.eng_text : articleInfo.esp_text,
              }}
            />
          </Grid>

          <Grid item xs={12}>
            <hr />
            <a
              href="tel:4076745769"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Typography variant="overline">
                {lang === "EN" ? (
                  <b>
                    ⚠️ If you are in Orlando, Tampa or Jacksonville in Florida,
                    or Atlanta, Savannah in Georgia and your truck stopped due
                    to a mechanical or electrical breakdown, we can help you.
                    <b style={{ color: "#D69E48" }}>
                      {" "}
                      Call Cioffi Express Services
                    </b>{" "}
                    at (407) 674-5769 and our technicians will come to the
                    rescue. <br />
                    <br />
                    At Cioffi Express we provide{" "}
                    <b style={{ color: "#D69E48" }}>
                      mobile repair service to trucks and trailers in Florida
                      and Georgia
                    </b>
                    .
                  </b>
                ) : (
                  <b>
                    ⚠️ Si estás en las ciudades de Orlando, Tampa o Jacksonville
                    en Florida, o Atlanta, Savannah en Georgia y tu camión se
                    detuvo por una avería mecánica o eléctrica, te podemos
                    ayudar.{" "}
                    <b style={{ color: "#D69E48" }}>
                      Llama al servicio de atención al cliente de Cioffi Express
                      Services
                    </b>{" "}
                    al (407) 674-5769 y nuestros mecánicos irán al rescate.{" "}
                    <br />
                    <br />
                    En Cioffi Express brindamos servicio móvil de{" "}
                    <b style={{ color: "#D69E48" }}>
                      reparación a camiones y remolques en Florida y Georgia
                    </b>
                  </b>
                )}
              </Typography>
            </a>
            <hr />
          </Grid>

          <Grid item xs={12} textAlign="center" className="mt-5">
            <Link
              to={"/services"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>{"< "}&nbsp; Back to services</>
                ) : (
                  <>{"< "}&nbsp; Volver a servicios</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ServiceArticleSection;
