import React from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardMedia,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import GalleryPicture1 from "../../assets/carousel/1.webp";
import GalleryPicture2 from "../../assets/carousel/2.webp";
import GalleryPicture4 from "../../assets/carousel/4.webp";
import GalleryPicture5 from "../../assets/carousel/5.webp";
import GalleryPicture6 from "../../assets/carousel/6.webp";
import GalleryPicture7 from "../../assets/carousel/7.webp";
import GalleryPicture8 from "../../assets/carousel/8.webp";
import GalleryPicture9 from "../../assets/carousel/9.webp";
import GalleryPicture10 from "../../assets/carousel/10.webp";
import GalleryPicture11 from "../../assets/carousel/11.webp";
import GalleryPicture12 from "../../assets/carousel/12.webp";
import GalleryPicture13 from "../../assets/carousel/13.webp";
import GalleryPicture14 from "../../assets/carousel/14.webp";
import GalleryPicture15 from "../../assets/carousel/15.webp";
import GalleryPicture16 from "../../assets/carousel/16.webp";
import GalleryPicture17 from "../../assets/carousel/17.webp";
import GalleryPicture18 from "../../assets/carousel/18.webp";
import GalleryPicture19 from "../../assets/carousel/19.webp";
import GalleryPicture20 from "../../assets/carousel/20.webp";
import GalleryPicture21 from "../../assets/carousel/21.webp";
import GalleryPicture22 from "../../assets/carousel/22.webp";
import GalleryPicture23 from "../../assets/carousel/23.webp";
import GalleryPicture24 from "../../assets/carousel/24.webp";
import GalleryPicture25 from "../../assets/carousel/25.webp";
import GalleryPicture26 from "../../assets/carousel/26.webp";
import GalleryPicture27 from "../../assets/carousel/27.webp";
import GalleryPicture28 from "../../assets/carousel/28.webp";
import GalleryPicture29 from "../../assets/carousel/29.webp";
import GalleryPicture30 from "../../assets/carousel/30.webp";
import GalleryPicture31 from "../../assets/carousel/31.webp";
import GalleryPicture32 from "../../assets/carousel/32.webp";

const GallerySection = () => {
  const { lang } = useUiStore();
  const images = [
    {
      src: GalleryPicture1,
      alt: "Gallery Picture 1",
    },
    {
      src: GalleryPicture2,
      alt: "Gallery Picture 2",
    },
    {
      src: GalleryPicture4,
      alt: "Gallery Picture 4",
    },
    {
      src: GalleryPicture5,
      alt: "Gallery Picture 5",
    },
    {
      src: GalleryPicture6,
      alt: "Gallery Picture 6",
    },
    {
      src: GalleryPicture7,
      alt: "Gallery Picture 7",
    },
    {
      src: GalleryPicture8,
      alt: "Gallery Picture 8",
    },
    {
      src: GalleryPicture9,
      alt: "Gallery Picture 9",
    },
    {
      src: GalleryPicture10,
      alt: "Gallery Picture 10",
    },
    {
      src: GalleryPicture11,
      alt: "Gallery Picture 11",
    },
    {
      src: GalleryPicture12,
      alt: "Gallery Picture 12",
    },
    {
      src: GalleryPicture18,
      alt: "Gallery Picture 18",
    },
    {
      src: GalleryPicture19,
      alt: "Gallery Picture 19",
    },
    {
      src: GalleryPicture20,
      alt: "Gallery Picture 20",
    },
    {
      src: GalleryPicture21,
      alt: "Gallery Picture 21",
    },
    {
      src: GalleryPicture22,
      alt: "Gallery Picture 22",
    },
    {
      src: GalleryPicture23,
      alt: "Gallery Picture 23",
    },
  ];

  const images2 = [
    {
      src: GalleryPicture13,
      alt: "Gallery Picture 13",
    },
    {
      src: GalleryPicture14,
      alt: "Gallery Picture 14",
    },
    {
      src: GalleryPicture15,
      alt: "Gallery Picture 15",
    },
    {
      src: GalleryPicture16,
      alt: "Gallery Picture 16",
    },
    {
      src: GalleryPicture17,
      alt: "Gallery Picture 17",
    },
    {
      src: GalleryPicture24,
      alt: "Gallery Picture 24",
    },
    {
      src: GalleryPicture25,
      alt: "Gallery Picture 25",
    },
    {
      src: GalleryPicture26,
      alt: "Gallery Picture 26",
    },
    {
      src: GalleryPicture27,
      alt: "Gallery Picture 27",
    },
    {
      src: GalleryPicture28,
      alt: "Gallery Picture 28",
    },
    {
      src: GalleryPicture29,
      alt: "Gallery Picture 29",
    },
    {
      src: GalleryPicture30,
      alt: "Gallery Picture 30",
    },
    {
      src: GalleryPicture31,
      alt: "Gallery Picture 31",
    },
    {
      src: GalleryPicture32,
      alt: "Gallery Picture 32",
    },
  ];

  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Gallery
      </Typography>
      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        {/* Títulos */}
        <Grid container sx={{ flexGrow: 1 }} spacing={1}>
          {/* Title */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric"
              }}
            >
              {lang === "EN" ? "Gallery" : "Galería"}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric"
                },
              }}
            >
              {lang === "EN" ? "Gallery" : "Galería"}
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
            {/* Subtitle Desktop View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                mb: 10,
                fontSize: 25
              }}
            >
              {lang === "EN" ? (
                <>
                  Look into our{" "}
                  <b style={{ color: "#D69E48" }}>
                    immediate roadside assistance
                  </b>
                </>
              ) : (
                <>
                  Conoce nuestra{" "}
                  <b style={{ color: "#D69E48" }}>asistencia vial inmediata</b>
                </>
              )}
            </Typography>

            {/* Subtitle Tablet and Phone View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                mb: 5,
              }}
            >
              {lang === "EN" ? (
                <>
                  Look into our{" "}
                  <b style={{ color: "#D69E48" }}>
                    immediate roadside assistance
                  </b>
                </>
              ) : (
                <>
                  Conoce nuestra{" "}
                  <b style={{ color: "#D69E48" }}>asistencia vial inmediata</b>
                </>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mb: 5 }}>
              {lang === "EN"
                ? "If you are looking for the best option to repair your truck or trailer, you are in the right place. We understand that after long hours on the road, a technical or mechanical failure can take you by surprise at any time."
                : "Si buscas la mejor opción para reparar tu camión o remolque, estás en el sitio correcto. Entendemos que tras largas horas en carretera, una falla técnica o mecánica puede tomarte de imprevisto en cualquier momento."}
            </Typography>
          </Grid>

          {/* Mobile CES Carousel */}
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Carousel images={images} style={{ height: 300, width: "100%" }} />
          </Grid>

          {/* Desktop CES Carousel */}
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Carousel images={images} style={{ height: 500, width: "100%" }} />
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1" sx={{ mt: 5, mb: 2, fontSize: 25, fontWeight: "bold" }}>
              {lang === "EN" ? "Before and after" : "Antes y después"}
            </Typography>
            <Typography variant="body1">
              {lang === "EN"
                ? "The Cioffi Express business ethic is based on transparency, excellence and efficiency. Our heavy-duty vehicle repair services make your life easier at a lower price. The idea is to help you keep your truck in good shape and ready for adventure."
                : "La ética empresarial de Cioffi Express está cimentada en la transparencia, excelencia y eficiencia. Nuestros servicios de reparación de vehículos de carga pesada te hacen la vida más sencilla a un menor precio. La idea es ayudarte a mantener tu camión en buen estado y listo para la aventura."}
            </Typography>
          </Grid>

          {/* Mobile Before & After Carousel */}
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              mt: 5,
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Carousel images={images2} style={{ height: 300, width: "100%" }} />
          </Grid>

          {/* Desktop Before & After Carousel */}
          <Grid
            item
            xs={12}
            textAlign="center"
            sx={{
              mt: 5,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Carousel images={images2} style={{ height: 500, width: "100%" }} />
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default GallerySection;
