import React from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../../hooks/useUiStore";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { Box, Container, Grid, Typography } from "@mui/material";
import CallButtons from "../../../components/CallButtons";
import RedTriangle from "../../../assets/webp/red-triangle.webp";
import RedTriangle2 from "../../../assets/webp/red-triangle-2.webp";
import CESLOGO from "../../../assets/webp/Trucking-Logo-08.webp";
import Tires from "../../../assets/webp/tires.webp";
import ArticleFooter from "../../../components/ArticleFooter";

const Kissimmee = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/kissimmee" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Find out the costs for semi-truck tire service in Kissimmee, FL. Find the best rates and options."
              : "Descubre los costos del servicio de neumáticos para semi truck en Kissimmee, FL. Encuentra las mejores tarifas y opciones."
          }`}
        />
        <title>Semi-truck Tire Service in Kissimmee, FL</title>
      </Helmet>
      <Navbar />
      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          Kissimmee
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle2});`,
          backgroundPosition: "center right",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          Kissimmee
        </Typography>
        <CallButtons />
      </Box>

      {/* Body */}
      <Box>
        <Container sx={{ mt: 10, mb: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: 40,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                How Much Does Semi-truck Service In Kissimmee, FL Cost? Top 2
                Options
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {lang === "EN" ? (
                <>
                  <p>
                    Find the cost of semi-truck tire service in Kissimmee by
                    contacting CES Truck Services at 407 674 5769 or{" "}
                    <a target="_blank" href="https://cestruckrepair.com/">
                      visiting their website
                    </a>{" "}
                    to request a free quote.
                  </p>
                  <p>
                    Here's everything you need to know about the companies that
                    offer tire{" "}
                    <a
                      target="_blank"
                      href="https://www.samsara.com/guides/preventive-maintenance-checklist-for-semi-trucks/?gad_source=1&gclid=CjwKCAjw88yxBhBWEiwA7cm6peit8v5KtOroM1N21NKqvbsv6SgnPYLgz06mkDMBy8DMS4m6I-K8KxoCXkgQAvD_BwE"
                    >
                      maintenance and mobile semi-truck repair
                    </a>{" "}
                    in Kissimmee. Discover the current prices and available
                    options to keep your semi-truck's tires in top condition.
                  </p>
                  <h2>
                    2 Options Of Semi-truck Tire Service In Kissimmee, FL And
                    Their Prices
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    Explore top semi-truck tire service locations in Kissimmee
                    and discover if they offer fast and reliable service. Get
                    fast and efficient answers to any issue your semi-truck has
                    on the road, 24 hours a day.
                  </p>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.6,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.3,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <h3>1. Cioffi Express Services</h3>
                  <p style={{ paddingTop: 15 }}>
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/about-us"
                    >
                      Cioffi Express Services
                    </a>{" "}
                    is a{" "}
                    <b>
                      family owned and operated mobile semi-truck and trailer
                      repair company with three generations of experience
                    </b>{" "}
                    in heavy vehicle care. They offer the best service in
                    semi-truck assistance in Kissimmee. Their priority is
                    customer satisfaction. They have a superior technical team
                    that guarantees a high quality experience in every service.
                  </p>
                  <p>
                    They service all makes and models of semi-trucks and
                    trailers and provide second opinions on quotes to ensure
                    transparency and trust.{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/services"
                    >
                      Contact CES Truck Services today
                    </a>{" "}
                    <b>for a free, reliable quote in Kissimmee</b>!
                  </p>
                  <h3>2. Quality Lugo Services</h3>
                  <p style={{ paddingTop: 15 }}>
                    Quality Lugo Services{" "}
                    <b>
                      offers tire-changing services for semi-trucks and trailers
                    </b>
                    , from repairs to complete installations. They have
                    different types of tires to suit all needs and road
                    conditions. They work closely with you every step of the way
                    and ensure you fully understand your vehicle's needs.
                  </p>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Tires}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <h2 style={{ paddingTop: 30 }}>
                    What Services Are Available At Cioffi Express Services?
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    Among the services offered by this company, the following
                    stand out:
                  </p>
                  <ul>
                    <li>Roadside assistance emergency.</li>
                    <li>Hydraulic repair.</li>
                    <li>Wheels repair near me.</li>
                    <li>Mobile semi-truck repair in Kissimmee.</li>
                    <li>Mobile tire repair.</li>
                    <li>Truck reefer.</li>
                  </ul>
                  <p>
                    <b>Contact the team at Cioffi Express Services</b> right now
                    to secure semi-truck tire service in Kissimmee and{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/blog/the-9-best-maintenance-tips-for-trucks"
                    >
                      maintain safety on the road
                    </a>
                    . Cioffi Express Services is the reliable choice for
                    semi-truck tire service in Kissimmee.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Descubre el costo del servicio de neumáticos para semi truck
                    tire service in Kissimmee contactando a CES Truck Services
                    al 407 674 5769 o{" "}
                    <a target="_blank" href="https://cestruckrepair.com/">
                      visitando su sitio web
                    </a>{" "}
                    para solicitar una cotización gratis.
                  </p>
                  <p>
                    A continuación te contamos todo lo que debes saber sobre las
                    empresas que ofrecen mantenimiento de neumáticos y mobile
                    semi truck repair in Kissimmee. Descubre los precios
                    actuales y las opciones disponibles para mantener en óptimas
                    condiciones los neumáticos de tu semi truck.
                  </p>
                  <h2>
                    2 opciones de semi truck tire service in Kissimmee, FL y sus
                    precios
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    Explora los principales puntos de semi truck tire service in
                    Kissimmee que ofrecen atención rápida y confiable. Obtén
                    respuestas rápidas y efectivas para cualquier necesidad de
                    tu semi truck, las 24 horas del día.
                  </p>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.6,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.3,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <h3>1. Cioffi Express Services</h3>
                  <p style={{ paddingTop: 15 }}>
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/about-us"
                    >
                      Cioffi Express Services
                    </a>
                    , es una{" "}
                    <b>
                      empresa familiar de reparación móvil de semi truck y
                      remolques con tres generaciones de experiencia
                    </b>{" "}
                    en el cuidado de estos vehículos. Ofrecen el mejor servicio
                    en semi truck assistance in Kissimmee. Su prioridad es la
                    satisfacción del cliente, cuentan con un equipo técnico
                    superior que garantiza una experiencia de alta calidad en
                    cada servicio.
                  </p>
                  <p>
                    Atienden todas las marcas y modelos de semi truck y
                    remolques y proporcionan segundas opiniones sobre
                    presupuestos para asegurar transparencia y confianza. ¡
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/services"
                    >
                      Contacta a CES Truck Services hoy mismo
                    </a>{" "}
                    <b>
                      para obtener un presupuesto gratuito y confiable en
                      Kissimmee
                    </b>
                    !
                  </p>
                  <h3>2. Quality Lugo Services</h3>
                  <p style={{ paddingTop: 15 }}>
                    Quality Lugo Services{" "}
                    <b>
                      ofrece servicios de cambio de neumáticos para semi truck y
                      remolques
                    </b>
                    , desde reparaciones hasta instalaciones completas. Cuenta
                    con una amplia gama de neumáticos para adaptarse a todas las
                    necesidades y condiciones de la carretera. Trabajan
                    estrechamente contigo en cada paso del proceso, asegurándose
                    de que comprendas completamente las necesidades de tu
                    vehículo.
                  </p>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Tires}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <h2 style={{ paddingTop: 30 }}>
                    ¿Cuáles son los servicios disponibles en Cioffi Express
                    Services?
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    Dentro de los servicios que ofrece esta empresa, destacan
                    los siguientes:
                  </p>
                  <ul>
                    <li>Roadside assistance emergency</li>
                    <li>Hydraulic repair </li>
                    <li>Wheels repair near me</li>
                    <li>Mobile semi truck repair in Kissimmee</li>
                    <li>Mobile tire repair</li>
                    <li>Truck reefer</li>
                  </ul>
                  <p>
                    <b>Contacta con el equipo de Cioffi Express Services</b>{" "}
                    ahora mismo para asegurar el semi truck tire service
                    Kissimmee y mantener la seguridad en la carretera. Cioffi
                    Express Services es la opción confiable para la semi truck
                    tire service in Kissimmee.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <ArticleFooter/>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Kissimmee;
