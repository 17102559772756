import React from "react";
import { Container, Grid, Typography, Divider, Box } from "@mui/material";
import Mechanics from "../../assets/webp/mechanics.webp";
import CESLOGO from "../../assets/webp/Trucking-Logo-08.webp";
import BoltIcon from "@mui/icons-material/Bolt";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import { useUiStore } from "../../hooks/useUiStore";
import CallButtons from "../../components/CallButtons";

const SectionAbout = () => {
  const { lang } = useUiStore();
  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair About Us
      </Typography>
      <Typography variant="h2" sx={{ display: "none" }}>
        Leave it in our hands
      </Typography>
      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${Mechanics});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>About us</> : <>Sobre nosotros</>}
        </Typography>
        <CallButtons/>
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${Mechanics});`,
          backgroundPosition: "left center",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb:10
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>About us</> : <>Sobre nosotros</>}
        </Typography>
        <CallButtons/>
      </Box>

      {/* body info */}
      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }}>
          {/* Title */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              Cioffi Express Services
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              Cioffi Express Services
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
          </Grid>

          {/* Phone Video */}
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
              marginTop: 5,
            }}
          >
            {/* <iframe
              width="100%"
              height="300"
              src="https://www.youtube.com/embed/evTQDmzNjX4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
            <iframe
                srcDoc="
        <style>
            body, .full {
                width: 100%;
                height:100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/evTQDmzNjX4'
            class='full'
        >
            <img
                src='https://vumbnail.com/evTQDmzNjX4.jpg'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "
                style={{width: '100%', aspectRatio: '16/9'}}
              ></iframe>
          </Grid>

          {/* Desktop Video */}
          <Grid
            item
            xs={12}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              marginTop: 5,
            }}
          >
            {/* <iframe
              width="100%"
              height="600"
              src="https://www.youtube.com/embed/evTQDmzNjX4"
              title="YouTube video player"
              frameBorder="0"
              allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
              allowFullScreen
            ></iframe> */}
            <iframe
                srcDoc="
        <style>
            body, .full {
                width: 100%;
                height:100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/evTQDmzNjX4'
            class='full'
        >
            <img
                src='https://vumbnail.com/evTQDmzNjX4.jpg'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "
                style={{width: '100%', aspectRatio: '16/9'}}
              ></iframe>
          </Grid>

          {/* Body */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              marginTop: 5,
              textAlign: "justify",
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          >
            <Typography variant="body1" display="inline" sx={{fontSize: 20}}>
              {lang === "EN" ? (
                <>
                  &nbsp;&nbsp;Cioffi Express Services is a <b>family-based</b>{" "}
                  mobile truck and trailer repair company. A{" "}
                  <b>three-generation legacy</b> of knowledge and love for truck
                  and trailer care. We serve all truck and trailer makes and
                  models.
                </>
              ) : (
                <>
                  &nbsp;&nbsp;Cioffi Express Services es una{" "}
                  <b>empresa familiar</b> de reparación de camiones y remolques
                  móviles. Un <b>legado de tres generaciones</b> de conocimiento
                  y amor por el cuidado de camiones y remolques.
                </>
              )}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 3, fontSize: 20 }}>
              {lang === "EN" ? (
                <>
                  &nbsp;&nbsp;We are a company built on pure experience and{" "}
                  <b>customer satisfaction</b>. Our top technical staff have
                  been screened and selected with the utmost care to{" "}
                  <b>guarantee the best experience</b> when your most valuable
                  asset breaks down. We can provide a second opinion on
                  estimates.
                  <b style={{ color: "#D69E48" }}>
                    {" "}
                    Call us today for a free quote!
                  </b>
                </>
              ) : (
                <>
                  &nbsp;&nbsp;Somos una empresa basada en la experiencia pura y
                  la <b>satisfacción del cliente</b>. Nuestro personal técnico
                  de primer nivel ha sido evaluado y seleccionado con sumo
                  cuidado para <b>garantizar la mejor experiencia</b> cuando su
                  activo más valioso se avería. Podemos proporcionar una segunda
                  opinión de presupuesto.{" "}
                  <b style={{ color: "#D69E48" }}>
                    {" "}
                    ¡Llámenos hoy para una cotización gratis!
                  </b>
                </>
              )}
            </Typography>
          </Grid>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              marginTop: 5,
              textAlign: "justify",
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          >
            <Typography variant="body1" sx={{ fontSize: 20}}>
              {lang === "EN" ? (
                <>
                  &nbsp;&nbsp;Cioffi Express Services is a <b>family-based</b>{" "}
                  mobile truck and trailer repair company. A{" "}
                  <b>three-generation legacy</b> of knowledge and love for truck
                  and trailer care. We serve all truck and trailer makes and
                  models.
                </>
              ) : (
                <>
                  &nbsp;&nbsp;Cioffi Express Services es una{" "}
                  <b>empresa familiar</b> de reparación de camiones y remolques
                  móviles. Un <b>legado de tres generaciones</b> de conocimiento
                  y amor por el cuidado de camiones y remolques.
                </>
              )}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 3, fontSize: 20 }}>
              {lang === "EN" ? (
                <>
                  &nbsp;&nbsp;We are a company built on pure experience and{" "}
                  <b>customer satisfaction</b>. Our top technical staff have
                  been screened and selected with the utmost care to{" "}
                  <b>guarantee the best experience</b> when your most valuable
                  asset breaks down. We can provide a second opinion on
                  estimates.
                  <b style={{ color: "#D69E48" }}>
                    {" "}
                    Call us today for a free quote!
                  </b>
                </>
              ) : (
                <>
                  &nbsp;&nbsp;Somos una empresa basada en la experiencia pura y
                  la <b>satisfacción del cliente</b>. Nuestro personal técnico
                  de primer nivel ha sido evaluado y seleccionado con sumo
                  cuidado para <b>garantizar la mejor experiencia</b> cuando su
                  activo más valioso se avería. Podemos proporcionar una segunda
                  opinión de presupuesto.{" "}
                  <b style={{ color: "#D69E48" }}>
                    {" "}
                    ¡Llámenos hoy para una cotización gratis!
                  </b>
                </>
              )}
            </Typography>
          </Grid>

          {/* Grid de separacion responsive */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              marginTop: 8,
              textAlign: "justify",
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          ></Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} textAlign="center">
            <Box
              component="img"
              src={CESLOGO}
              sx={{ width: 300, height: "auto" }}
              display="inline"
              alt="CES LOGO"
              title="CES LOGO"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6}>
            <Box textAlign="center">
              <Typography
                variant="h2"
                sx={{
                  fontSize: 30,
                  fontWeight: "bold",
                  marginBottom: 3,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                {lang === "EN" ? (
                  <>Working by your side</>
                ) : (
                  <>Trabajamos a tu lado</>
                )}
              </Typography>
            </Box>

            <Typography variant="body1" sx={{ fontSize: 20 }}>
              <BoltIcon sx={{ color: "#FFCB68" }} />{" "}
              {lang === "EN" ? (
                <>
                  Our technicians are on call for you <b>24/7</b>.
                </>
              ) : (
                <>
                  Nuestros técnicos están disponibles <b>24/7</b>.
                </>
              )}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 3, fontSize: 20 }}>
              <BoltIcon sx={{ color: "#FFCB68" }} />{" "}
              {lang === "EN" ? (
                <>
                  Specialists standing by for all your technical and mechanical
                  needs.
                </>
              ) : (
                <>
                  Especialistas a su disposición para todas sus necesidades
                  técnicas y mecánicas.
                </>
              )}
            </Typography>
            <Typography variant="body1" sx={{ marginTop: 3, fontSize: 20 }}>
              <BoltIcon sx={{ color: "#FFCB68" }} />{" "}
              {lang === "EN" ? (
                <>
                  No matter if it's a simple fix or a more complex issue, we
                  will guide you step by step.
                </>
              ) : (
                <>
                  No importa si se trata de una solución simple o de un problema
                  más complejo, te guiaremos paso a paso.
                </>
              )}
            </Typography>
          </Grid>

          {/* Grid de separacion responsive */}
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              marginTop: 5,
              textAlign: "justify",
            }}
          ></Grid>

          <Grid item xs={12} sm={12} md={12} lg={12}>
            <Box
              sx={{
                borderWidth: "4px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, rgba(214,158,72,1), #FFCB68) 1",
                padding: 3,
                backgroundColor: "#272626",
                color: "white",
                borderRadius: 1,
                textAlign: "center",
              }}
            >
              <Typography variant="body1" sx={{ marginTop: 1, fontSize: 20 }}>
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    marginTop: -3,
                    transform: "scale(-1, 1)",
                  }}
                />
                {lang === "EN" ? (
                  <>
                    We understand breakdowns are not easy,{" "}
                    <b style={{ color: "#FFCB68" }}>leave it in our hands</b> to
                    make every step of the process run smoothly with our
                    well-trained, relaible and knowledgeable dispatchers and
                    technicians.
                  </>
                ) : (
                  <>
                    Entendemos que las averías no son fáciles,{" "}
                    <b style={{ color: "#FFCB68" }}>déjelo en nuestras manos</b>{" "}
                    para que cada paso del proceso se desarrolle sin problemas
                    con despachadores y personal bien capacitados.
                  </>
                )}
                <FormatQuoteIcon sx={{ fontSize: 40, marginTop: -2 }} />
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default SectionAbout;
