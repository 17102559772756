import React, {useState, useEffect} from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ArticleSection from "./ArticleSection";
import { useParams, useNavigate } from "react-router-dom";
import articles from "../Blog/blogArticlesLinks.json";

const Article = () => {
    const [articleInfo, setArticleInfo] = useState({});
    const {article} = useParams();
    const navigate = useNavigate();
    useEffect(() => {
      if (articles.articles.filter(e => e.url === article).length > 0) {
        setArticleInfo(articles.articles.find(x => x.url === article));
      }else{
        navigate("/error");
      }
    }, [])
    
  return (
    <>
      <Navbar />
      <ArticleSection articleInfo={articleInfo} />
      <Footer />
    </>
  );
};

export default Article;