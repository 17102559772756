import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
// import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FAQSection from "./FAQSection";

const FAQ = () => {
  //   const { lang } = useUiStore();

  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/faqs" rel="canonical" />
        <title>24 Hour Mobile Truck Repair FAQs</title>
      </Helmet>
      <Navbar />
      <FAQSection />
      <Footer />
    </>
  );
};

export default FAQ;
