import React from "react";
import {
  Typography,
  Box,
  Divider,
  Grid,
  Container,
  ImageList,
  ImageListItem,
  IconButton,
  ImageListItemBar,
  ListSubheader,
  Button,
} from "@mui/material";
import ShareLocationIcon from "@mui/icons-material/ShareLocation";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import PinDropIcon from "@mui/icons-material/PinDrop";
import MapMobile from "../../assets/webp/map-mobile.webp";
import MapDesktop from "../../assets/webp/map-desktop.webp";
import MapMobileV2 from "../../assets/webp/map-mobile-v2.webp";
import InfoIcon from "@mui/icons-material/Info";
import StarBorderIcon from "@mui/icons-material/StarBorder";

function srcset(image, width, height, rows = 1, cols = 1) {
  return {
    src: `${image}?w=${width * cols}&h=${height * rows}&fit=crop&auto=format`,
    srcSet: `${image}?w=${width * cols}&h=${
      height * rows
    }&fit=crop&auto=format&dpr=2 2x`,
  };
}

const MapSection = () => {
  const { lang } = useUiStore();

  const itemData = [
    {
      img: "../../static/media/1.3b201bdf6761cd52b86a.webp",
      title: "Jacksonville",
      href: "/jacksonville",
    },
    {
      img: "../../static/media/36.83efe58e0011d8d1ea36.webp",
      title: "Ocala",
      href: "/ocala",
    },
    {
      img: "../../static/media/37.a20e2b77b8948ca1cdfe.webp",
      title: "Lakeland",
      href: "/lakeland",
    },
    {
      img: "../../static/media/38.620aafaf4c18b2510b52.webp",
      title: "Kissimmee",
      href: "/kissimmee",
    },
    {
      img: "../../static/media/40.bc21c337320bf525dcd0.webp",
      title: "Melbourne",
      href: "/melbourne",
    },
    // {
    //   img: "../../static/media/1.3b201bdf6761cd52b86a.webp",
    //   title: "Ocala",
    // },
    // {
    //   img: "../../static/media/36.83efe58e0011d8d1ea36.webp",
    //   title: "DeLand",
    // },
    // {
    //   img: "../../static/media/37.a20e2b77b8948ca1cdfe.webp",
    //   title: "Deltona",
    // },
    // {
    //   img: "../../static/media/38.620aafaf4c18b2510b52.webp",
    //   title: "Orlando",
    // },
    // {
    //   img: "../../static/media/40.bc21c337320bf525dcd0.webp",
    //   title: "Sandford",
    // },
    // {
    //   img: "../../static/media/41.a444df34b05e37fd8e67.webp",
    //   title: "Clermond",
    // },
    // {
    //   img: "../../static/media/42.82b71d3762cf38315a75.webp",
    //   title: "Lakeland",
    // },
    // {
    //   img: "../../static/media/43.fa31cd5f496863246a0c.webp",
    //   title: "Davenport",
    // },
    // {
    //   img: "../../static/media/44.7e8dabb3427be8086858.webp",
    //   title: "Kissimmee",
    // },
    // {
    //   img: "../../static/media/45.2da1098bdd50638dc8be.webp",
    //   title: "Melbourne",
    // },
    // {
    //   img: "../../static/media/47.f6fc4d2c9850ca531383.webp",
    //   title: "Poinciana",
    // },
    // {
    //   img: "../../static/media/48.b31166fcd0deb8af7f21.webp",
    //   title: "Pine Hills",
    // },
    // {
    //   img: "../../static/media/49.f79703718c93e3a613af.webp",
    //   title: "Titusville",
    // },
    // {
    //   img: "../../static/media/50.c19e18097322ce6e66d2.webp",
    //   title: "Haines City",
    // },
    // {
    //   img: "../../static/media/51.3639d7ff86c0546ad1b5.webp",
    //   title: "Pine Castle",
    // },
    // {
    //   img: "../../static/media/53.6557fd589b5ae000fd15.webp",
    //   title: "Williamsburg",
    // },
    // {
    //   img: "../../static/media/54.33916bec631d368d3213.webp",
    //   title: "Winter Haven",
    // },
    // {
    //   img: "../../static/media/56.400d933e72abd4eef36e.webp",
    //   title: "Daytona Beach",
    // },
    // {
    //   img: "../../static/media/57.783e48dd42b8bca9b301.webp",
    //   title: "Altamonte Springs",
    // },
    {
      img: "../../static/media/map-mobile.5f0e10d7f0b5c1234c05.webp",
      title: "... and more.",
      href: "/location"
    },
  ];

  return (
    <>
      <Box sx={{ textAlign: "center" }}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              <Typography>
                <ShareLocationIcon sx={{ fontSize: 80, color: "#FDD383" }} />
              </Typography>
              <Typography
                variant="body1"
                sx={{ fontSize: 30, mb: 1, mt: 2, fontFamily: "Ethnocentric" }}
              >
                {lang === "EN" ? (
                  <>We can assist you</>
                ) : (
                  <>Podemos asistirte</>
                )}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: 30,
                  mb: 5,
                  color: "#FDD383",
                  fontFamily: "Ethnocentric",
                   display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>100 miles around Orlando, Tampa & Jacksonville</>
                ) : (
                  <>100 millas a la redonda de Orlando, Tampa y Jacksonville</>
                )}{" "}
              </Typography>
              <Typography
                variant="h2"
                sx={{
                  fontSize: 20,
                  mb: 5,
                  color: "#FDD383",
                  fontFamily: "Ethnocentric",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>100 miles around Orlando, Tampa & Jacksonville</>
                ) : (
                  <>100 millas a la redonda de Orlando, Tampa y Jacksonville</>
                )}{" "}
              </Typography>
            </Grid>
          </Grid>

          <Grid container sx={{ mb: 3 }}>
            <Grid item xs={12}>
              <Typography variant="body1" sx={{ mb: 2, fontSize: 20 }}>
                {lang === "EN" ? (
                  <b>We offer our road service around this area 🚚 </b>
                ) : (
                  <b>
                    Te ofrecemos nuestro servicio en está área a la redonda 🚚
                  </b>
                )}
              </Typography>
            </Grid>

            <Grid item xs={12}>
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                    xl: "block",
                  },
                }}
              >
                {/* <ImageList cols={6} sx={{ width: 1, mt: 4 }}>
                  {itemData.map((item) => (
                    <ImageListItem key={item.img}>
                      <img
                        srcSet={`${item.img}?w=248&fit=crop&auto=format&dpr=2 2x`}
                        src={`${item.img}?w=248&fit=crop&auto=format`}
                        alt={item.title}
                        loading="lazy"
                      />
                      <ImageListItemBar title={item.title} position="below" />
                    </ImageListItem>
                  ))}
                </ImageList> */}
                <ImageList
                  sx={{
                    width: 1,
                    transform: "translateZ(0)",
                    mt: 4,
                  }}
                  cols={6}
                  rowHeight={200}
                  gap={2}
                >
                  {itemData.map((item) => {
                    const cols = item.featured ? 2 : 1;
                    const rows = item.featured ? 2 : 1;

                    return (
                      <Link
                        to={item.href}
                        key={item.img}
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                        <ImageListItem cols={cols} rows={rows}>
                          <img
                            {...srcset(item.img, 250, 200, rows, cols)}
                            alt={item.title}
                            loading="lazy"
                          />
                          <ImageListItemBar
                            sx={{
                              background:
                                "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                                "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                            }}
                            title={item.title}
                            position="top"
                            actionIcon={
                              <IconButton
                                sx={{ color: "white" }}
                                aria-label={`star ${item.title}`}
                              >
                                <StarBorderIcon />
                              </IconButton>
                            }
                            actionPosition="left"
                          />
                        </ImageListItem>
                      </Link>
                    );
                  })}
                </ImageList>
              </Box>

              <Box
                sx={{
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                    xl: "none",
                  },
                  mt: 4,
                }}
              >
                <ImageList
                  sx={{
                    width: 1,
                    height: 450,
                    transform: "translateZ(0)",
                  }}
                  rowHeight={200}
                  gap={2}
                >
                  {itemData.map((item) => {
                    const cols = item.featured ? 2 : 1;
                    const rows = item.featured ? 2 : 1;

                    return (
                      <Link
                        to={item.href}
                        key={item.img}
                        style={{
                          textDecoration: "none",
                          color: "black",
                        }}
                      >
                      <ImageListItem cols={cols} rows={rows}>
                        <img
                          {...srcset(item.img, 250, 200, rows, cols)}
                          alt={item.title}
                          loading="lazy"
                        />
                        <ImageListItemBar
                          sx={{
                            background:
                              "linear-gradient(to bottom, rgba(0,0,0,0.7) 0%, " +
                              "rgba(0,0,0,0.3) 70%, rgba(0,0,0,0) 100%)",
                          }}
                          title={item.title}
                          position="top"
                          actionIcon={
                            <IconButton
                              sx={{ color: "white" }}
                              aria-label={`star ${item.title}`}
                            >
                              <StarBorderIcon />
                            </IconButton>
                          }
                          actionPosition="left"
                        />
                      </ImageListItem>
                      </Link>
                    );
                  })}
                </ImageList>
              </Box>

              <Grid item xs={12} sx={{ mt: 5, mb: 4, fontSize: 15 }}>
                <Link
                  to={"/location"}
                  style={{
                    textDecoration: "none",
                    color: "black",
                  }}
                >
                  <Button
                    variant="outlined"
                    sx={{
                      backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                      color: "black",
                      fontWeight: "bold",
                      border: "1px solid #FDD383",
                      "&:hover": {
                        color: "black",
                        backgroundColor: "rgb(253,211,131,0.7)",
                        border: "1px solid #FDD383",
                      },
                    }}
                  >
                    {lang === "EN" ? (
                      <>Explore more cities&nbsp;&nbsp; &#8594;</>
                    ) : (
                      <>Explora más ciudades&nbsp;&nbsp; &#8594;</>
                    )}
                  </Button>
                </Link>
              </Grid>
            </Grid>
          </Grid>
        </Container>
        {/* <iframe
          title="Orlando Range of Service"
          src="https://www.google.com/maps/d/u/1/embed?mid=1RowMPaU3T37zi9yff13Yhe4L787sCBM&ehbc=2E312F"
          width="100%"
          height="450"
          sx={{ border: 0 }}
          allowFullScreen=""
          loading="lazy"
          referrerPolicy="no-referrer-when-downgrade"
        ></iframe> */}
        <Box
          component="img"
          src={MapDesktop}
          sx={{
            width: 1,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
          alt="Map Desktop"
          title="Map Desktop"
        />

        <Box
          component="img"
          src={MapMobileV2}
          sx={{
            width: 1,
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
            },
          }}
          alt="Map Mobile"
          title="Map Mobile"
        />
        {/* </Grid>
        </Grid> */}
      </Box>
    </>
  );
};

export default MapSection;
