import React from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../../hooks/useUiStore";
import Navbar from "../../../components/Navbar";
import Footer from "../../../components/Footer";
import { Box, Container, Grid, Typography } from "@mui/material";
import CallButtons from "../../../components/CallButtons";
import RedTriangle from "../../../assets/webp/red-triangle.webp";
import RedTriangle2 from "../../../assets/webp/red-triangle-2.webp";
import CESLOGO from "../../../assets/webp/Trucking-Logo-08.webp";
import Image4 from "../../../assets/carousel/4.webp";
import Image7 from "../../../assets/carousel/7.webp";
import ArticleFooter from "../../../components/ArticleFooter";

const Melbourne = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/melbourne" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Cioffi Express Services offers the best roadside assistance service in Melbourne (FL) and surrounding areas. Check out their services and medium contact here!"
              : "Cioffi Express Services ofrece el mejor servicio de asistencia en carretera en Melbourne FL y sus alrededores. ¡Mira aquí sus servicios y contacto!"
          }`}
        />
        <title>Roadside Assistance in Melbourne, FL</title>
      </Helmet>
      <Navbar />
      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          Melbourne
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle2});`,
          backgroundPosition: "center right",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 30, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          Melbourne
        </Typography>
        <CallButtons />
      </Box>

      {/* Body */}
      <Box>
        <Container sx={{ mt: 10, mb: 10 }}>
          <Grid container spacing={1}>
            <Grid item xs={12}>
              <Typography
                variant="h1"
                gutterBottom
                sx={{
                  fontSize: 40,
                  fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                }}
              >
                Roadside Assistance in Melbourne, FL
              </Typography>
            </Grid>
            <Grid item xs={12}>
              {lang === "EN" ? (
                <>
                  <p>
                    Knowing that you have a reliable service capable of helping
                    you if you have an accident in the middle of the road is
                    ideal for stress-free travel.{" "}
                    <a target="_blank" href="https://cestruckrepair.com/">
                      Cioffi Express Services
                    </a>{" "}
                    offers the best mobile semi-truck service in Melbourne and
                    the other areas of Florida.
                  </p>
                  <p>
                    <b>Their team is available 24 hours a day</b> and is on the
                    move wherever you are. Below, you will find more information
                    about their{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/services"
                    >
                      services
                    </a>
                    .
                  </p>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.6,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.3,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <h2>
                    Cioffi Express Services: The Best Mobile Semi-truck Service
                    In Melbourne
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    CES Truck Repair offers everything your semi-truck needs.
                    That's why they are the best roadside assistance in
                    Melbourne, FL. Their staff is highly trained and always
                    ready to help you. Since its founding, the company has 3
                    generations that have worked in the care and maintenance of
                    trailers and semi-trucks.
                  </p>
                  <p>
                    To contact and receive assistance from this mobile
                    semi-truck service in Melbourne or any other area of
                    Florida, call 407-674-5769.{" "}
                    <b>
                      You can also{" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/contact"
                      >
                        send a message through their website
                      </a>
                    </b>
                    .
                  </p>
                  <p>
                    Cioffi Express Services' team of technicians is available 24
                    hours a day, any day of the week. In addition, since its
                    services focus on giving roadside assistance in Melbourne
                    and other areas in Florida, its personnel will come to your
                    location to repair your semi-truck or trailer.
                  </p>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image4}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <p style={{ paddingTop: 30 }}>
                    This company's services are varied and efficient, so no
                    matter what the problem with your vehicle, you'll be back on
                    the road. Some of the outstanding services are:
                  </p>
                  <ul>
                    <li>
                      Brake maintenance: because CES Truck Repair knows that
                      this system is critical to your safety and the safety of
                      others.
                    </li>
                    <li>
                      Computerized diagnostics: guarantees a complete check and
                      diagnosis of your truck that optimizes its overall
                      performance.
                    </li>
                    <li>
                      Battery replacement: CES professionals not only replace
                      the battery of your semi-truck or trailer but also offer
                      an overhaul of the current step with total security.{" "}
                      <b>
                        All this guarantees the care of the electronics of your
                        vehicle
                      </b>
                      .
                    </li>
                    <li>
                      Hydraulic and suspension repairs: complete analysis of the
                      hydraulic system and its possible failures, as well as
                      evaluation of oil flow, pressure and volume.
                    </li>
                    <li>
                      Rebuild your fleet's engines: if needed, they rebuild the
                      engines of your entire fleet of trailers and semi-trucks.
                    </li>
                    <li>
                      Oil and lubricant changes: they have the{" "}
                      <a
                        target="_blank"
                        href="https://medium.com/@megulfoilltd/what-are-the-best-oils-for-commercial-vehicle-882b4fb639ef"
                      >
                        best brands of oils and lubricants
                      </a>{" "}
                      that your heavy vehicle requires.
                    </li>
                    <li>
                      Semi-truck tire assistance in Melbourne and any other area
                      in Florida: whether your semi-truck needs new tires or the
                      tires you already have are repairable, CES Truck Repair
                      has the solution.{" "}
                      <b>They also have a balancing service</b>.
                    </li>
                    <li>
                      Filter changes (fuel, oil and AC): always with the best
                      quality in all spare parts.
                    </li>
                    <li>
                      Diesel particulate filter cleaning: this service focuses
                      on removing solid diesel particles that accumulate in the
                      filter to ensure the optimum performance of your
                      semi-truck.
                    </li>
                    <li>
                      Semi-truck jump-start in Melbourne and the rest of
                      Florida: if your truck's battery has completely
                      discharged, Cioffi Express Services jump start service
                      takes care of it. So you'll be back on the road faster
                      than you think.
                    </li>
                  </ul>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image7}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <p style={{ paddingTop: 30 }}>
                    In addition to offering this full range of services, CES
                    Truck Repair has a{" "}
                    <a target="_blank" href="https://cestruckrepair.com/blog">
                      blog
                    </a>{" "}
                    where you can read articles on heavy vehicle maintenance. We
                    recommend the{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/blog/the-9-best-maintenance-tips-for-trucks"
                    >
                      9 Maintenance Tips for your Truck
                    </a>{" "}
                    and the{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/blog/top-10-apps-for-truck-drivers"
                    >
                      10 Best Apps for Truck Drivers
                    </a>
                    .
                  </p>
                  <p>
                    In cas of any problem with your vehicle,{" "}
                    <b>
                      don't hesitate to call{" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/about-us"
                      >
                        Cioffi Express Services
                      </a>
                    </b>{" "}
                    and request your repair estimate. The best roadside
                    assistance in Melbourne (FL) will get on the road
                    immediately to repair your semi-truck breakdown quickly and
                    effectively.
                  </p>
                </>
              ) : (
                <>
                  <p>
                    Una buena asistencia en carretera marca la diferencia entre
                    estar preocupado o libre de estrés en tus viajes.{" "}
                    <a target="_blank" href="https://cestruckrepair.com/">
                      Cioffi Express Services
                    </a>{" "}
                    ofrece el mejor servicio móvil de semi truck en Melbourne y
                    las demás zonas de Florida.
                  </p>
                  <p>
                    <b>Su equipo está disponible las 24 horas del día</b> y se
                    mueve hasta donde estés. A continuación, encontrarás más
                    información sobre sus{" "}
                    <a target="_blank" href="">
                      servicios
                    </a>
                    .
                  </p>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "block",
                        sm: "block",
                        md: "none",
                        lg: "none",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.6,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <Grid
                    textAlign="center"
                    sx={{
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                  >
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 0.3,
                      }}
                      src={CESLOGO}
                      alt="CES LOGO"
                      title="CES LOGO"
                    />
                  </Grid>
                  <h2>
                    Cioffi Express Services: El Mejor Servicio Móvil de Semi
                    Truck en Melbourne
                  </h2>
                  <p style={{ paddingTop: 15 }}>
                    CES Truck Repair ofrece todo lo que necesita tu semi truck,
                    por eso son la mejor asistencia en carretera en Melbourne,
                    FL. Su personal está altamente capacitado y siempre
                    dispuesto a ayudarte. Desde su fundación, la empresa cuenta
                    con 3 generaciones que han trabajado en el cuidado y
                    mantenimiento de trailers y semi trucks.
                  </p>
                  <p>
                    Para contactar y recibir asistencia de este servicio móvil
                    para semi truck en Melbourne o cualquier otra zona de
                    Florida, llama al 407-674-5769.{" "}
                    <b>
                      También puedes{" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/contact"
                      >
                        enviar un mensaje a través de su página
                      </a>
                    </b>
                    .
                  </p>
                  <p>
                    El equipo de técnicos de Cioffi Express Services está
                    disponible para ti las 24 horas, cualquier día de la semana.
                    Además, por tratarse de asistencia en carretera, su personal
                    se mueve hasta el lugar en el que te encuentres para reparar
                    tu semi truck o trailer.
                  </p>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image4}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <p style={{ paddingTop: 30 }}>
                    Los servicios de esta empresa son variados y eficientes, así
                    que sin importar el problema en tu vehículo, volverás a la
                    vía. Algunos de los servicios destacados son:
                  </p>
                  <ul>
                    <li>
                      Mantenimiento de frenos: porque CES Truck Repair sabe que
                      este sistema es fundamental para tu seguridad y la de los
                      demás.
                    </li>
                    <li>
                      Diagnóstico computarizado: garantiza un chequeo y
                      diagnóstico completo de tu camión que{" "}
                      <b>optimiza su desempeño en general</b>.
                    </li>
                    <li>
                      Reemplazo de baterías: no solo cuentan con el reemplazo de
                      la batería de tu semi truck o trailer, también ofrecen
                      revisión del paso de corriente con total seguridad. Todo
                      esto garantizando el cuidado de la electrónica de tu
                      vehículo.
                    </li>
                    <li>
                      Reparaciones hidráulicas y de suspensión: análisis
                      completo del sistema hidráulico y sus posibles fallas, así
                      como la evaluación del flujo, presión y volumen del
                      aceite.
                    </li>
                    <li>
                      Reconstrucción de los motores de tu flota: si lo
                      necesitas, reconstruyen los motores de tu flota entera de
                      trailers y semi trucks.
                    </li>
                    <li>
                      Cambio de aceite y lubricantes: cuentan con las{" "}
                      <a
                        target="_blank"
                        href="https://medium.com/@megulfoilltd/what-are-the-best-oils-for-commercial-vehicle-882b4fb639ef"
                      >
                        mejores marcas de aceites y lubricantes
                      </a>{" "}
                      que requiere tu vehículo pesado.
                    </li>
                    <li>
                      Neumáticos para semi trucks en Melbourne y cualquier otra
                      zona de Florida: sea que tu semi truck necesite neumáticos
                      nuevos o que sea posible reparar los que ya tiene, CES
                      Truck Repair tiene la solución. Además{" "}
                      <b>cuentan con servicio de balanceo</b>.
                    </li>
                    <li>
                      Cambios de filtros (combustible, aceite y AC): siempre con
                      la mejor calidad en todos los repuestos.
                    </li>
                    <li>
                      Limpieza del filtro de partículas diesel: se enfoca en
                      remover las partículas sólidas de diesel que se acumulan
                      en el filtro, para garantizar el óptimo funcionamiento de
                      tu camión.
                    </li>
                    <li>
                      Servicio de arranque de semi truck en Melbourne y el resto
                      de Florida: si la batería de tu camión se ha descargado
                      por completo, el servicio de arranque de Cioffi Express
                      Services se encarga. Así, volverás a la carretera más
                      rápido de lo que imaginas.
                    </li>
                  </ul>
                  <Grid textAlign="center" sx={{ pt: 3 }}>
                    <Box
                      component="img"
                      textAlign="center"
                      sx={{
                        width: 1,
                      }}
                      src={Image7}
                      alt="CES Truck Mechanic"
                      title="CES Truck Mechanic"
                    />
                  </Grid>
                  <p style={{ paddingTop: 30 }}>
                    CES Truck Repair además de ofrecer toda esta gama de
                    servicios, tambien dispone de un{" "}
                    <a target="_blank" href="https://cestruckrepair.com/blog">
                      blog
                    </a>{" "}
                    en el que publica artículos sobre el mantenimiento de
                    vehículos pesados. Te recomendamos los{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/blog/the-9-best-maintenance-tips-for-trucks"
                    >
                      9 Tips de Mantenimiento para tu Camión
                    </a>{" "}
                    y{" "}
                    <a
                      target="_blank"
                      href="https://cestruckrepair.com/blog/top-10-apps-for-truck-drivers"
                    >
                      Las 10 Mejores apps para Choferes de Camiones
                    </a>
                    .
                  </p>
                  <p>
                    En caso de cualquier problema con tu vehículo,{" "}
                    <b>
                      no dudes en llamar a{" "}
                      <a
                        target="_blank"
                        href="https://cestruckrepair.com/about-us"
                      >
                        Cioffi Express Services
                      </a>
                    </b>{" "}
                    y solicitar tu presupuesto de reparación. La mejor
                    asistencia en carretera en Melbourne FL se pondrá en marcha
                    inmediatamente para reparar la falla en tu semi truck de
                    manera rápida y efectiva.
                  </p>
                </>
              )}
            </Grid>
          </Grid>
          <ArticleFooter/>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Melbourne;
