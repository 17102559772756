import React, { useEffect } from "react";
import { Link } from "react-router-dom";
import Carousel from "react-gallery-carousel";
import "react-gallery-carousel/dist/index.css";
import GalleryPicture1 from "../../assets/carousel/1.webp";
import GalleryPicture2 from "../../assets/carousel/2.webp";
import GalleryPicture4 from "../../assets/carousel/4.webp";
import GalleryPicture5 from "../../assets/carousel/5.webp";
import GalleryPicture6 from "../../assets/carousel/6.webp";
import GalleryPicture7 from "../../assets/carousel/7.webp";
import GalleryPicture8 from "../../assets/carousel/8.webp";
import GalleryPicture9 from "../../assets/carousel/9.webp";
import GalleryPicture10 from "../../assets/carousel/10.webp";
import GalleryPicture11 from "../../assets/carousel/11.webp";
import GalleryPicture12 from "../../assets/carousel/12.webp";
import GalleryPicture13 from "../../assets/carousel/13.webp";
import GalleryPicture14 from "../../assets/carousel/14.webp";
import GalleryPicture15 from "../../assets/carousel/15.webp";
import GalleryPicture16 from "../../assets/carousel/16.webp";
import GalleryPicture17 from "../../assets/carousel/17.webp";
import GalleryPicture18 from "../../assets/carousel/18.webp";
import GalleryPicture19 from "../../assets/carousel/19.webp";
import GalleryPicture20 from "../../assets/carousel/20.webp";
import GalleryPicture21 from "../../assets/carousel/21.webp";
import GalleryPicture22 from "../../assets/carousel/22.webp";
import GalleryPicture23 from "../../assets/carousel/23.webp";
import GalleryPicture35 from "../../assets/carousel/35.webp";
import GalleryPicture36 from "../../assets/carousel/36.webp";
import GalleryPicture37 from "../../assets/carousel/37.webp";
import GalleryPicture38 from "../../assets/carousel/38.webp";
import GalleryPicture39 from "../../assets/carousel/39.webp";
import GalleryPicture40 from "../../assets/carousel/40.webp";
import GalleryPicture41 from "../../assets/carousel/41.webp";
import GalleryPicture42 from "../../assets/carousel/42.webp";
import GalleryPicture43 from "../../assets/carousel/43.webp";
import GalleryPicture44 from "../../assets/carousel/44.webp";
import GalleryPicture45 from "../../assets/carousel/45.webp";
import GalleryPicture46 from "../../assets/carousel/46.webp";
import GalleryPicture47 from "../../assets/carousel/47.webp";
import GalleryPicture48 from "../../assets/carousel/48.webp";
import GalleryPicture49 from "../../assets/carousel/49.webp";
import GalleryPicture50 from "../../assets/carousel/50.webp";
import GalleryPicture51 from "../../assets/carousel/51.webp";
import GalleryPicture52 from "../../assets/carousel/52.webp";
import GalleryPicture53 from "../../assets/carousel/53.webp";
import GalleryPicture54 from "../../assets/carousel/54.webp";
import GalleryPicture55 from "../../assets/carousel/55.webp";
import GalleryPicture56 from "../../assets/carousel/56.webp";
import GalleryPicture57 from "../../assets/carousel/57.webp";
import GalleryPicture58 from "../../assets/carousel/58.webp";
import GalleryPicture59 from "../../assets/carousel/59.webp";
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";

const CarouselSection = () => {
  const { lang } = useUiStore();
  const images = [
    {
      src: GalleryPicture1,
      alt: "Gallery Picture 1",
    },
    {
      src: GalleryPicture2,
      alt: "Gallery Picture 2",
    },
    {
      src: GalleryPicture4,
      alt: "Gallery Picture 4",
    },
    {
      src: GalleryPicture5,
      alt: "Gallery Picture 5",
    },
    {
      src: GalleryPicture6,
      alt: "Gallery Picture 6",
    },
    {
      src: GalleryPicture7,
      alt: "Gallery Picture 7",
    },
    {
      src: GalleryPicture8,
      alt: "Gallery Picture 8",
    },
    {
      src: GalleryPicture9,
      alt: "Gallery Picture 9",
    },
    {
      src: GalleryPicture10,
      alt: "Gallery Picture 10",
    },
    {
      src: GalleryPicture11,
      alt: "Gallery Picture 11",
    },
    {
      src: GalleryPicture12,
      alt: "Gallery Picture 12",
    },
    {
      src: GalleryPicture13,
      alt: "Gallery Picture 13",
    },
    {
      src: GalleryPicture14,
      alt: "Gallery Picture 14",
    },
    {
      src: GalleryPicture15,
      alt: "Gallery Picture 15",
    },
    {
      src: GalleryPicture16,
      alt: "Gallery Picture 16",
    },
    {
      src: GalleryPicture17,
      alt: "Gallery Picture 17",
    },
    {
      src: GalleryPicture18,
      alt: "Gallery Picture 18",
    },
    {
      src: GalleryPicture19,
      alt: "Gallery Picture 19",
    },
    {
      src: GalleryPicture20,
      alt: "Gallery Picture 20",
    },
    {
      src: GalleryPicture21,
      alt: "Gallery Picture 21",
    },
    {
      src: GalleryPicture22,
      alt: "Gallery Picture 22",
    },
    {
      src: GalleryPicture23,
      alt: "Gallery Picture 23",
    },
  ];

  return (
    <Container sx={{ mb: 5, mt: 5 }}>
      <Grid
        container
        sx={{
          flexGrow: 1,
          textAlign: "center",
        }}
      >
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="body1"
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
              fontSize: 30,
            }}
          >
            <Link
              to="/gallery"
              style={{ textDecoration: "none", color: "black" }}
            >
              {lang === "EN" ? <>Gallery</> : <>Galería</>}
            </Link>
          </Typography>
          <Typography
            variant="body1"
            sx={{
              mt: 1,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              fontSize: 30,
              fontFamily: "Ethnocentric"
            }}
          >
            <Link
              to="/gallery"
              style={{ textDecoration: "none", color: "black" }}
            >
              {lang === "EN" ? <>Gallery</> : <>Galería</>}
            </Link>
          </Typography>
        </Grid>

        <Grid item xs={12} sx={{ mt: 2 }}>
          <Typography variant="body1" sx={{ color: "#5D6D7E", fontSize: 20 }}>
            {lang === "EN" ? (
              <>🛠️&nbsp;Look into our real labor</>
            ) : (
              <>🛠️&nbsp;Conoce nuestra labor</>
            )}
          </Typography>
        </Grid>

        {/* Mobile Carousel */}
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            mt: 5,
            mb: 5,
            display: {
              xs: "block",
              sm: "block",
              md: "none",
              lg: "none",
            },
          }}
        >
          <Carousel
            images={images}
            isAutoPlaying={true}
            style={{ height: 300, width: "100%" }}
          />
        </Grid>

        {/* Desktop Carousel */}
        <Grid
          item
          xs={12}
          textAlign="center"
          sx={{
            mt: 5,
            mb: 5,
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        >
          <Carousel
            images={images}
            isAutoPlaying={true}
            style={{ height: 500, width: "100%" }}
          />
        </Grid>
      </Grid>
    </Container>
  );
};

export default CarouselSection;
