import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BlogSection from "./BlogSection";


const Blog = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/blog" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "✍ Find detailed information about truck repair, electrical service, tire replacement and much more on the Cioffi Express Services blog ✅"
              : "✍ Encuentra información detallada sobre reparación de camiones, servicio eléctrico, cambio de llantas y mucho más en el blog de Cioffi Express Services ✅"
          }`}
        />
        <title>The Truck Blog | CES Mobile Truck & Trailer Repair 🚚</title>
      </Helmet>
      <Navbar />
      <BlogSection />
      <Footer />
    </>
  );
};

export default Blog;
