import { useDispatch, useSelector } from "react-redux"
import { onChangeLang,
    onChangeBlogPage,
    //  onCloseDateModal, 
    //  onOpenDateModal 
    } from "../store/ui/uiSlice";

export const useUiStore = () => {

    const dispatch = useDispatch();

    const { 
        // isDateModalOpen, 
        lang,
        blogPage } = useSelector( state => state.ui );

    // const openDateModal = () => {
    //     dispatch( onOpenDateModal() );
    // }

    // const closeDateModal = () => {
    //     dispatch( onCloseDateModal() );
    // }

    // const toggleDateModal = () => {
    //     (isDateModalOpen) ? openDateModal() : closeDateModal();
    // }

   const changeLang = (newlang) => {
        dispatch( onChangeLang(newlang) );
    }

    const changeBlogPage = (page) => {
        dispatch( onChangeBlogPage(page) );
    }

    return {
        //Properties
        // isDateModalOpen,
        lang,
        blogPage,

        //Métodos
        // openDateModal,
        // closeDateModal,
        // toggleDateModal
        changeLang,
        changeBlogPage
    }
}