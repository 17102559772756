import React, { useEffect, useState } from "react";
import {
  Drawer,
  IconButton,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Divider,
  Box,
  Button,
  Container,
  CssBaseline,
  Typography,
  Collapse,
} from "@mui/material";
import { makeStyles } from "@mui/styles";
import { Link } from "react-router-dom";
import MenuIcon from "@mui/icons-material/Menu";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import CloseIcon from "@mui/icons-material/Close";
import TextsmsIcon from "@mui/icons-material/Textsms";
import StarBorder from "@mui/icons-material/StarBorder";
import ExpandLess from "@mui/icons-material/ExpandLess";
import ExpandMore from "@mui/icons-material/ExpandMore";
import CESLogo from "../../assets/webp/Trucking-Logo-05.webp";
import { useUiStore } from "../../hooks/useUiStore";

const useStyles = makeStyles({
  paper: {
    backgroundColor: "blue",
  },
});

const Sidebar = ({ navItems, navItemsSpanish, submenuItems }) => {
  const [open, setOpen] = useState(false);
  const [openSubmenu, setOpenSubmenu] = useState(false);
  const classes = useStyles();
  const { lang, changeBlogPage } = useUiStore();

  const resetBlogPage = (itemName) => {
    if (itemName === "Blog") {
      changeBlogPage(1);
    }
  };

  return (
    <>
      <IconButton
        onClick={() => setOpen(true)}
        size="large"
        edge="start"
        color="inherit"
        aria-label="menu"
        sx={{
          mr: 2,
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
        }}
      >
        <MenuIcon />
      </IconButton>

      <Drawer
        PaperProps={{
          sx: {
            backgroundColor: "black",
            color: "white",
          },
        }}
        anchor="left"
        open={open}
        onClose={() => setOpen(false)}
      >
        <Box sx={{ width: 250 }} role="presentation">
          <Box sx={{ paddingLeft: 2, paddingTop: 3 }}>
            <Typography
              variant="h2"
              sx={{ fontSize: 30, color: "white", fontFamily: "Ethnocentric" }}
              display="inline"
            >
              Menu
            </Typography>
            <Typography
              variant="h2"
              sx={{ fontSize: 30, color: "white", marginLeft: 8 }}
              display="inline"
              onClick={() => setOpen(false)}
            >
              <CloseIcon />
            </Typography>
          </Box>
          <Divider className="mt-3" sx={{ backgroundColor: "white" }} />
          {/* Menu options */}
          <List sx={{ marginTop: 1 }}>
            {lang === "EN"
              ? navItems.map((item, index) => (
                  <Box key={index}>
                    <Link
                      key={index}
                      to={item.href}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton
                          href={item.href}
                          onClick={() => resetBlogPage(item.name)}
                        >
                          <ListItemText primary={item.name} />
                          {item.name === "Location" && !openSubmenu ? (
                            <ExpandMore onClick={() => setOpenSubmenu(true)} />
                          ) : item.name === "Location" && openSubmenu ? (
                            <ExpandLess onClick={() => setOpenSubmenu(false)} />
                          ) : (
                            <></>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Link>

                    {item.name === "Location" ? (
                      <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {submenuItems.map((item, index) => (
                            <Link
                              key={index}
                              to={item.href}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            </Link>
                          ))}
                        </List>
                      </Collapse>
                    ) : (
                      <></>
                    )}
                  </Box>
                ))
              : navItemsSpanish.map((item, index) => (
                  <Box key={index}>
                    <Link
                      key={index}
                      to={item.href}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <ListItem disablePadding>
                        <ListItemButton
                          href={item.href}
                          onClick={() => resetBlogPage(item.name)}
                        >
                          <ListItemText primary={item.name} />
                          {item.name === "Ubicación" && !openSubmenu ? (
                            <ExpandMore onClick={() => setOpenSubmenu(true)} />
                          ) : item.name === "Ubicación" && openSubmenu ? (
                            <ExpandLess onClick={() => setOpenSubmenu(false)} />
                          ) : (
                            <></>
                          )}
                        </ListItemButton>
                      </ListItem>
                    </Link>

                    {item.name === "Ubicación" && submenuItems ? (
                      <Collapse in={openSubmenu} timeout="auto" unmountOnExit>
                        <List component="div" disablePadding>
                          {submenuItems.map((item, index) => (
                            <Link
                              key={index}
                              to={item.href}
                              style={{ textDecoration: "none", color: "white" }}
                            >
                              <ListItemButton sx={{ pl: 4 }}>
                                <ListItemText primary={item.name} />
                              </ListItemButton>
                            </Link>
                          ))}
                        </List>
                      </Collapse>
                    ) : (
                      <></>
                    )}
                  </Box>
                ))}
          </List>

          {/* Call Buttons */}
          <Divider className="mt-3" sx={{ backgroundColor: "white" }} />
          <Box textAlign={"center"} className="mb-3">
            {/* <Box
            component="img"
            sx={{
              height: 150,
            }}
            alt="CES LOGO"
            src={CESLogo}
          /> */}
            <br />
            <Button
              variant="contained"
              href="tel:4076745769"
              sx={{
                background:
                  "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
                color: "black",
                fontWeight: "bold",
                border: "2px solid #B9893F",
              }}
            >
              {lang === "EN" ? <>Call us now &nbsp;</> : <>Llámanos &nbsp;</>}
              <PhoneEnabledIcon />
            </Button>
            <br />
            <Button
              variant="contained"
              href="sms:+14076745769"
              sx={{
                background:
                  "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
                color: "black",
                fontWeight: "bold",
                border: "2px solid #B9893F",
              }}
              className="mt-3"
            >
              {lang === "EN" ? (
                <>Chat with us &nbsp;</>
              ) : (
                <>Escríbenos &nbsp;</>
              )}
              <TextsmsIcon />
            </Button>
          </Box>

          {/* Logo */}
          <Divider className="mb-3" sx={{ backgroundColor: "white" }} />
          <Container sx={{ textAlign: "center" }}>
            <Link to="/">
              <Box
                component="img"
                sx={{
                  height: 150,
                }}
                alt="CES LOGO"
                title="CES LOGO"
                src={CESLogo}
              />
            </Link>
            {lang === "EN" ? (
              <p>
                &copy; All rights reserved to <br /> Cioffi Express Services
                LLC.
              </p>
            ) : (
              <p>
                &copy; Todos los derechos reservados a <br /> Cioffi Express
                Services LLC.
              </p>
            )}
          </Container>
        </Box>
      </Drawer>
    </>
  );
};

export default Sidebar;
