import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import ServicesSection from "./ServicesSection";

const Services = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/services" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Did you have a breakdown on your truck? Discover Cioffi Express Services mobile repair for any type of truck or semi-trailer✅"
              : "¿Sufriste una avería en tu camión? Estos son los servicios de Cioffi Express Services de reparación móvil para todo tipo de camiones o semirremolques ✅"
          }`}
        />
        <title>Services | CES Mobile Truck & Trailer Repair 🚚</title>
      </Helmet>
      <Navbar />
      <ServicesSection />
      <Footer />
    </>
  );
};

export default Services;
