import React from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import CallButtons from "../../components/CallButtons";
import RedTriangle from "../../assets/webp/red-triangle.webp";
import RedTriangle2 from "../../assets/webp/red-triangle-2.webp";
import CESLOGO from "../../assets/webp/Trucking-Logo-08.webp";
import BoltIcon from "@mui/icons-material/Bolt";
import PlaceIcon from "@mui/icons-material/Place";
import Image1 from "../../assets/carousel/1.webp";
import Image8 from "../../assets/carousel/8.webp";
import Image19 from "../../assets/carousel/19.webp";
import Image33 from "../../assets/carousel/33.webp";
import Image51 from "../../assets/carousel/51.webp";

const LocationSection = () => {
  const { lang } = useUiStore();

  function createData(cities1, cities2) {
    return { cities1, cities2 };
  }

  const rows = [
    createData("Ocala", "Poinciana"),
    createData("DeLand", "Pine Hills"),
    createData("Deltona", "Titusville"),
    createData("Orlando", "Haines City"),
    createData("Sanford", "Pine Castle"),
    createData("Clermont", "Williamsburg"),
    createData("Lakeland", "Winter Haven"),
    createData("Davenport", "Daytona Beach"),
    createData("Kissimmee", "Altamonte Springs"),
    createData("Melbourne", "and more..."),
  ];

  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Location
      </Typography>

      {/* Desktop Image View */}
      <Box
        alt="Mechanical Service about us banner"
        title="Mechanical Service about us banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>Service Area</> : <>Área de Servicio</>}
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${RedTriangle2});`,
          backgroundPosition: "center right",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          {lang === "EN" ? <>Service Area</> : <>Área de Servicio</>}
        </Typography>
        <CallButtons />
      </Box>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }} spacing={5}>
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              {lang === "EN" ? "Right by your side" : "Estamos a tu lado"}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              {lang === "EN" ? "Right by your side" : "Estamos a tu lado"}
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
            {/* Subtitle Desktop View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                fontSize: 25,
                mb: 2,
              }}
            >
              {lang === "EN" ? (
                <>
                  Committed to{" "}
                  <b>delivering fast and dependable roadside assistance</b>{" "}
                  <b style={{ color: "#D69E48" }}>across Florida and Georgia</b>
                </>
              ) : (
                <>
                  Comprometidos a{" "}
                  <b>brindar asistencia vial rápida y confiable</b>{" "}
                  <b style={{ color: "#D69E48" }}>en Florida y Georgia</b>
                </>
              )}
            </Typography>

            {/* Subtitle Tablet and Phone View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                mb: 2,
              }}
            >
              {lang === "EN" ? (
                <>
                  Committed to{" "}
                  <b>delivering fast and dependable roadside assistance</b>{" "}
                  <b style={{ color: "#D69E48" }}>across Florida and Georgia</b>
                </>
              ) : (
                <>
                  Comprometidos a{" "}
                  <b>brindar asistencia vial rápida y confiable</b>{" "}
                  <b style={{ color: "#D69E48" }}>en Florida y Georgia</b>
                </>
              )}
            </Typography>
          </Grid>

          {/* start of first paragraph */}
          <Grid item xs={12}>
            <Typography variant="body1" sx={{ fontSize: 20 }}>
              {lang === "EN" ? (
                <>
                  {" "}
                  Our 24/7 roadside assistance services are{" "}
                  <b>
                    available across multiple states, including Florida and
                    Georgia
                  </b>
                  . Whether your trucks are navigating the busy streets of{" "}
                  <b>Orlando, Jacksonville, and Tampa</b>, or making their way
                  through the vital routes in <b>Atlanta and Savannah</b>, our
                  expert teams are always on standby to provide swift and
                  reliable support.
                </>
              ) : (
                <>
                  <BoltIcon sx={{ color: "#FFCB68", mr: 1 }} />
                  Nuestros servicios de asistencia en carretera las 24 horas,
                  los 7 días de la semana, están{" "}
                  <b>
                    disponibles en varios estados, incluidos Florida y Georgia
                  </b>
                  .
                  <BoltIcon sx={{ color: "#FFCB68", mr: 1 }} />
                  Ya sea que tus camiones estén navegando por las transitadas
                  calles de <b>Orlando, Jacksonville y Tampa</b>, o recorriendo
                  las rutas vitales de <b>Atlanta y Savannah</b>, nuestros
                  equipos de expertos están siempre listos para brindar un
                  soporte rápido y confiable.
                </>
              )}
            </Typography>
            <Typography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
              {lang === "EN" ? (
                <>
                  For quick and dependable roadside and fleet support, call{" "}
                  <a
                    href="tel:4076745769"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "#D69E48",
                    }}
                  >
                    407-674-5769{" "}
                  </a>
                  today, or{" "}
                  <a
                    href="sms:+14076745769"
                    style={{
                      textDecoration: "none",
                      fontWeight: "bold",
                      color: "#D69E48",
                    }}
                  >
                    chat with us
                  </a>
                  .
                </>
              ) : (
                <>
                  Para asistencia vial y de flota rápida y confiable, llama al{" "}
                  <b style={{ color: "#D69E48" }}>407-674-5769</b> ahora, o
                  puedes{" "}
                  <b style={{ color: "#D69E48" }}>chatear con nosotros</b>.
                </>
              )}
            </Typography>
          </Grid>
        </Grid>
        <Grid container sx={{ flexGrow: 1, mt: 1 }} spacing={5}>
          <Grid item xs={12} sm={12} md={4} textAlign="center">
            <Box
              component="img"
              src={CESLOGO}
              sx={{ width: 250, height: "auto" }}
              display="inline"
              alt="CES LOGO"
              title="CES LOGO"
            />
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            <Box
              sx={{
                borderWidth: "4px",
                borderStyle: "solid",
                borderImage:
                  "linear-gradient(to right, rgba(214,158,72,1), #FFCB68) 1",
                padding: 3,
                backgroundColor: "#272626",
                color: "white",
                borderRadius: 1,
              }}
            >
              <Typography variant="body1" sx={{ fontSize: 20 }}>
                <BoltIcon sx={{ color: "#FFCB68", mr: 1 }} />
                {lang === "EN" ? (
                  <>
                    From{" "}
                    <b style={{ color: "#FFCB68" }}>
                      emergency repairs and tire changes to towing services and
                      more
                    </b>
                    , our goal is to minimize downtime and keep your fleet
                    moving smoothly.
                  </>
                ) : (
                  <>
                    Desde{" "}
                    <b style={{ color: "#FFCB68" }}>
                      reparaciones de emergencia y cambios de neumáticos hasta{" "}
                      servicios de remolque y más
                    </b>
                    , nuestro objetivo es minimizar el tiempo de inactividad y
                    mantener tu flota en movimiento sin problemas.
                  </>
                )}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
                <BoltIcon sx={{ color: "#FFCB68", mr: 1 }} />
                {lang === "EN" ? (
                  <>
                    With our extensive coverage, you can trust that{" "}
                    <b style={{ color: "#FFCB68" }}>
                      no matter where your trucks are
                    </b>
                    .
                  </>
                ) : (
                  <>
                    Con nuestra amplia cobertura, puedes confiar en que,{" "}
                    <b style={{ color: "#FFCB68" }}>
                      sin importar dónde estén tus camiones
                    </b>
                    .
                  </>
                )}
              </Typography>
              <Typography variant="body1" sx={{ fontSize: 20, mt: 2 }}>
                <BoltIcon sx={{ color: "#FFCB68", mr: 1 }} />
                {lang === "EN" ? (
                  <>
                    They will receive the highest level of service to help them
                    stay on the road and{" "}
                    <b style={{ color: "#FFCB68" }}>
                      complete their journeys without delay
                    </b>
                    .
                  </>
                ) : (
                  <>
                    Recibirán el más alto nivel de servicio para ayudarlos a
                    mantenerse en la carretera y{" "}
                    <b style={{ color: "#FFCB68" }}>
                      completar sus recorridos sin demoras
                    </b>
                    .
                  </>
                )}
              </Typography>
            </Box>
          </Grid>
        </Grid>
      </Container>

      <Box sx={{ backgroundColor: "#f4f6f6", py: 10 }}>
        <Container>
          <Grid container sx={{ flexGrow: 1 }} spacing={5}>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link
                to={"/orlando"}
                style={{
                  textDecoration: "none",
                  color: "#FFCB68",
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300"
                      image={Image1}
                      alt="truck repair"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <PlaceIcon sx={{ mt: -1, color: "#FFCB68" }} /> Orlando,
                        Florida
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {lang === "EN" ? (
                          <>
                            We proudly operate in Orlando, Florida, offering
                            top-notch roadside assistance services for trucks
                            and fleets. Our coverage extends{" "}
                            <b>up to 150 miles around Orlando</b>, ensuring that
                            no matter where you are in the area, help is just a
                            call away. From breakdowns to minor repairs, we’re
                            here to keep your fleet moving smoothly.
                          </>
                        ) : (
                          <>
                            Operamos con orgullo en Orlando, Florida, ofreciendo
                            servicios de asistencia vial de primera calidad para
                            camiones y flotas. Nuestra cobertura se extiende{" "}
                            <b>hasta 150 millas alrededor de Orlando</b>,
                            asegurando que, sin importar dónde te encuentres en
                            el área, la ayuda esté siempre a solo una llamada de
                            distancia. Desde averías hasta reparaciones menores,
                            estamos aquí para mantener tu flota en movimiento
                            sin problemas.
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link
                to={"/jacksonville"}
                style={{
                  textDecoration: "none",
                  color: "#FFCB68",
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300"
                      image={Image8}
                      alt="truck repair"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <PlaceIcon sx={{ mt: -1, color: "#FFCB68" }} />{" "}
                        Jacksonville, Florida
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {lang === "EN" ? (
                          <>
                            In Jacksonville, Florida, our roadside assistance
                            services are designed to meet the needs of truck
                            drivers and fleet operators. We cover a wide area,{" "}
                            <b>up to 100 miles from Jacksonville</b>, providing
                            reliable support whenever and wherever you need it.
                            Trust us to be there for you with prompt and
                            professional service.
                          </>
                        ) : (
                          <>
                            En Jacksonville, Florida, nuestros servicios de
                            asistencia vial están diseñados para satisfacer las
                            necesidades de los conductores de camiones y
                            operadores de flotas. Cubrimos un área amplia,{" "}
                            <b>hasta 100 millas desde Jacksonville</b>,
                            brindando apoyo confiable cuando y donde lo
                            necesites. Confía en nosotros para estar ahí con un
                            servicio rápido y profesional.
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link
                to={"/tampa"}
                style={{
                  textDecoration: "none",
                  color: "#FFCB68",
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300"
                      image={Image19}
                      alt="truck repair"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <PlaceIcon sx={{ mt: -1, color: "#FFCB68" }} /> Tampa,
                        Florida
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {lang === "EN" ? (
                          <>
                            Our operations in Tampa, Florida, focus on providing
                            comprehensive roadside assistance for trucks within
                            a <b>100-mile radius of the city</b>. Whether you're
                            facing a mechanical issue or need emergency support,
                            our team is ready to assist, ensuring your fleet
                            remains on the move with minimal downtime.
                          </>
                        ) : (
                          <>
                            Nuestras operaciones en Tampa, Florida, se centran
                            en proporcionar una asistencia vial integral para
                            camiones dentro de{" "}
                            <b>un radio de 100 millas de la ciudad</b>. Ya sea
                            que enfrentes un problema mecánico o necesites apoyo
                            de emergencia, nuestro equipo está listo para
                            ayudarte, asegurando que tu flota continúe en
                            movimiento con el menor tiempo de inactividad
                            posible.{" "}
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link
                to={"/atlanta"}
                style={{
                  textDecoration: "none",
                  color: "#FFCB68",
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300"
                      image={Image33}
                      alt="truck repair"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <PlaceIcon sx={{ mt: -1, color: "#FFCB68" }} /> Atlanta,
                        Georgia
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {lang === "EN" ? (
                          <>
                            In Atlanta, Georgia, we offer extensive roadside
                            assistance services, covering{" "}
                            <b>up to 100 miles from the city center</b>. Our
                            commitment is to be by your side, providing quick
                            and efficient solutions to keep your trucks and
                            fleet on the road, no matter where you are within
                            our service area.
                          </>
                        ) : (
                          <>
                            En Atlanta, Georgia, ofrecemos servicios extensivos
                            de asistencia vial, cubriendo{" "}
                            <b>hasta 100 millas desde el centro de la ciudad</b>
                            . Nuestro compromiso es estar a tu lado, brindando
                            soluciones rápidas y eficientes para mantener tus
                            camiones y flota en la carretera, sin importar dónde
                            te encuentres dentro de nuestra área de servicio.
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
            <Grid item xs={12} sm={12} md={6} lg={4}>
              <Link
                to={"/savannah"}
                style={{
                  textDecoration: "none",
                  color: "#FFCB68",
                }}
              >
                <Card>
                  <CardActionArea>
                    <CardMedia
                      component="img"
                      height="300"
                      image={Image51}
                      alt="truck repair"
                    />
                    <CardContent>
                      <Typography
                        gutterBottom
                        variant="h5"
                        component="div"
                        fontWeight={"bold"}
                        sx={{ mt: 1, mb: 1 }}
                      >
                        <PlaceIcon sx={{ mt: -1, color: "#FFCB68" }} />{" "}
                        Savannah, Georgia
                      </Typography>
                      <Typography variant="body2" color="text.secondary">
                        {lang === "EN" ? (
                          <>
                            Operating in Savannah, Georgia, we provide dedicated
                            roadside assistance for trucks{" "}
                            <b>within a 100-mile radius of the city</b>. Our
                            goal is to offer swift, reliable support whenever
                            you need it, ensuring your operations continue
                            without interruption, even in the most challenging
                            situations.{" "}
                          </>
                        ) : (
                          <>
                            Operando en Savannah, Georgia, proporcionamos
                            asistencia vial dedicada para camiones{" "}
                            <b>dentro de un radio de 100 millas de la ciudad</b>
                            . Nuestro objetivo es ofrecer un apoyo rápido y
                            confiable siempre que lo necesites, asegurando que
                            tus operaciones continúen sin interrupciones,
                            incluso en las situaciones más desafiantes.{" "}
                          </>
                        )}
                      </Typography>
                    </CardContent>
                  </CardActionArea>
                </Card>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default LocationSection;
