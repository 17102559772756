import React, { useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Box,
  Accordion,
  AccordionSummary,
  AccordionDetails,
  TextField,
  IconButton,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
import ExpandMoreIcon from "@mui/icons-material/ExpandMore";
import SearchIcon from "@mui/icons-material/Search";
import faqs from "../FAQ/faqs.json";
import Banner5 from "../../assets/webp/banner5.webp";
import CallButtons from "../../components/CallButtons";
import SearchOffIcon from "@mui/icons-material/SearchOff";

const SearchButton = () => (
  <IconButton>
    <SearchIcon />
  </IconButton>
);

const FAQSection = () => {
  const { lang } = useUiStore();

  const [searchTerm, setSearchTerm] = useState("");
  const [filteredItems, setFilteredItems] = useState(faqs.faqs);
  const [expanded, setExpanded] = useState(false);

  const handleChange = (panel) => (event, isExpanded) => {
    setExpanded(isExpanded ? panel : false);
  };

  const searchFAQ = (e) => {
    const searchTerm = e.target.value.toLowerCase();
    setSearchTerm(searchTerm);

    const filteredItems = faqs.faqs.filter(
      (item) =>
        item.eng_metatitle.toLowerCase().includes(searchTerm) ||
        item.esp_metatitle.toLowerCase().includes(searchTerm) ||
        item.eng_metadescription.toLowerCase().includes(searchTerm) ||
        item.esp_metadescription.toLowerCase().includes(searchTerm)
    );

    setFilteredItems(filteredItems);
  };
  return (
    <>
      {/* SEO TITLES */}
      <Typography variant="h1" sx={{ display: "none" }}>
        Truck Roadside Assistance Frequently Asked Questions
      </Typography>
      
      {/* Desktop view */}
      <Box
        alt="FAQS banner"
        title="FAQS banner"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${Banner5});`,
          backgroundPosition: "center",
          color: "white",
          height: 300,
          border: 0,
          textAlign: "center",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <Typography
          variant="body1"
          sx={{ paddingTop: 15, fontSize: 40, fontFamily: "Ethnocentric" }}
        >
          FAQS
        </Typography>
        <CallButtons />
      </Box>

      {/* Phone and tablet Image View */}
      <Box
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.5), rgba(0, 0, 0, 1)), url(${Banner5});`,
          backgroundPosition: "left center",
          color: "white",
          border: 0,
          textAlign: "center",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
          pb: 10,
        }}
      >
        <Typography
          variant="body1"
          sx={{ fontSize: 40, paddingTop: 12, fontFamily: "Ethnocentric" }}
        >
          FAQS
        </Typography>
        <CallButtons />
      </Box>

      {/* Most searched questions on mobile */}
      <Box
        sx={{
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
        }}
      >
        <Container sx={{ padding: 5 }}>
          <Grid item xs={12}>
            <Card variant="outlined">
              <Box sx={{ backgroundColor: "rgb(253,211,131,0.7)", p: 2 }}>
                <Typography fontWeight={"bold"} gutterBottom>
                  {lang === "EN"
                    ? "Most searched questions"
                    : "Preguntas más buscadas"}
                </Typography>
                <Typography sx={{ fontSize: 12 }} gutterBottom>
                  {lang === "EN"
                    ? "Here, we have compiled a list of the most frequently asked questions to help you quickly find the information you need."
                    : "Aquí hemos recopilado una lista de las preguntas más frecuentes para ayudarte a encontrar rápidamente la información que necesitas."}
                </Typography>
              </Box>
              <CardContent>
                <Link
                  to={"/faqs/why-is-cioffi-express-services-the-best-choice"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "Why is Cioffi Express Services the Best Choice?"
                      : "¿Por qué Cioffi Express Services es la Mejor Opción?"}
                  </Typography>
                </Link>

                <Link
                  to={"/faqs/what-are-cioffi-express-services-working-hours"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "What Are Cioffi Express Services' Working Hours?"
                      : "¿Cuál es el Horario de Trabajo de Cioffi Express Services?"}
                  </Typography>
                </Link>

                <Link to={"/faqs/how-can-i-contact-cioffi-express-services"}>
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "How Can I Contact Cioffi Express Services?"
                      : "¿Cómo Puedo Contactar a Cioffi Express Services?"}
                  </Typography>
                </Link>

                <Link
                  to={"/faqs/what-routes-does-cioffi-express-services-cover"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "What Routes Does Cioffi Express Services Cover?"
                      : "¿Qué rutas cubre Cioffi Express Services?"}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
        </Container>
      </Box>

      {/* Search Bar */}
      <Box sx={{ background: "#EAEDED", marginBottom: 5 }}>
        <Container sx={{ padding: 5 }}>
          <Grid
            container
            sx={{ flexGrow: 1 }}
            spacing={5}
            justifyContent="center"
            alignItems="center"
          >
            <Grid item xs={12} sm={12} md={4} lg={4}>
              <Typography
                variant="body1"
                sx={{ paddingBottom: 3, fontWeight: "bold", fontSize: 25 }}
                textAlign="center"
              >
                {lang === "EN" ? "Help center" : "Centro de ayuda"}
              </Typography>
              <Typography variant="body1" textAlign="justify">
                {/* <FormatQuoteIcon sx={{fontSize:40}}/> */}
                {lang === "EN"
                  ? "Our goal is to provide you with quick and accurate information to enhance your experience with us. If you don't find the answer you're looking for, please don't hesitate to contact our support team. We're here to help!"
                  : "Nuestro objetivo es proporcionarte información rápida y precisa para mejorar tu experiencia con nosotros. Si no encuentras la respuesta que buscas, no dudes en contactar a nuestro equipo de soporte. ¡Estamos aquí para ayudarte!"}
              </Typography>
            </Grid>
            <Grid item xs={12} sm={12} md={8} lg={8}>
              <TextField
                label={lang === "EN" ? "Search" : "Buscar"}
                variant="outlined"
                InputProps={{ endAdornment: <SearchButton /> }}
                sx={{
                  "& .MuiInputBase-root": {
                    backgroundColor: "white",
                  },
                  "& .MuiFilledInput:after": {
                    backgroundColor: "white",
                  },
                  // focused color for input with variant='filled'
                  "& .MuiFilledInput-underline:after": {
                    borderBottomColor: "#FDD383",
                  },
                  // "& label.Mui-focused": {
                  //   color: "#D69E48",
                  // },
                }}
                fullWidth
                value={searchTerm}
                onChange={(e) => searchFAQ(e)}
              />
            </Grid>
          </Grid>
        </Container>
      </Box>
      {/* END Search Bar */}

      {/* Body most important questions */}
      <Container sx={{ paddingBottom: 10 }}>
        <Box
          sx={{
            display: {
              xs: "none",
              sm: "none",
              md: "block",
              lg: "block",
            },
          }}
        >
          <Grid
            container
            sx={{
              flexGrow: 1,
            }}
            spacing={1}
          >
            <Grid item xs={12} textAlign="center">
              <Typography
                sx={{ paddingBottom: 3, fontWeight: "bold", fontSize: 25 }}
              >
                {lang === "EN"
                  ? "Frequently Asked Questions"
                  : "Preguntas Frecuentes"}
              </Typography>
            </Grid>
          </Grid>
        </Box>
        {/* Body */}

        {/* FAQ LIST */}
        <Grid container sx={{ flexGrow: 1 }} spacing={3}>
          <Grid
            item
            md={4}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Card variant="outlined">
              <Box sx={{ backgroundColor: "rgb(253,211,131,0.7)", p: 2 }}>
                <Typography fontWeight={"bold"} gutterBottom>
                  {lang === "EN"
                    ? "Most searched questions"
                    : "Preguntas más buscadas"}
                </Typography>
                <Typography sx={{ fontSize: 12 }} gutterBottom>
                  {lang === "EN"
                    ? "Here, we have compiled a list of the most frequently asked questions to help you quickly find the information you need."
                    : "Aquí hemos recopilado una lista de las preguntas más frecuentes para ayudarte a encontrar rápidamente la información que necesitas."}
                </Typography>
              </Box>
              <CardContent>
                <Link
                  to={"/faqs/why-is-cioffi-express-services-the-best-choice"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "Why is Cioffi Express Services the Best Choice?"
                      : "¿Por qué Cioffi Express Services es la Mejor Opción?"}
                  </Typography>
                </Link>

                <Link
                  to={"/faqs/what-are-cioffi-express-services-working-hours"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "What Are Cioffi Express Services' Working Hours?"
                      : "¿Cuál es el Horario de Trabajo de Cioffi Express Services?"}
                  </Typography>
                </Link>

                <Link to={"/faqs/how-can-i-contact-cioffi-express-services"}>
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "How Can I Contact Cioffi Express Services?"
                      : "¿Cómo Puedo Contactar a Cioffi Express Services?"}
                  </Typography>
                </Link>

                <Link
                  to={"/faqs/what-routes-does-cioffi-express-services-cover"}
                >
                  <Typography gutterBottom fontWeight={"bold"}>
                    ‣&nbsp;
                    {lang === "EN"
                      ? "What Routes Does Cioffi Express Services Cover?"
                      : "¿Qué rutas cubre Cioffi Express Services?"}
                  </Typography>
                </Link>
              </CardContent>
            </Card>
          </Grid>
          <Grid item xs={12} sm={12} md={8}>
            {filteredItems.length > 0 ? (
              filteredItems.map((article, index) => (
                <>
                  <Accordion
                    key={index}
                    expanded={expanded === "panel" + index}
                    onChange={handleChange("panel" + index)}
                  >
                    <AccordionSummary
                      expandIcon={<ExpandMoreIcon />}
                      sx={{
                        backgroundColor: "rgb(253,211,131,0.3)",
                      }}
                      aria-controls="panel1bh-content"
                      id="panel1bh-header"
                    >
                      <Typography
                        variant="h2"
                        sx={{
                          fontSize: 15,
                          fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        }}
                        fontWeight={"bold"}
                      >
                        {lang === "EN"
                          ? article.eng_metatitle
                          : article.esp_metatitle}
                      </Typography>
                      {/* <Typography sx={{ color: "text.secondary" }}>
                        I am an accordion
                      </Typography> */}
                    </AccordionSummary>
                    <AccordionDetails>
                      <Typography gutterBottom>
                        {lang === "EN"
                          ? article.eng_metadescription
                          : article.esp_metadescription}
                      </Typography>
                      <Link to={"/faqs/" + article.url}>
                        <Typography>
                          {lang === "EN" ? "Read more" : "Leer más"}
                        </Typography>
                      </Link>
                    </AccordionDetails>
                  </Accordion>
                </>
              ))
            ) : (
              <Grid item xs={12} textAlign="center">
                <Typography
                  variant="button"
                  sx={{ fontWeight: "bold", color: "#CD6155" }}
                  display="block"
                  gutterBottom
                >
                  <SearchOffIcon />
                  &nbsp;
                  {lang === "EN"
                    ? "No Results Found"
                    : "No se Encontraron Resultados"}
                </Typography>
                <Typography variant="caption" display="block">
                  {lang === "EN"
                    ? "Sorry, no results were found for your search. Please try using different keywords or check your spelling."
                    : "Lo sentimos, no se encontraron resultados para tu búsqueda. Por favor, intenta usar palabras clave diferentes o verifica tu ortografía."}
                </Typography>
              </Grid>
            )}
          </Grid>

          <Grid item xs={12} sx={{ mt: 5 }} textAlign="center">
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>&#8592;&nbsp;&nbsp; Go to home</>
                ) : (
                  <>&#8592;&nbsp;&nbsp; Volver a página principal</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
        {/* FAQ LIST */}
      </Container>
    </>
  );
};

export default FAQSection;
