import React from "react";
import { Box, Container, Typography, Grid, Button } from "@mui/material";
import RemoveRoadIcon from "@mui/icons-material/RemoveRoad";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";

const Error404 = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Navbar />
      <Box sx={{ mt: 10, mb: 10 }} textAlign={"center"}>
        <Container>
          <Grid container>
            <Grid item xs={12}>
              {/* <Typography variant="h2" display="inline">404 |</Typography> */}
              <RemoveRoadIcon sx={{ fontSize: 100, mb: 5, color: "#D69E48" }} />
              <br />
              <Typography variant="h4" display="inline">
                <strong style={{ color: "#D69E48" }}>404</strong> |{" "}
                {lang === "EN" ? (
                  <>The page you requested was not found.</>
                ) : (
                  <>La página que buscas no fue encontrada.</>
                )}
              </Typography>
            </Grid>
            <Grid item xs={12} sx={{ mt: 5 }}>
              <Link
                to={"/"}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Button
                  variant="outlined"
                  sx={{
                    backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                    color: "black",
                    fontWeight: "bold",
                    border: "1px solid #FDD383",
                    "&:hover": {
                      color: "black",
                      backgroundColor: "rgb(253,211,131,0.7)",
                      border: "1px solid #FDD383",
                    },
                  }}
                >
                  {lang === "EN" ? (
                    <>Go to home</>
                  ) : (
                    <>Volver a página principal</>
                  )}
                </Button>
              </Link>
            </Grid>
          </Grid>
        </Container>
      </Box>
      <Footer />
    </>
  );
};

export default Error404;
