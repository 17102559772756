import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import SectionAbout from "./SectionAbout";

const AboutUs = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/about-us" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Cioffi Express Services is a family owned and operated mobile truck and trailer repair company🚛 We will come to you wherever you are in Orlando Florida ✅"
              : "Cioffi Express Services es una empresa familiar de reparación móvil de camiones y remolques🚛 Vamos hasta donde estés en Orlando Florida  ✅"
          }`}
        />
        <title>Cioffi Express Services | 24h Mobile Truck Assistance 🚚</title>
      </Helmet>
      <Navbar />
      <SectionAbout />
      <Footer />
    </>
  );
};

export default AboutUs;
