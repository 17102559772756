import React, { useEffect } from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import LocationSection from "./LocationSection";

const Location = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/location" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Cioffi Express Services assists your truck in Orlando Florida or 100 miles around. 🥇THE BEST ROAD SERVICE TECHNICIANS are on their way! ⇨ 🚛"
              : "Cioffi Express Services asiste tu camión en Orlando Florida y 100 millas alrededor🚛 ¡Van en camino 🥇 LOS MEJORES TÉCNICOS en servicio de carretera!"
          }`}
        />
        <title>Location - CES Mobile Truck & Trailer Repair 🚚</title>
      </Helmet>
      <Navbar />
      <LocationSection />
      <Footer />
    </>
  );
};

export default Location;
