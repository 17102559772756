import React, { useState } from "react";
import axios from "axios";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  TextField,
  InputAdornment,
  IconButton,
  LinearProgress,
  Card,
  CardContent,
  Alert,
  AlertTitle,
} from "@mui/material";
import { useSnackbar } from "notistack";
import { useFormik } from "formik";
import * as yup from "yup";
import TiresWallpaper from "../../assets/webp/tires-wallpaper.webp";
import CESLOGO from "../../assets/webp/Trucking-Logo-05.webp";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";
import PinDropIcon from "@mui/icons-material/PinDrop";
import InfoOutlinedIcon from "@mui/icons-material/InfoOutlined";
import { useUiStore } from "../../hooks/useUiStore";

//Constante con el formato de validación para cada campo-----------------------------------------------------
const validationSchema = yup.object({
  name: yup
    .string("Enter your name")
    .required("Name is required")
    .max(50, "Name should be maximum 50 characters."),
  email: yup
    .string("Enter your email")
    .email("Enter a valid email")
    .required("Email is required")
    .max(50, "Email should be maximum 50 characters."),
  phone: yup
    .string("Enter your phone")
    .required("Phone is required")
    .max(50, "Phone should be maximum 50 characters."),
  makemodel: yup
    .string("Enter your Make Model")
    .required("Make Model is required")
    .max(50, "Make Model should be maximum 50 characters."),
  yearmodel: yup
    .string("Enter your Year Model")
    .required("Year Model is required")
    .max(50, "Year Model should be maximum 50 characters."),
  message: yup
    .string("Enter your Message")
    .required("Message is required")
    .max(200, "Message should be maximum 200 characters."),
});

const FormSection = () => {
  const [loading, setLoading] = useState(false);
  const { enqueueSnackbar, closeSnackbar } = useSnackbar();
  const { lang } = useUiStore();

  //Variable que guarda la opción Cerrar de los Snackbars
  //   const action = (key) => (
  //     <>
  //       <IconButton
  //         variant="outlined"
  //         sx={{
  //           color: "white",
  //         }}
  //         onClick={() => {
  //           closeSnackbar(key);
  //         }}
  //       >
  //         <CloseIcon />
  //       </IconButton>
  //     </>
  //   );

  //Setear los valores a evaluar por el formulario-------------------------------------------------------------
  const formik = useFormik({
    initialValues: {
      name: "",
      email: "",
      phone: "",
      makemodel: "",
      yearmodel: "",
      message: "",
    },
    validationSchema: validationSchema, //Se le pasa la costante de formato de cada campo
    onSubmit: (values, { resetForm }) => {
      //Si llega acá es porque pasó todas las validaciones, le enviamos los values
      //de cada campo en el formulario, se obtienen con el nombre de cada campo
      //para este caso particular: values.email y values.password
      signIn(values, resetForm);
    },
  });

  //Función de reconocimiento de combinación correcta email - password
  const signIn = (values, resetForm) => {
    //Le indicamos a la app el comienzo del progress bar
    setLoading(true);

    //Desestructuramos values y
    //guardamos como constantes los valores introducidos en formulario
    const { name, email, phone, makemodel, yearmodel, message } = values;

    //Guardamos en payload el body en formato json para pasarle al servicio login
    const payload = {
      name,
      email,
      phone,
      makemodel,
      yearmodel,
      message,
    };

    // Consumimos el servicio login a través de axios
    axios
      .post(`/email/sendContactUsEmail`, payload)
      .then((res) => {
        //Se desestructura la respuesta del servicio
        const { ok, msg } = res.data;

        if (ok && msg === "Correo enviado") {
          //Detener el progress bar
          setLoading(false);
          resetForm();

          //Mensaje de correo enviado exitoso
          enqueueSnackbar(
            lang === "EN"
              ? "Message was sent sucessfully, we'll call you!"
              : "¡Mensaje enviado exitosamente, te llamaremos!",
            {
              variant: "success",
              autoHideDuration: 2000,
              // action,
            }
          );
        }
      })
      .catch((e) => {
        //catch es la respuesta de error en la promesa
        /*Sí los servicios están OFF, retornamos este mensaje*/
        if (e.response === undefined) {
          //Si hay error se detiene el progress bar
          setLoading(false);
          enqueueSnackbar(
            lang === "EN"
              ? "An error ocurred. Please try again!"
              : "¡Ha ocurrido un error, intente de nuevo!",
            {
              variant: "error",
              autoHideDuration: 3000,
              // action,
            }
          );
          return 1;
        }

        /*Si ocurre algo en el request, retornamos esto*/
        const { msg, ok } = e.response.data;

        if (msg === undefined || msg === null || msg === "") {
          setLoading(false);
          enqueueSnackbar(
            lang === "EN"
              ? "An error ocurred. Please try again!"
              : "¡Ha ocurrido un error, intente de nuevo!",
            {
              variant: "error",
              autoHideDuration: 3000,
              // action,
            }
          );
          return 1;
        }

        if (!ok) {
          setLoading(false);
          enqueueSnackbar(msg, {
            variant: "error",
            autoHideDuration: 3000,
            // action,
          });
          return 1;
        }
      });
  };

  return (
    <>
      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Instant Help
      </Typography>

      <Box
        alt="Tires banner contact form"
        title="Tires banner contact form"
        sx={{
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.99) 0%, rgba(0, 0, 0, 0.8) 50%, rgba(0, 0, 0, 1) 100%), url(${TiresWallpaper});`,
          backgroundPosition: "center",
          color: "white",
          height: "auto",
          border: 0,
          textAlign: "center",
        }}
      >
        <Container
          sx={{
            paddingBottom: 5,
          }}
        >
          {/* Principal Title Desktop View */}
          <Typography
            variant="body1"
            sx={{
              fontSize: 40,
              paddingTop: 10,
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              fontFamily: "Ethnocentric",
            }}
          >
            {lang === "EN" ? <>Contact us</> : <>Contáctanos</>}
          </Typography>

          {/* Principal Title Tablet and Phone View */}
          <Typography
            variant="body1"
            sx={{
              fontSize: 30,
              paddingTop: 10,
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
              fontFamily: "Ethnocentric",
            }}
          >
            {lang === "EN" ? <>Contact us</> : <>Contáctanos</>}
          </Typography>

          <Divider
            sx={{
              paddingTop: 0.5,
              marginTop: 2,
              marginBottom: 2,
              width: "30%",
              marginRight: "auto",
              marginLeft: "auto",
              background:
                "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
            }}
          />

          {/* Subtitle Desktop View */}
          <Typography
            variant="body1"
            sx={{
              color: "white",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              mb: 10,
              fontSize: 20,
            }}
          >
            {lang === "EN" ? (
              <>
                No matter if it's a simple fix or a more complex issue, we'll
                guide you step by step. <br />{" "}
                <b style={{ color: "#D69E48" }}>
                  Customer service is all for us
                </b>
                .
              </>
            ) : (
              <>
                No importa si se trata de una solución simple o de un problema
                más complejo, lo guiaremos paso a paso. <br />{" "}
                <b style={{ color: "#D69E48" }}>
                  La confianza de nuestros clientes lo es todo
                </b>
                .
              </>
            )}
          </Typography>

          {/* Subtitle Tablet and Phone View */}
          <Typography
            variant="body1"
            sx={{
              color: "white",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
              mb: 5,
            }}
          >
            {lang === "EN" ? (
              <>
                No matter if it's a simple fix or a more complex issue, we'll
                guide you step by step. <br />{" "}
                <b style={{ color: "#D69E48" }}>
                  Customer service is all for us
                </b>
                .
              </>
            ) : (
              <>
                No importa si se trata de una solución simple o de un problema
                más complejo, lo guiaremos paso a paso. <br />{" "}
                <b style={{ color: "#D69E48" }}>
                  La confianza de nuestros clientes lo es todo
                </b>
                .
              </>
            )}
          </Typography>

          {/* Begining social network and info section */}
          <Grid container sx={{ flexGrow: 1 }} columnSpacing={10}>
            {/* section phone and table responsive */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                position: "relative",
                display: { xs: "block", sm: "block", md: "none", lg: "none" },
                marginBottom: 5,
                textAlign: "justify",
              }}
            >
              <Box
                sx={{
                  mt: 1,
                  borderWidth: "4px",
                  borderStyle: "solid",
                  borderImage:
                    "linear-gradient(to right, rgba(214,158,72,1), #FFCB68) 1",
                  padding: 3,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  borderRadius: 1,
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="mailto:support@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 40, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      support@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="mailto:info@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 40, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      info@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="tel:4076745769"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <PermPhoneMsgIcon
                        sx={{ fontSize: 40, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      407-674-5769
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/people/Cioffi-Express-Services-Mobile-Truck-Repair/100089914560684/?mibextid=LQQJ4d"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <FacebookIcon sx={{ fontSize: 40, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      Facebook Profile
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/cestruckrepair"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <InstagramIcon sx={{ fontSize: 40, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      @cestruckrepair
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      textAlign: "left",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <PinDropIcon sx={{ fontSize: 40, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                       {lang === "EN" ? (
                        <>
                          Orlando, Tampa & Jacksonville - Florida
                          <br />
                          Atlanta, Savannah - Georgia
                        </>
                      ) : (
                        <>
                          Orlando, Tampa y Jacksonville - Florida
                          <br />
                          Atlanta, Savannah
                        </>
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* section desktop responsive */}
            <Grid
              item
              xs={12}
              sm={12}
              md={6}
              lg={6}
              sx={{
                position: "relative",
                display: { xs: "none", sm: "none", md: "block", lg: "block" },
                textAlign: "justify",
              }}
            >
              <Box sx={{ textAlign: "center" }}>
                <Box
                  component="img"
                  src={CESLOGO}
                  sx={{ width: 300, height: "auto" }}
                  display="inline"
                  alt="CES LOGO"
                  title="CES LOGO"
                />
              </Box>
              <Box
                sx={{
                  mt: 3,
                  borderWidth: "4px",
                  borderStyle: "solid",
                  borderImage:
                    "linear-gradient(to right, rgba(214,158,72,1), #FFCB68) 1",
                  padding: 3,
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  borderRadius: 1,
                }}
              >
                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="mailto:support@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 50, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      support@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="mailto:info@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 50, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      info@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    href="tel:4076745769"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <PermPhoneMsgIcon
                        sx={{ fontSize: 50, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      407-674-5769
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/people/Cioffi-Express-Services-Mobile-Truck-Repair/100089914560684/?mibextid=LQQJ4d"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <FacebookIcon sx={{ fontSize: 50, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      Facebook Profile
                    </Typography>
                  </Button>
                </Box>

                <Box sx={{ mb: 3 }}>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/cestruckrepair"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <InstagramIcon sx={{ fontSize: 50, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      @cestruckrepair
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                      textAlign: "left",
                      borderLeft: "2px solid #FFCB68",
                    }}
                  >
                    <Typography display="inline">
                      <PinDropIcon sx={{ fontSize: 50, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      {lang === "EN" ? (
                        <>
                          Orlando, Tampa & Jacksonville - Florida
                          <br />
                          Atlanta, Savannah - Georgia
                        </>
                      ) : (
                        <>
                          Orlando, Tampa y Jacksonville - Florida
                          <br />
                          Atlanta, Savannah
                        </>
                      )}
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>

            {/* form */}
            <Grid item xs={12} sm={12} md={6} lg={6} sx={{}}>
              <Box
                sx={{ backgroundColor: "white", padding: 3, color: "black" }}
              >
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                  }}
                >
                  <Alert
                    iconMapping={{
                      warning: <InfoOutlinedIcon fontSize="inherit" />,
                    }}
                    severity="warning"
                    sx={{
                      textAlign: "left",

                      marginBottom: 5,
                    }}
                  >
                    <AlertTitle>
                      {lang === "EN" ? (
                        <strong>Send us an instant message</strong>
                      ) : (
                        <strong>Envíanos un mensaje directo</strong>
                      )}
                    </AlertTitle>
                    {lang === "EN" ? (
                      <>Fill te form and we will call you!</>
                    ) : (
                      <>Llena el formulario y te llamaremos!</>
                    )}
                  </Alert>
                </Box>

                <Box
                  sx={{
                    display: {
                      xs: "none",
                      sm: "none",
                      md: "block",
                      lg: "block",
                    },
                  }}
                >
                  <Alert
                    iconMapping={{
                      warning: <InfoOutlinedIcon fontSize="inherit" />,
                    }}
                    severity="warning"
                    sx={{
                      textAlign: "left",

                      marginBottom: 5,
                    }}
                  >
                    <AlertTitle>
                      {lang === "EN" ? (
                        <strong>Send us an instant message</strong>
                      ) : (
                        <strong>Envíanos un mensaje directo</strong>
                      )}
                    </AlertTitle>
                    {lang === "EN" ? (
                      <>Fill te form and we will call you!</>
                    ) : (
                      <>Llena el formulario y te llamaremos!</>
                    )}
                  </Alert>
                </Box>

                {/* Inicio de formulario */}
                <form
                  onSubmit={formik.handleSubmit}
                  style={{ textAlign: "left" }}
                >
                  {/* Campo Name */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Name:</> : <>Nombre:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    id="name"
                    label={
                      lang === "EN" ? "Type your Name" : "Escribe tu nombre"
                    }
                    name="name"
                    autoComplete="name"
                    value={formik.values.name}
                    onChange={formik.handleChange}
                    error={formik.touched.name && Boolean(formik.errors.name)}
                    helperText={formik.touched.name && formik.errors.name}
                  />

                  {/* Campo Phone */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Phone Number:</> : <>Teléfono:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    id="phone"
                    label={
                      lang === "EN"
                        ? "Type your Phone Number"
                        : "Escribe tu teléfono"
                    }
                    name="phone"
                    autoComplete="phone"
                    value={formik.values.phone}
                    onChange={formik.handleChange}
                    error={formik.touched.phone && Boolean(formik.errors.phone)}
                    helperText={formik.touched.phone && formik.errors.phone}
                  />

                  {/* Campo Email */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Email:</> : <>Correo:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    id="email"
                    label={
                      lang === "EN"
                        ? "Type your Email Address"
                        : "Escribe tu correo electrónico"
                    }
                    name="email"
                    autoComplete="email"
                    value={formik.values.email}
                    onChange={formik.handleChange}
                    error={formik.touched.email && Boolean(formik.errors.email)}
                    helperText={formik.touched.email && formik.errors.email}
                  />

                  {/* Campo Make Model */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Make Model</> : <>Marca Modelo:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    id="makemodel"
                    label={
                      lang === "EN"
                        ? "Type the Make Model"
                        : "Escribe la Marca Modelo"
                    }
                    name="makemodel"
                    autoComplete="makemodel"
                    value={formik.values.makemodel}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.makemodel &&
                      Boolean(formik.errors.makemodel)
                    }
                    helperText={
                      formik.touched.makemodel && formik.errors.makemodel
                    }
                  />

                  {/* Campo Year Model */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Year:</> : <>Año:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 50 }}
                    fullWidth
                    id="yearmodel"
                    label={lang === "EN" ? "Type the Year" : "Escribe el Año"}
                    name="yearmodel"
                    autoComplete="yearmodel"
                    value={formik.values.yearmodel}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.yearmodel &&
                      Boolean(formik.errors.yearmodel)
                    }
                    helperText={
                      formik.touched.yearmodel && formik.errors.yearmodel
                    }
                  />

                  {/* Textarea message */}
                  <Typography variant="overline">
                    {lang === "EN" ? <>Message:</> : <>Mensaje:</>}{" "}
                    <b style={{ color: "red" }}>*</b>
                  </Typography>
                  <TextField
                    sx={{
                      marginTop: 0,
                      "& label.Mui-focused": {
                        color: "grey",
                      },
                      "& .MuiInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='filled'
                      "& .MuiFilledInput-underline:after": {
                        borderBottomColor: "#D69E48",
                      },
                      // focused color for input with variant='outlined'
                      "& .MuiOutlinedInput-root": {
                        "&.Mui-focused fieldset": {
                          borderColor: "#D69E48",
                        },
                      },
                    }}
                    margin="normal"
                    variant="filled"
                    inputProps={{ maxLength: 200 }}
                    id="message"
                    label={
                      lang === "EN" ? "Type your Message" : "Escribe tu Mensaje"
                    }
                    multiline
                    fullWidth
                    rows={5}
                    name="message"
                    autoComplete="message"
                    value={formik.values.message}
                    onChange={formik.handleChange}
                    error={
                      formik.touched.message && Boolean(formik.errors.message)
                    }
                    helperText={formik.touched.message && formik.errors.message}
                  />

                  {/*Loading progress bar */}
                  {loading ? <LinearProgress sx={{ mt: 2 }} /> : <></>}

                  {/* Botón submit */}
                  <Button
                    type="submit"
                    disabled={loading}
                    fullWidth
                    variant="contained"
                    sx={{
                      mt: 3,
                      backgroundColor: "#FFCB68", //FDD383 claro D69E48 oscuro
                      color: "black",
                      fontWeight: "bold",
                      "&:hover": {
                        backgroundColor: "#272626",
                        color: "white",
                      },
                    }}
                  >
                    {lang === "EN" ? <>Send Message</> : <>Enviar Mensaje</>}
                  </Button>
                </form>
              </Box>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default FormSection;
