import React from "react";
import {
  Container,
  Box,
  Button,
  Typography,
  Grid,
  Divider,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TextsmsIcon from '@mui/icons-material/Textsms';

const CallButtons = () => {
  const { lang } = useUiStore();
  return (
    <>
      {/* Responsive Desktop */}
      <Box
        sx={{
          marginTop: 5,
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
        textAlign={"center"}
      >
        <Button
          variant="contained"
          href="tel:4076745769"
          sx={{
            background:
              "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
            color: "black",
            fontWeight: "bold",
            border: "2px solid #B9893F",
            "&:hover": {
              color: "white",
              background: "transparent",
            },
          }}
        >
          {lang === "EN" ? <>Call us now &nbsp;</> : <>LLámanos &nbsp;</>}
          <PhoneEnabledIcon />
        </Button>
        <Button
          variant="contained"
          href="sms:+14076745769"
          sx={{
            marginLeft: 5,
            background:
              "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
            color: "black",
            fontWeight: "bold",
            border: "2px solid #B9893F",
            "&:hover": {
              color: "white",
              background: "transparent",
            },
          }}
        >
          {lang === "EN" ? <>Chat with us &nbsp;</> : <>Escríbenos &nbsp;</>}
          <TextsmsIcon />
        </Button>
      </Box>

      {/* Responsive Tablets and Phones */}
      <Box
        textAlign={"center"}
        sx={{
          marginTop: 5,
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
        }}
      >
        <Button
          variant="contained"
          href="tel:4076745769"
          target="_blank"
          link="noreferer"
          sx={{
            background:
              "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
            color: "black",
            fontWeight: "bold",
            border: "2px solid #B9893F",
            "&:hover": {
              color: "white",
              background: "transparent",
            },
          }}
        >
          {lang === "EN" ? <>Call us now &nbsp;</> : <>LLámanos &nbsp;</>}
          <PhoneEnabledIcon />
        </Button>
        <Box sx={{ marginTop: 3 }}>
          <Button
            href="sms:+14076745769"
            target="_blank"
            link="noreferer"
            variant="contained"
            sx={{
              background:
                "linear-gradient(0deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)", //FDD383 claro D69E48 oscuro
              color: "black",
              fontWeight: "bold",
              border: "2px solid #B9893F",
              "&:hover": {
                color: "white",
                background: "transparent",
              },
            }}
          >
            {lang === "EN" ? <>Chat with us &nbsp;</> : <>Escríbenos &nbsp;</>}
            <TextsmsIcon />
          </Button>
        </Box>
      </Box>
    </>
  );
};

export default CallButtons;
