import React, { useEffect, useState } from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  Card,
  CardContent,
} from "@mui/material";
import { Helmet } from "react-helmet";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
import faqs from "../FAQ/faqs.json";

const FaqArticleSection = ({ faqArticleInfo }) => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);

  const [randomFaqs, setRandomFaqs] = useState([]);

  useEffect(() => {
    const getRandomFaqs = (array, qty) => {
      const randomFaqs = [];
      const copyArray = array.slice();

      for (let i = 0; i < qty; i++) {
        const randomIndex = Math.floor(Math.random() * copyArray.length);
        const question = copyArray.splice(randomIndex, 1)[0];
        randomFaqs.push(question);
      }

      return randomFaqs;
    };

    const questions = getRandomFaqs(faqs.faqs, 10);
    setRandomFaqs(questions);
  }, []);

  return (
    <>
      <Helmet>
        <link
          href={"https://cestruckrepair.com/faqs/" + faqArticleInfo.url}
          rel="canonical"
        />
        <title>
          {`${
            lang === "EN"
              ? faqArticleInfo.eng_metatitle
              : faqArticleInfo.esp_metatitle
          }`}{" "}
          | CES Mobile Truck & Trailer Repair 🚚
        </title>
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? faqArticleInfo.eng_metadescription
              : faqArticleInfo.esp_metadescription
          }`}
        />
      </Helmet>

      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        {lang === "EN"
          ? faqArticleInfo.eng_metatitle
          : faqArticleInfo.esp_metatitle}
      </Typography>
      {/* <Typography variant="h2" sx={{ display: "none" }}>
        {lang === "EN"
          ? faqArticleInfo.eng_metadescription
          : faqArticleInfo.esp_metadescription}
      </Typography> */}

      <Container
        sx={{
          paddingTop: 5,
          paddingBottom: 10,
        }}
      >
        {/* Títulos */}
        <Grid container spacing={5}>
          {/* Other articles sections - START */}
          <Grid
            item
            xs={12}
            sm={12}
            md={3}
            lg={3}
            xl={3}
            order={{ xs: 2, lg: 1 }}
          >
            <Card variant="outlined">
              <Box sx={{ backgroundColor: "rgb(253,211,131,0.7)", p: 2 }}>
                <Typography gutterBottom>
                  <b>{lang === "EN" ? "Other articles" : "Otros articulos"}</b>
                </Typography>
                <Typography sx={{ fontSize: 12 }} gutterBottom>
                  {lang === "EN"
                    ? "Here, we have compiled a list of the most frequently asked questions to help you quickly find the information you need."
                    : "Aquí hemos recopilado una lista de las preguntas más frecuentes para ayudarte a encontrar rápidamente la información que necesitas."}
                </Typography>
              </Box>
              <CardContent>
                {randomFaqs.map((randomFaq, index) => (
                  <div key={index}>
                    <Link to={"/faqs/" + `${randomFaq.url}`} style={{}}>
                      <Typography
                        sx={{ fontSize: 14, mb: 1, fontWeight: "bold" }}
                        gutterBottom
                      >
                        ‣&nbsp;
                        {lang === "EN"
                          ? `${randomFaq.eng_title}`
                          : `${randomFaq.esp_title}`}
                      </Typography>
                    </Link>
                  </div>
                ))}
              </CardContent>
            </Card>
          </Grid>
          {/* Other articles sections - END */}
          <Grid
            item
            xs={12}
            sm={12}
            md={9}
            lg={9}
            xl={9}
            order={{ xs: 1, lg: 2 }}
          >
            <Typography sx={{ fontSize: 35, mb: 4 }}>
              {lang === "EN"
                ? faqArticleInfo.eng_metatitle
                : faqArticleInfo.esp_metatitle}
            </Typography>
            <div
              dangerouslySetInnerHTML={{
                __html:
                  lang === "EN"
                    ? faqArticleInfo.eng_text
                    : faqArticleInfo.esp_text,
              }}
            />
          </Grid>
        </Grid>

        <Grid container>
          <Grid item xs={12} className="mt-5" order={{ xs: 3, lg: 3 }}>
            <hr />
            <a
              href="tel:4076745769"
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Typography variant="overline">
                {lang === "EN" ? (
                  <b>
                    ⚠️ If you are in Orlando, Tampa, Atlanta, Savannah or
                    Jacksonville, and your truck stopped due to a mechanical or
                    electrical breakdown, we can help you.
                    <b style={{ color: "#D69E48" }}>
                      {" "}
                      Call Cioffi Express Services
                    </b>{" "}
                    at (407) 674-5769 and our technicians will come to the
                    rescue. <br />
                    <br />
                    At Cioffi Express we provide{" "}
                    <b style={{ color: "#D69E48" }}>
                      mobile repair service to trucks and trailers in Orlando,
                      Tampa, Atlanta, Savannah or Jacksonville
                    </b>
                    .
                  </b>
                ) : (
                  <b>
                    ⚠️ Si estás en Orlando, Tampa, Atlanta, Savannah o
                    Jacksonville, y tu camión se detuvo por una avería mecánica
                    o eléctrica, te podemos ayudar.{" "}
                    <b style={{ color: "#D69E48" }}>
                      Llama al servicio de atención al cliente de Cioffi Express
                      Services
                    </b>{" "}
                    al (407) 674-5769 y nuestros mecánicos irán al rescate.{" "}
                    <br />
                    <br />
                    En Cioffi Express brindamos servicio móvil de{" "}
                    <b style={{ color: "#D69E48" }}>
                      reparación a camiones y remolques en Orlando, Tampa,
                      Atlanta, Savannah y Jacksonville
                    </b>
                  </b>
                )}
              </Typography>
            </a>
            <hr />
          </Grid>

          <Grid
            item
            xs={12}
            textAlign="center"
            className="mt-5"
            order={{ xs: 4, lg: 4 }}
          >
            <Link
              to={"/faqs"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>&#8592;&nbsp;&nbsp; Back to FAQS</>
                ) : (
                  <>&#8592;&nbsp;&nbsp; Volver a FAQS</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default FaqArticleSection;
