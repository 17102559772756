import React from "react";
import {
  Box,
  Grid,
  Container,
  Typography,
  Divider,
  Card,
  CardActions,
  CardContent,
  CardMedia,
  Button,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import BrowseGalleryTwoToneIcon from "@mui/icons-material/BrowseGalleryTwoTone";
import HandshakeTwoToneIcon from "@mui/icons-material/HandshakeTwoTone";
import RecordVoiceOverTwoToneIcon from "@mui/icons-material/RecordVoiceOverTwoTone";
import MetalFloorPattern from "../../assets/webp/metal-floor-pattern.webp";
import articles from "../../views/Blog/blogArticlesLinks.json";
import Tires from "../../assets/webp/tires.webp";
import DriverRest from "../../assets/webp/driver-rest.webp";
import OldTruck from "../../assets/webp/old-truck.webp";
import Mechanics from "../../assets/webp/mechanics.webp";
import WrongPreventive from "../../assets/webp/wrong-preventive.webp";
import TruckNight from "../../assets/webp/truck-night.webp";
import TruckStop from "../../assets/webp/truck-stop.webp";
import TruckShop from "../../assets/webp/best-truck-shop.webp";
import EquipTruck from "../../assets/webp/equip-truck.webp";
import BestTruckRepairOrlando from "../../assets/webp/best-truck-repair-orlando.webp";
import TruckMaintenance from "../../assets/webp/truck-maintenance.webp";
import TruckWashing from "../../assets/webp/truck-washing.webp";
import TruckWebsite from "../../assets/webp/truck-website.webp";

const InfoSection = () => {
  const { lang } = useUiStore();
  return (
    <Container sx={{ mb: 5, mt: 5 }}>
      <Grid container sx={{ flexGrow: 1, textAlign: "center" }}>
        
        <Grid item xs={12} textAlign="center">
          <Typography
            variant="body1"
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
              fontSize: 30,
              fontFamily: "Ethnocentric",
            }}
          >
            {lang === "EN" ? <>Check our blog</> : <>Lee nuestro blog</>}
          </Typography>
          <Typography
            variant="body1"
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              fontSize: 30,
              fontFamily: "Ethnocentric",
            }}
          >
            {lang === "EN" ? <>Check our blog</> : <>Lee nuestro blog</>}
          </Typography>
          {/* <Divider
            sx={{
              paddingTop: 0.5,
              width: "50%",
              marginRight: "auto",
              marginLeft: "auto",
              background:
                "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
            }}
          /> */}
        </Grid>

        <Grid item xs={12} sx={{ mt: 3 }}>
          <Typography variant="body1" sx={{ color: "#5D6D7E", fontSize: 20 }}>
            {lang === "EN" ? (
              <>
                ✔️&nbsp;Learn some tips about truck & trailer maintenance, truck
                stops and safe driving
              </>
            ) : (
              <>
                ✔️&nbsp;Conozca algunos consejos sobre mantenimiento de
                camiones, paradas y conducción segura
              </>
            )}
          </Typography>
        </Grid>
      </Grid>
      <Grid container spacing={4} sx={{ mt: 2 }}>
        {/* Cards de cada artículo */}

        {articles.articles.map((article, index) => (
          <Grid
            key={index}
            item
            xs={12}
            sm={6}
            md={6}
            lg={4}
            xl={4}
            sx={{
              display:
                index > 2
                  ? {
                      xs: "none",
                      sm: "none",
                      md: "none",
                      lg: "none",
                    }
                  : {
                      xs: "block",
                      sm: "block",
                      md: "block",
                      lg: "block",
                    },
            }}
          >
            {index < 3 ? (
              <Link
                to={"/blog/" + article.url}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Card sx={{ height: 470 }}>
                  <CardMedia
                    component="img"
                    alt={lang === "EN" ? article.eng_title : article.esp_title}
                    height="200"
                    image={
                      article.position === 0
                        ? Tires
                        : article.position === 1
                        ? DriverRest
                        : article.position === 2
                        ? OldTruck
                        : article.position === 3
                        ? Mechanics
                        : article.position === 4
                        ? WrongPreventive
                        : article.position === 5
                        ? TruckNight
                        : article.position === 6
                        ? TruckStop
                        : article.position === 7
                        ? TruckShop
                        : article.position === 8
                        ? EquipTruck
                        : article.position === 9
                        ? BestTruckRepairOrlando
                        : article.position === 10
                        ? TruckMaintenance
                        : article.position === 11
                        ? TruckWashing
                        : article.position === 12
                        ? TruckWebsite
                        : ""
                    }
                  />
                  <CardContent>
                    <Typography gutterBottom variant="h5" component="div">
                      {lang === "EN" ? article.eng_title : article.esp_title}
                    </Typography>
                    <Typography
                      variant="body2"
                      color="text.secondary"
                      className="truncate"
                    >
                      {lang === "EN"
                        ? article.eng_metadescription
                        : article.esp_metadescription}
                    </Typography>
                  </CardContent>
                  <CardActions>
                    {/* <Button size="small">Share</Button>
                     */}
                    <Button size="small">
                      {lang === "EN" ? "Learn More" : "Leer más"}
                    </Button>
                  </CardActions>
                </Card>
              </Link>
            ) : (
              <></>
            )}
          </Grid>
        ))}

        {/* <Grid item xs={12} sx={{ mt: 2 }} textAlign="center">
          {" "}
          <Link
            to={"/blog/"}
            style={{
              textDecoration: "none",
            }}
          >
            <Typography variant="body1" sx={{ fontSize: 15}}>
              {lang === "EN" ? (
                <>
                  Want to see more blog articles? <br />
                  Click here
                </>
              ) : (
                <>
                  ¿Quieres ver más artículos? <br />
                  Click aquí"
                </>
              )}
            </Typography>
          </Link>
        </Grid> */}

        <Grid item xs={12} sx={{ mt: 2, fontSize: 15 }} textAlign="center">
          <Link
            to={"/blog"}
            style={{
              textDecoration: "none",
              color: "black",
            }}
          >
            <Button
              variant="outlined"
              sx={{
                backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                color: "black",
                fontWeight: "bold",
                border: "1px solid #FDD383",
                "&:hover": {
                  color: "black",
                  backgroundColor: "rgb(253,211,131,0.7)",
                  border: "1px solid #FDD383",
                },
              }}
            >
              {lang === "EN" ? (
                <>Explore more blog articles&nbsp;&nbsp; &#8594;</>
              ) : (
                <>Explora más artículos del blog&nbsp;&nbsp; &#8594;</>
              )}
            </Button>
          </Link>
        </Grid>
      </Grid>
    </Container>
  );
};

export default InfoSection;
