import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FormSection from "./FormSection";

const Contact = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/contact" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "Need to find a truck repair service near you in Orlando Florida? Contact us! Ces Truck Repair will come to you! 🚀"
              : "¿Necesitas un servicio de reparación de camiones cerca de tí en Orlando Florida? ¡Contáctanos! Ces Truck Repair llega rápidamente a donde estés🚀"
          }`}
        />
        <title>Instant Help | CES Mobile Truck & Trailer Repair | Roadside Emergency 🚚</title>
      </Helmet>
      <Navbar />
      <FormSection />
      <Footer />
    </>
  );
};

export default Contact;
