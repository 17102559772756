import React from "react";
import { Container, Box, Typography, Grid } from "@mui/material";
import MechanicalService from "../../assets/webp/mechanics2.webp";
import WatchVideo from "../../assets/webp/Watch-video.webp";
import FormatQuoteIcon from "@mui/icons-material/FormatQuote";
import CallButtons from "../../components/CallButtons";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";

const ThirdSection = () => {
  const { lang } = useUiStore();
  return (
    <>
    {/* Desktop */}
      <Box
        alt="Mechanical Truck Services"
        title="Mechanical Truck Services"
        sx={{
          height: "auto",
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)),
          url(${MechanicalService});`,
          border: 0,
          color: "white",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundPosition: "center",
          display: { xs: "none", sm: "none", md: "block", lg: "block" },
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
          }}
        ></Grid>
        <Container>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sx={{ mb: 5 }}>
              {/* <iframe
                width="100%"
                height="600"
                src="https://www.youtube.com/embed/inFFXHAU79I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              {/* <iframe
                srcDoc="
        <style>
            body, .full {
                width: 100%;
                height: 100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/inFFXHAU79I'
            class='full'
        >
            <img
                src='https://vumbnail.com/inFFXHAU79I.jpg'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "
                style={{width: '100%', aspectRatio: '16/9'}}
              ></iframe> */}

              <Link to="/about-us">
                <Box
                  component="img"
                  sx={{ width: 1 }}
                  src={WatchVideo}
                  alt="CES Video About Us"
                  title="CES Video About Us"
                />
              </Link>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="body1"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                  fontSize: 30,
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    marginTop: -2,
                    transform: "scale(-1, 1)",
                  }}
                />
                {lang === "EN" ? (
                  <>
                    We understand breakdowns are not easy, leave it in our hands
                    to make every step of the process run smoothly with our
                    well-trained, relaible and knowledgeable dispatchers and
                    technicians.
                  </>
                ) : (
                  <>
                    Entendemos que las averías no son fáciles, déjelo en
                    nuestras manos para que cada paso del proceso se desarrolle
                    sin problemas con despachadores y personal bien capacitados.
                  </>
                )}
                <FormatQuoteIcon sx={{ fontSize: 40, marginTop: -2 }} />
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    marginTop: -2,
                    transform: "scale(-1, 1)",
                  }}
                />
                {lang === "EN" ? (
                  <>
                    We understand breakdowns are not easy, leave it in our hands
                    to make every step of the process run smoothly with our
                    well-trained, relaible and knowledgeable dispatchers and
                    technicians.
                  </>
                ) : (
                  <>
                    Entendemos que las averías no son fáciles, déjelo en
                    nuestras manos para que cada paso del proceso se desarrolle
                    sin problemas con despachadores y personal bien capacitados.
                  </>
                )}
                <FormatQuoteIcon sx={{ fontSize: 40, marginTop: -2 }} />
              </Typography>

              <CallButtons />
            </Grid>
          </Grid>
        </Container>
      </Box>

    {/* Mobile */}
      <Box
        alt="Mechanical Truck Services"
        title="Mechanical Truck Services"
        sx={{
          height: "auto",
          backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 1)),
          url(${MechanicalService});`,
          border: 0,
          color: "white",
          paddingTop: 10,
          paddingBottom: 10,
          backgroundPosition: "right center",
          display: { xs: "block", sm: "block", md: "none", lg: "none" },
        }}
      >
        <Grid
          container
          sx={{
            flexGrow: 1,
          }}
        ></Grid>
        <Container>
          <Grid container sx={{ flexGrow: 1 }}>
            <Grid item xs={12} sx={{ mb: 5 }}>
              {/* <iframe
                width="100%"
                height="300"
                src="https://www.youtube.com/embed/inFFXHAU79I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              {/* <iframe
                width="100%"
                height="300"
                data-src="https://www.youtube.com/embed/inFFXHAU79I"
                title="YouTube video player"
                allow="accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture; web-share"
                allowFullScreen
              ></iframe> */}
              {/* <iframe
                srcDoc="
        <style>
            body, .full {
                width: 100%;
                height:100%;
                margin: 0;
                position: absolute;
                display: flex;
                justify-content: center;
                object-fit: cover;
            }
        </style>
        <a
            href='https://www.youtube.com/embed/inFFXHAU79I'
            class='full'
        >
            <img
                src='https://vumbnail.com/inFFXHAU79I.jpg'
                class='full'
            />
            <svg
                version='1.1'
                viewBox='0 0 68 48'
                width='68px'
                style='position: relative;'
            >
                <path d='M66.52,7.74c-0.78-2.93-2.49-5.41-5.42-6.19C55.79,.13,34,0,34,0S12.21,.13,6.9,1.55 C3.97,2.33,2.27,4.81,1.48,7.74C0.06,13.05,0,24,0,24s0.06,10.95,1.48,16.26c0.78,2.93,2.49,5.41,5.42,6.19 C12.21,47.87,34,48,34,48s21.79-0.13,27.1-1.55c2.93-0.78,4.64-3.26,5.42-6.19C67.94,34.95,68,24,68,24S67.94,13.05,66.52,7.74z' fill='#f00'></path>
                <path d='M 45,24 27,14 27,34' fill='#fff'></path>
            </svg>
        </a>
    "
                style={{width: '100%', aspectRatio: '16/9'}}
              ></iframe> */}
              <Link to="/about-us">
                <Box
                  component="img"
                  sx={{ width: 1 }}
                  src={WatchVideo}
                  alt="CES Video About Us"
                  title="CES Video About Us"
                />
              </Link>
            </Grid>
            <Grid item xs={12} textAlign="center">
              <Typography
                variant="body1"
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                  fontSize: 30,
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    marginTop: -2,
                    transform: "scale(-1, 1)",
                  }}
                />
                {lang === "EN" ? (
                  <>
                    We understand breakdowns are not easy, leave it in our hands
                    to make every step of the process run smoothly with our
                    well-trained, relaible and knowledgeable dispatchers and
                    technicians.
                  </>
                ) : (
                  <>
                    Entendemos que las averías no son fáciles, déjelo en
                    nuestras manos para que cada paso del proceso se desarrolle
                    sin problemas con despachadores y personal bien capacitados.
                  </>
                )}
                <FormatQuoteIcon sx={{ fontSize: 40, marginTop: -2 }} />
              </Typography>
              <Typography
                variant="body1"
                sx={{
                  textAlign: "center",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <FormatQuoteIcon
                  sx={{
                    fontSize: 40,
                    marginTop: -2,
                    transform: "scale(-1, 1)",
                  }}
                />
                {lang === "EN" ? (
                  <>
                    We understand breakdowns are not easy, leave it in our hands
                    to make every step of the process run smoothly with our
                    well-trained, relaible and knowledgeable dispatchers and
                    technicians.
                  </>
                ) : (
                  <>
                    Entendemos que las averías no son fáciles, déjelo en
                    nuestras manos para que cada paso del proceso se desarrolle
                    sin problemas con despachadores y personal bien capacitados.
                  </>
                )}
                <FormatQuoteIcon sx={{ fontSize: 40, marginTop: -2 }} />
              </Typography>

              <CallButtons />
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default ThirdSection;
