import React, {useEffect} from "react";
import { Helmet } from "react-helmet";
import { useUiStore } from "../../hooks/useUiStore";
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import GallerySection from "./GallerySection";

const Gallery = () => {
  const { lang } = useUiStore();
  useEffect(() => {
    window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com/gallery" rel="canonical" />
        <meta
          name="description"
          content={`${
            lang === "EN"
              ? "🚛Need roadside service for your truck? Ces Truck Repair will arrive quickly with the solution Check out the results in our gallery!"
              : "🚛¿Necesitas servicio de carretera para tu camión? Ces Truck Repair llegará rápidamente con la solución ¡Mira los resultados en nuestra galería!"
          }`}
        />
        <title>Gallery | CES Mobile Truck & Trailer Repair 🚚</title>
      </Helmet>
      <Navbar />
      <GallerySection />
      <Footer />
    </>
  );
};

export default Gallery;
