import React, { useState, useEffect } from "react";

import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import FaqArticleSection from "./FaqArticleSection";
import { useParams, useNavigate } from "react-router-dom";
import faqs from "../FAQ/faqs.json";

const FaqArticle = () => {
  const [faqArticleInfo, setFaqArticleInfo] = useState({});
  const { article } = useParams();
  const navigate = useNavigate();
  useEffect(() => {
    if (faqs.faqs.filter((e) => e.url === article).length > 0) {
      /* vendors contains the element we're looking for */
      // console.log("si");
      setFaqArticleInfo(faqs.faqs.find((x) => x.url === article));
    } else {
      // console.log("no");
      navigate("/error");
    }
  }, [window.location.pathname]);

  return (
    <>
      <Navbar />
      <FaqArticleSection faqArticleInfo={faqArticleInfo} />
      <Footer />
    </>
  );
};

export default FaqArticle;
