import React, { useEffect } from "react";
import { Helmet } from "react-helmet";

//Components
import Navbar from "../../components/Navbar";
import Footer from "../../components/Footer";
import BannerSection from "./BannerSection";
import ServicesSection from "./ServicesSection";
import CarouselSection from "./CarouselSection";
import VideoSection from "./VideoSection";
import InfoSection from "./InfoSection";
import MapSection from "./MapSection";
import GoogleReviewsSection from "./GoogleReviewsSection";
import StepsSection from "./StepsSection";
import StatisticsSection from "./StatisticsSection";


function LandingPage() {
  // useEffect(() => {
  //   window.scrollTo({ top: 0, left: 0, behavior: "smooth" });
  // }, []);
  return (
    <>
      <Helmet>
        <link href="https://cestruckrepair.com" rel="canonical" />
        <meta
          name="description"
          content="Mobile Truck, Trailer, RVs, Tire Repair ⚒️, 24/7 Roadside Assistance ✅ We serve 100 miles around Orlando and Jacksonville, Call Us Now for a free quote."
        />
        <title>
          CES Mobile Truck & Trailer Repair | 24 Hour Roadside Assistance 🚚
        </title>
      </Helmet>

      <Navbar />

      <BannerSection />

      <ServicesSection />

      <MapSection />

      <StepsSection />

      <StatisticsSection />

      {/* <GoogleReviewsSection /> */}

      <CarouselSection />

      <VideoSection />

      <InfoSection />

      <Footer />
    </>
  );
}

export default LandingPage;
