import React, { useEffect } from "react";
import {
  Container,
  Box,
  Typography,
  Grid,
  Divider,
  Card,
  CardActionArea,
  CardMedia,
  CardContent,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
import BoltIcon from "@mui/icons-material/Bolt";
import BuildIcon from "@mui/icons-material/Build";
import Mechanics from "../../assets/webp/mechanics.webp";
import Tires from "../../assets/webp/tires.webp";
import Electrical from "../../assets/webp/mechanics2.webp";
import TireIcon from "../../assets/img/tire.png";

const mechanics = [
  "Breaks services",
  "Suspension services",
  "Hydraulic System Repair",
  "Diesel Engine services",
  "Lockouts",
  "Oil Change & Lube",
  "Jump starts",
];

const mechanicsSpanish = [
  "Servicio de frenos",
  "Servicio de suspensión",
  "Reparación de sistema hidráulico",
  "Servicio de Motor Diesel",
  "Abertura de puertas bloqueadas",
  "Servicio de cambio de aceite",
  "Arrancador",
];

const electrical = [
  "Battery Service",
  "Battery Change",
  "Electrical Service",
  "Electrical Repair",
  "Computer Diagnostics",
  "Maintenance Programs",
];

const electricalSpanish = [
  "Servicio de baterías",
  "Cambio de baterías",
  "Servicio Eléctrico",
  "Reparación eléctrica",
  "Diagnóstico de computadora",
  "Programas de mantenimiento",
];

const tires = ["New Tires", "Used Tires", "Tire Replacement", "Tire Repair"];

const tiresSpanish = [
  "Llantas nuevas",
  "Llantas usadas",
  "Reemplazo de llantas",
  "Reparación de llantas",
];

const ServicesSection = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Typography variant="h3" sx={{ display: "none" }}>
        Mechanics
      </Typography>
      <Typography variant="h3" sx={{ display: "none" }}>
        Electrical
      </Typography>
      <Typography variant="h3" sx={{ display: "none" }}>
        Tires
      </Typography>
      <Container
        sx={{
          paddingTop: 5,
          paddingBottom: 5,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }}>
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              {lang === "EN" ? (
                <>The roadside assistance you need</>
              ) : (
                <>Asistencia vial que necesitas</>
              )}
            </Typography>
            <Typography
              variant="body"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              {lang === "EN" ? (
                <>The roadside assistance you need</>
              ) : (
                <>Asistencia vial que necesitas</>
              )}
            </Typography>
            {/* <hr xs={{ minWidth: 0.1, maxWidth: 0.1}}/> */}
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
          </Grid>
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                color: "#5D6D7E",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 18,
              }}
            >
              {lang === "EN" ? (
                <>Check below the list of services we offer</>
              ) : (
                <>
                  A continuación puedes ver la lista de servicios que ofrecemos
                </>
              )}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                color: "#5D6D7E",
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                fontSize: 25,
              }}
            >
              {lang === "EN" ? (
                <>Check below the list of services we offer</>
              ) : (
                <>Descubre la lista de servicios que ofrecemos</>
              )}
            </Typography>
          </Grid>
        </Grid>

        <Grid container spacing={3}>
          <Grid
            item
            xs={12}
            sm={12}
            md={12}
            lg={12}
            sx={{
              marginTop: 5,
              display: { xs: "none", sm: "none", md: "block", lg: "block" },
            }}
          ></Grid>
          <Grid
            item
            xs={12}
            sx={{
              display: { xs: "block", sm: "block", md: "none", lg: "none" },
            }}
          ></Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Link
              to={"/services"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Card
                sx={{
                  background:
                    "linear-gradient(90deg, #E5E7E9 0%, white 50% , #E5E7E9 100%)",
                  // color: "white",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                elevation={15}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={Mechanics}
                  alt="Mechanical Service"
                  title="Mechanical Service"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    sx={{ fontWeight: "bold", color: "#272626", fontSize: 25 }}
                  >
                    {lang === "EN" ? <>Mechanics</> : <>Mecánica</>}
                  </Typography>

                  {lang === "EN"
                    ? mechanics.map((service, index) => (
                        <Typography key={index}>
                          <BuildIcon sx={{ fontSize: 15, color: "#AEB6BF" }} />
                          &nbsp; {service}
                        </Typography>
                      ))
                    : mechanicsSpanish.map((service, index) => (
                        <Typography key={index}>
                          <BuildIcon sx={{ fontSize: 15, color: "#AEB6BF" }} />
                          &nbsp; {service}
                        </Typography>
                      ))}
                </CardContent>
                {/* <CardActions
              sx={{
                // borderTop: "1px solid #FFCB68",
                // '&:hover': { backgroundColor: '#FFCB68', color: 'black'} 
                backgroundColor: '#272626',
              }}
            >
              <Button size="small" sx={{ color: '#FFCB68' }}>
                Read More
              </Button>
            </CardActions> */}
              </Card>
            </Link>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sx={{
              paddingTop: 5,
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
          ></Grid> */}

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Link
              to={"/services"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#F2F4F4",
                  background:
                    "linear-gradient(90deg, #E5E7E9 0%, white 50% , #E5E7E9 100%)",
                  height: 440,
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                }}
                elevation={15}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={Electrical}
                  alt="Electrical Services"
                  title="Electrical Services"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: 25 }}
                  >
                    {lang === "EN" ? <>Electrical</> : <>Electricidad</>}
                  </Typography>

                  {lang === "EN"
                    ? electrical.map((service, index) => (
                        <Typography key={index}>
                          <BoltIcon sx={{ color: "#FDD383" }} />
                          &nbsp; {service}
                        </Typography>
                      ))
                    : electricalSpanish.map((service, index) => (
                        <Typography key={index}>
                          <BoltIcon sx={{ color: "#FDD383" }} />
                          &nbsp; {service}
                        </Typography>
                      ))}
                </CardContent>
                {/* <CardActions
              sx={{
                // borderTop: "1px solid #FFCB68",
                // '&:hover': { backgroundColor: '#FFCB68', color: 'black'} 
                backgroundColor: '#272626',
              }}
            >
              <Button size="small" sx={{ color: '#FFCB68' }}>
                Read More
              </Button>
            </CardActions> */}
              </Card>
            </Link>
          </Grid>

          {/* <Grid
            item
            xs={12}
            sx={{
              paddingTop: 5,
              display: { xs: "block", sm: "block", md: "block", lg: "none" },
            }}
          ></Grid> */}

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Link
              to={"/services"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Card
                sx={{
                  backgroundColor: "#F2F4F4",
                  background:
                    "linear-gradient(90deg, #E5E7E9 0%, white 50% , #E5E7E9 100%)",
                  height: 440,
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  display: { xs: "none", sm: "none", md: "block", lg: "block" },
                }}
                elevation={15}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={Tires}
                  alt="Tires Services"
                  title="Tires Services"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h3"
                    component="div"
                    sx={{ fontWeight: "bold", fontSize: 25 }}
                  >
                    {lang === "EN" ? <>Tires</> : <>Llantas</>}
                  </Typography>

                  {lang === "EN"
                    ? tires.map((service, index) => (
                        <Typography key={index}>
                          <Box
                            component="img"
                            alt={"TireIcon" + index}
                            src={TireIcon}
                            sx={{ width: 0.05 }}
                            display="inline"
                          />
                          &nbsp; {service}
                        </Typography>
                      ))
                    : tiresSpanish.map((service, index) => (
                        <Typography key={index}>
                          <Box
                            component="img"
                            alt={"TireIcon" + index}
                            src={TireIcon}
                            sx={{ width: 0.05 }}
                            display="inline"
                          />
                          &nbsp; {service}
                        </Typography>
                      ))}
                </CardContent>
                {/* <CardActions
              sx={{
                // borderTop: "1px solid #FFCB68",
                // '&:hover': { backgroundColor: '#FFCB68', color: 'black'} 
                backgroundColor: '#272626',
              }}
            >
              <Button size="small" sx={{ color: '#FFCB68' }}>
                Read More
              </Button>
            </CardActions> */}
              </Card>
              <Card
                sx={{
                  backgroundColor: "#F2F4F4",
                  background:
                    "linear-gradient(90deg, #E5E7E9 0%, white 50% , #E5E7E9 100%)",
                  transition: "all .2s ease-in-out",
                  "&:hover": {
                    transform: "scale(1.05)",
                  },
                  display: { xs: "block", sm: "block", md: "none", lg: "none" },
                }}
                elevation={15}
              >
                <CardMedia
                  component="img"
                  height="200"
                  image={Tires}
                  alt="Tires Services"
                  title="Tires Services"
                />
                <CardContent>
                  <Typography
                    gutterBottom
                    variant="h5"
                    component="div"
                    sx={{ fontWeight: "bold" }}
                  >
                    {lang === "EN" ? <>Tires</> : <>Llantas</>}
                  </Typography>

                  {lang === "EN"
                    ? tires.map((service, index) => (
                        <Typography key={index}>
                          <Box
                            component="img"
                            alt={"TireIcon" + index}
                            src={TireIcon}
                            sx={{ width: 0.05 }}
                            display="inline"
                          />
                          &nbsp; {service}
                        </Typography>
                      ))
                    : tiresSpanish.map((service, index) => (
                        <Typography key={index}>
                          <Box
                            component="img"
                            alt={"TireIcon" + index}
                            src={TireIcon}
                            sx={{ width: 0.05 }}
                            display="inline"
                          />
                          &nbsp; {service}
                        </Typography>
                      ))}
                </CardContent>
                {/* <CardActions
              sx={{
                // borderTop: "1px solid #FFCB68",
                // '&:hover': { backgroundColor: '#FFCB68', color: 'black'} 
                backgroundColor: '#272626',
              }}
            >
              <Button size="small" sx={{ color: '#FFCB68' }}>
                Read More
              </Button>
            </CardActions> */}
              </Card>
            </Link>
          </Grid>
          
        </Grid>
      </Container>
    </>
  );
};

export default ServicesSection;
