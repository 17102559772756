import React from "react";
import { useUiStore } from "../../hooks/useUiStore";
import PropTypes from "prop-types";
import { styled } from "@mui/material/styles";
import Stack from "@mui/material/Stack";
import Stepper from "@mui/material/Stepper";
import Step from "@mui/material/Step";
import StepLabel from "@mui/material/StepLabel";
import { Container, Box, Typography, StepContent, Button } from "@mui/material";
import Check from "@mui/icons-material/Check";
import StepConnector, {
  stepConnectorClasses,
} from "@mui/material/StepConnector";
import MetalFloor from "../../assets/webp/metal-floor-pattern.webp";
import PhoneForwardedIcon from "@mui/icons-material/PhoneForwarded";
import QuestionAnswerIcon from "@mui/icons-material/QuestionAnswer";
import BuildIcon from "@mui/icons-material/Build";
import SpeedIcon from "@mui/icons-material/Speed";

// const QontoConnector = styled(StepConnector)(({ theme }) => ({
//   [`&.${stepConnectorClasses.alternativeLabel}`]: {
//     top: 10,
//     left: "calc(-50% + 16px)",
//     right: "calc(50% + 16px)",
//   },
//   [`&.${stepConnectorClasses.active}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: "#784af4",
//     },
//   },
//   [`&.${stepConnectorClasses.completed}`]: {
//     [`& .${stepConnectorClasses.line}`]: {
//       borderColor: "#784af4",
//     },
//   },
//   [`& .${stepConnectorClasses.line}`]: {
//     borderColor:
//       theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
//     borderTopWidth: 3,
//     borderRadius: 1,
//   },
// }));

const QontoStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  color: theme.palette.mode === "dark" ? theme.palette.grey[700] : "#eaeaf0",
  display: "flex",
  height: 22,
  alignItems: "center",
  ...(ownerState.active && {
    color: "#784af4",
  }),
  "& .QontoStepIcon-completedIcon": {
    color: "#784af4",
    zIndex: 1,
    fontSize: 18,
  },
  "& .QontoStepIcon-circle": {
    width: 8,
    height: 8,
    borderRadius: "50%",
    backgroundColor: "currentColor",
  },
}));

function QontoStepIcon(props) {
  const { active, completed, className } = props;

  return (
    <QontoStepIconRoot ownerState={{ active }} className={className}>
      {completed ? (
        <Check className="QontoStepIcon-completedIcon" />
      ) : (
        <div className="QontoStepIcon-circle" />
      )}
    </QontoStepIconRoot>
  );
}

QontoStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
};

const ColorlibConnector = styled(StepConnector)(({ theme }) => ({
  [`&.${stepConnectorClasses.alternativeLabel}`]: {
    top: 22,
  },
  [`&.${stepConnectorClasses.active}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #D69E48 0%, #FDD383 50%, #FDD383 100%)",
    },
  },
  [`&.${stepConnectorClasses.completed}`]: {
    [`& .${stepConnectorClasses.line}`]: {
      backgroundImage:
        "linear-gradient( 136deg, #D69E48 0%, #FDD383 50%, #FDD383 100%)",
    },
  },
  [`& .${stepConnectorClasses.line}`]: {
    height: 3,
    border: 0,
    backgroundColor:
      theme.palette.mode === "dark" ? theme.palette.grey[800] : "#eaeaf0",
    borderRadius: 1,
  },
}));

const ColorlibStepIconRoot = styled("div")(({ theme, ownerState }) => ({
  backgroundColor:
    theme.palette.mode === "dark" ? theme.palette.grey[700] : "#ccc",
  zIndex: 1,
  color: "black",
  width: 50,
  height: 50,
  display: "flex",
  borderRadius: "50%",
  justifyContent: "center",
  alignItems: "center",
  ...(ownerState.active && {
    backgroundImage:
      "linear-gradient( 136deg, #D69E48 0%, #FDD383 50%, #FDD383 100%)", //FDD383 claro D69E48 oscuro
    boxShadow: "0 4px 10px 0 rgba(0,0,0,.25)",
  }),
  ...(ownerState.completed && {
    backgroundImage:
      "linear-gradient( 136deg, #D69E48 0%, #FDD383 50%, #FDD383 100%)", //FDD383 claro D69E48 oscuro
  }),
}));

function ColorlibStepIcon(props) {
  const { active, completed, className } = props;

  const icons = {
    1: <PhoneForwardedIcon />,
    2: <QuestionAnswerIcon />,
    3: <BuildIcon />,
    4: <SpeedIcon />,
  };

  return (
    <ColorlibStepIconRoot
      ownerState={{ completed, completed, active }}
      className={className}
    >
      {icons[String(props.icon)]}
    </ColorlibStepIconRoot>
  );
}

ColorlibStepIcon.propTypes = {
  /**
   * Whether this step is active.
   * @default false
   */
  active: PropTypes.bool,
  className: PropTypes.string,
  /**
   * Mark the step as completed. Is passed to child components.
   * @default false
   */
  completed: PropTypes.bool,
  /**
   * The label displayed in the step icon.
   */
  icon: PropTypes.node,
};

const StepsSection = () => {
  const { lang } = useUiStore();

  const steps = [
    {
      title: "Call us 24/7",
      description: "Contact us at any time of day or night.",
    },
    {
      title: "Tell us your problem",
      description: "Provide us with details about your truck's issue.",
    },
    {
      title: "Repair On-Site",
      description: "Our mechanics will arrive at your location.",
    },
    {
      title: "We repair your truck",
      description: "We get you back on the road ✅",
    },
  ];

  const esp_steps = [
    {
      title: "Llámanos 24/7",
      description:
        "Contacta nuestra línea de emergencia dedicada en cualquier momento del día o la noche.",
    },
    {
      title: "Cuéntanos tu problema",
      description: "Proporciónanos detalles sobre el problema de tu camión.",
    },
    {
      title: "Reparación en el Lugar",
      description: "Nuestros mecánicos llegarán a tu ubicación.",
    },
    {
      title: "Nosotros reparamos tu camión",
      description: "Te devolvemos a la carretera lo antes posible.",
    },
  ];

  return (
    <Box
      alt="Mechanical Truck Services"
      title="Mechanical Truck Services"
      sx={{
        height: "auto",
        backgroundImage: `linear-gradient(to bottom, rgba(0, 0, 0, 0.7), rgba(0, 0, 0, 0.1)),
          url(${MetalFloor});`,
        // backgroundColor: "#17202A",
        border: 0,
        color: "white",
        paddingTop: 10,
        paddingBottom: 10,
        backgroundPosition: "center",
      }}
    >
      <Container>
        <Box textAlign={"center"} sx={{ mb: 5 }}>
          <Typography
            variant="h2"
            sx={{
              fontSize: 30,
              color: "#FDD383",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            {lang === "EN"
              ? "Roadside Emergency Line"
              : "Línea de Asistencia Vial"}
          </Typography>
          <Typography
            variant="h2"
            sx={{
              fontSize: 40,
              color: "#FDD383",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            {lang === "EN"
              ? "Roadside Emergency Line"
              : "Línea de Asistencia Vial"}
          </Typography>
          <Typography
            variant="body1"
            sx={{ fontWeight: "bold", mt: 2, fontSize: 20 }}
          >
            {lang === "EN"
              ? "Reaching our service is simple and straightforward. Follow these steps to enjoy the benefits:"
              : "Empezar con nuestro servicio es simple y directo. Sigue estos pasos para disfrutar de los beneficios:"}
          </Typography>
        </Box>
        <Stack>
          {/* Stepper desktop version */}
          <Box
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
              mt: 2,
            }}
          >
            <Stepper
              alternativeLabel
              activeStep={3}
              connector={<ColorlibConnector />}
            >
              {lang === "EN"
                ? steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: "white",
                            "&.Mui-active": {
                              color: index === 3 ? "#FDD383" : "white",
                              fontWeight: "bold",
                            },
                            "&.Mui-completed": {
                              color: index === 3 ? "#FDD383" : "white",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                          {"Step " + parseInt(index + 1)}
                          <br />
                          {label.title}
                        </Typography>
                        <Typography variant="caption" sx={{ mt: 2 }}>
                          {label.description}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))
                : esp_steps.map((label, index) => (
                    <Step key={index}>
                      <StepLabel
                        sx={{
                          "& .MuiStepLabel-label": {
                            color: "white",
                            "&.Mui-active": {
                              color: index === 3 ? "#FDD383" : "white",
                              fontWeight: "bold",
                            },
                            "&.Mui-completed": {
                              color: index === 3 ? "#FDD383" : "white",
                              fontWeight: "bold",
                            },
                          },
                        }}
                        StepIconComponent={ColorlibStepIcon}
                      >
                        <Typography sx={{ fontWeight: "bold", mb: 2 }}>
                          {"Paso " + parseInt(index + 1)}
                          <br />
                          {label.title}
                        </Typography>
                        <Typography variant="caption" sx={{ mt: 2 }}>
                          {label.description}
                        </Typography>
                      </StepLabel>
                    </Step>
                  ))}
            </Stepper>
          </Box>

          {/* Stepper tablet and mobile version */}
          <Box
            sx={{
              // pl: 1,
              display: {
                xs: "flex",
                sm: "flex",
                md: "none",
                lg: "none",
              },
              justifyContent: "center",
            }}
          >
            <Stepper orientation="vertical">
              {lang === "EN"
                ? steps.map((step, index) => (
                    <Step active key={step.title}>
                      <StepLabel
                        sx={{
                          "& .MuiStepIcon-root": {
                            color: "#FDD383",
                            fontWeight: "bold",
                            // "& .MuiStepIcon-text": {
                            //   color: "black",
                            // },
                          },
                          "& .MuiStepLabel-label": {
                            color: "white",
                            "&.Mui-completed": {
                              color: "#FDD383",
                            },
                            "&.Mui-active": {
                              color: "#FDD383",
                              fontWeight: "bold",
                            },
                          },
                        }}
                      >
                        {step.title}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))
                : esp_steps.map((step, index) => (
                    <Step active key={step.title}>
                      <StepLabel
                        sx={{
                          "& .MuiStepIcon-root": {
                            color: "#FDD383",
                            fontWeight: "bold",
                            // "& .MuiStepIcon-text": {
                            //   color: "black",
                            // },
                          },
                          "& .MuiStepLabel-label": {
                            color: "white",
                            "&.Mui-completed": {
                              color: "#FDD383",
                            },
                            "&.Mui-active": {
                              color: "#FDD383",
                              fontWeight: "bold",
                            },
                          },
                        }}
                      >
                        {step.title}
                      </StepLabel>
                      <StepContent>
                        <Typography>{step.description}</Typography>
                      </StepContent>
                    </Step>
                  ))}
            </Stepper>
          </Box>
        </Stack>
      </Container>
    </Box>
  );
};

export default StepsSection;
