import React from "react";
import {
  Container,
  Grid,
  Typography,
  Divider,
  Box,
  Button,
  Card,
  CardMedia,
  CardContent,
  CardActions,
} from "@mui/material";
import { useUiStore } from "../../hooks/useUiStore";
import { Link } from "react-router-dom";
import Banner3 from "../../assets/webp/banner3.webp";
import WrongPreventive from "../../assets/webp/wrong-preventive.webp";
import EquipTruck from "../../assets/webp/equip-truck.webp";
import TruckMaintenance from "../../assets/webp/truck-maintenance.webp";
import Tires from "../../assets/webp/tires.webp";
import TiresWallpaper from "../../assets/webp/tires-wallpaper.webp";
import Mechanics from "../../assets/webp/mechanics.webp";
import Mechanics2 from "../../assets/webp/mechanics2.webp";
import FullTruckLoad from "../../assets/webp/full-truck-load.webp";
import TruckNight from "../../assets/webp/truck-night.webp";
import CESLogo from "../../assets/webp/Trucking-Logo-08.webp";
import BestTruckRepairOrlando from "../../assets/webp/best-truck-repair-orlando.webp";
import RedTriangle from "../../assets/webp/red-triangle.webp";
import MetalFloor from "../../assets/webp/metal-floor-pattern.webp";
import TruckMaintenance2 from "../../assets/webp/truck-maintenance.webp";
import TruckDriver from "../../assets/webp/truck-driver.webp";
import FreightBrokerDispatcher from "../../assets/webp/freight-broker-vs-dispatcher.webp";
import ComputerDiagnostics from "../../assets/carousel/11.webp";
import HydraulicRepair from "../../assets/carousel/5.webp";
import RVServices from "../../assets/carousel/8.webp";
import JumpstartService from "../../assets/carousel/7.webp";
import BatteryReplacement from "../../assets/carousel/4.webp";
import WheelsRepair from "../../assets/carousel/25.webp";
import BearingsService from "../../assets/carousel/31.webp";
import OilChange from "../../assets/carousel/27.webp";
import Image28 from "../../assets/carousel/28.webp";
import Image33 from "../../assets/carousel/33.webp";
import Image5 from "../../assets/carousel/5.webp";
import Image6 from "../../assets/carousel/6.webp";
import Image7 from "../../assets/carousel/7.webp";
import { Helmet } from "react-helmet";
import services from "./services.json";

const ServicesSection = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Helmet>
        <title>Services | CES Mobile Truck & Trailer Repair 🚚</title>
        <meta
          name="description"
          content="Need truck repair service? Get immediate assistance for unforeseen events on the road. ⚡ Get to know our services!"
        />
      </Helmet>

      {/* SEO Titles */}
      <Typography variant="h1" sx={{ display: "none" }}>
        CES Mobile Truck & Trailer Repair Services
      </Typography>

      <Container
        sx={{
          paddingTop: 10,
          paddingBottom: 10,
        }}
      >
        <Grid container sx={{ flexGrow: 1 }} spacing={4}>
          {/* Title */}
          <Grid item xs={12} textAlign="center">
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                fontSize: 30,
                fontFamily: "Ethnocentric",
              }}
            >
              {lang === "EN"
                ? "24 Hour Roadside Assistance"
                : "Asistencia Vial 24 Horas"}
            </Typography>
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                  fontSize: 40,
                  fontFamily: "Ethnocentric",
                },
              }}
            >
              {lang === "EN"
                ? "24 Hour Roadside Assistance"
                : "Asistencia Vial 24 Horas"}
            </Typography>
            <Divider
              sx={{
                paddingTop: 0.5,
                marginTop: 2,
                marginBottom: 2,
                width: "50%",
                marginRight: "auto",
                marginLeft: "auto",
                background:
                  "linear-gradient(90deg, rgba(214,158,72,1) 0%, #FFCB68 50%, rgba(214,158,72,1) 100%)",
              }}
            />
            {/* Subtitle Desktop View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
                fontSize: 25,
                mb: 5,
              }}
            >
              {lang === "EN" ? (
                <>
                  The roadside assistance you need.{" "}
                  <b style={{ color: "#D69E48" }}>We are available 24/7</b>.
                </>
              ) : (
                <>
                  La asistencia vial que necesitas.{" "}
                  <b style={{ color: "#D69E48" }}>Estamos disponibles 24/7</b>.
                </>
              )}
            </Typography>

            {/* Subtitle Tablet and Phone View */}
            <Typography
              variant="body1"
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
                mb: 5,
              }}
            >
              {lang === "EN" ? (
                <>
                  The roadside assistance you need.{" "}
                  <b style={{ color: "#D69E48" }}>We are available 24/7</b>.
                </>
              ) : (
                <>
                  La asistencia vial que necesitas.{" "}
                  <b style={{ color: "#D69E48" }}>Estamos disponibles 24/7</b>.
                </>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12}>
            <Typography variant="body1">
              {lang === "EN" ? (
                <>
                  <b>
                    Do you need assistance with the breakdown of your truck?
                  </b>{" "}
                  At Cioffi Express Services we take care of it. You will
                  receive immediate response, help will be on its way to your
                  location, wherever you are.{" "}
                  <b>We focus on providing mobile repair services</b> for all
                  types of trucks.
                </>
              ) : (
                <>
                  <b>¿Necesitas asistencia con la avería de tu camión?</b> En
                  Cioffi Express Services nos encargamos de resolverlo.
                  Recibirás respuesta inmediata, la ayuda irá en camino hacia el
                  lugar donde te encuentres.{" "}
                  <b>Nos enfocamos en brindar servicios de reparación móvil</b>{" "}
                  para todo tipo de camiones.
                </>
              )}
            </Typography>
          </Grid>

          {/* Cards de cada artículo */}
          {/* Versión mobile */}
          {services.services.map((service, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: {
                  xs: "block",
                  sm: "block",
                  md: "none",
                  lg: "none",
                },
              }}
            >
              <Link
                to={"/services/" + service.url}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    height="100"
                    alt={lang === "EN" ? service.eng_title : service.esp_title}
                    image={
                      index === 0
                        ? TruckNight
                        : index === 1
                        ? Mechanics2
                        : index === 2
                        ? FullTruckLoad
                        : index === 3
                        ? Banner3
                        : index === 4
                        ? Tires
                        : index === 5
                        ? Mechanics
                        : index === 6
                        ? TiresWallpaper
                        : index === 7
                        ? BestTruckRepairOrlando
                        : index === 8
                        ? RedTriangle
                        : index === 9
                        ? MetalFloor
                        : index === 10
                        ? TruckMaintenance2
                        : index === 11
                        ? TruckDriver
                        : index === 12
                        ? FreightBrokerDispatcher
                        : index === 13
                        ? ComputerDiagnostics
                        : index === 14
                        ? HydraulicRepair
                        : index === 15
                        ? RVServices
                        : index === 16
                        ? JumpstartService
                        : index === 17
                        ? BatteryReplacement
                        : index === 18
                        ? WheelsRepair
                        : index === 19
                        ? BearingsService
                        : index === 20
                        ? OilChange
                        : index === 21
                        ? Image7
                        : index === 22
                        ? Image5
                        : index === 23
                        ? Image6
                        : index === 24
                        ? OilChange
                        : index === 25
                        ? Image33
                        : index === 26
                        ? Tires
                        : Tires
                    }
                  />

                  <CardContent sx={{ height: 60 }}>
                    <Typography
                      gutterBottom
                      variant="h2"
                      sx={{
                        fontSize:
                          service.eng_title.length > 35 ||
                          service.esp_title.length > 35
                            ? 11
                            : 12,
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        textAlign: "center",
                      }}
                      component="div"
                    >
                      {lang === "EN" ? service.eng_title : service.esp_title}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}

          {/* Cards de cada artículo */}
          {/* Versión desktop */}
          {services.services.map((service, index) => (
            <Grid
              key={index}
              item
              xs={6}
              sm={6}
              md={6}
              lg={4}
              xl={4}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              <Link
                to={"/services/" + service.url}
                style={{
                  textDecoration: "none",
                  color: "black",
                }}
              >
                <Card>
                  <CardMedia
                    component="img"
                    alt={lang === "EN" ? service.eng_title : service.esp_title}
                    height="200"
                    image={
                      index === 0
                        ? TruckNight
                        : index === 1
                        ? Mechanics2
                        : index === 2
                        ? FullTruckLoad
                        : index === 3
                        ? Banner3
                        : index === 4
                        ? Tires
                        : index === 5
                        ? Mechanics
                        : index === 6
                        ? TiresWallpaper
                        : index === 7
                        ? BestTruckRepairOrlando
                        : index === 8
                        ? RedTriangle
                        : index === 9
                        ? MetalFloor
                        : index === 10
                        ? TruckMaintenance2
                        : index === 11
                        ? TruckDriver
                        : index === 12
                        ? FreightBrokerDispatcher
                        : index === 13
                        ? ComputerDiagnostics
                        : index === 14
                        ? HydraulicRepair
                        : index === 15
                        ? RVServices
                        : index === 16
                        ? JumpstartService
                        : index === 17
                        ? BatteryReplacement
                        : index === 18
                        ? WheelsRepair
                        : index === 19
                        ? BearingsService
                        : index === 20
                        ? OilChange
                        : index === 21
                        ? Image7
                        : index === 22
                        ? Image5
                        : index === 23
                        ? Image6
                        : index === 24
                        ? Image28
                        : index === 25
                        ? Image33
                        : index === 26
                        ? Tires
                        : Tires
                    }
                  />

                  <CardContent>
                    <Typography
                      gutterBottom
                      variant="h2"
                      sx={{
                        fontSize: 22,
                        mt: 2,
                        fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                        textAlign: "center",
                      }}
                      component="div"
                    >
                      {lang === "EN" ? service.eng_title : service.esp_title}
                    </Typography>
                  </CardContent>
                </Card>
              </Link>
            </Grid>
          ))}
        </Grid>

        <Grid container sx={{ flexGrow: 1, mt:5 }} spacing={5}>
          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              textAlign: "center",
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Wrong Preventive"
              title="Wrong Preventive"
              src={WrongPreventive}
            />
          </Grid>
          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: 30,
              }}
            >
              {lang === "EN" ? <>What do you need?</> : <>¿Qué necesitas?</>}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  Cioffi Express Services' specialized mechanics have vehicles
                  equipped for road repairs. Have you run out of spare tires?
                  Choose whether you need a used or new one and{" "}
                  <b>help will arrive in no time</b>.
                </>
              ) : (
                <>
                  Los mecánicos especialistas de Cioffi Express Services
                  disponen de vehículos equipados para la reparación vial. ¿Te
                  has quedado sin llantas de repuesto? Elige si requieres una
                  usada o nueva y <b>la ayuda llegará en poco tiempo</b>.
                </>
              )}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  We do the complete installation so you can get back on the
                  road in a flash. But that's not all,{" "}
                  <b>we invite you to learn more about truck repair services</b>
                  . We adapt to your needs.
                </>
              ) : (
                <>
                  Hacemos la instalación completa para que en breve retomes la
                  carretera. Pero eso no es todo,{" "}
                  <b>
                    te invitamos a conocer más sobre los servicios de reparación
                    de camiones
                  </b>
                  . Nos adaptamos a tus necesidades.
                </>
              )}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  <b>Having an ally</b> to solve accidents is vital to protect
                  the integrity of your vehicle and ensure optimal performance
                  for the load to reach its destination. You can count on our
                  team of professionals with extensive experience.{" "}
                </>
              ) : (
                <>
                  <b>Tener un aliado</b> que solvente los accidentes es vital
                  para proteger la integridad de tu vehículo y asegurar el
                  óptimo rendimiento para que la carga llegue a su destino.
                  Cuentas con todo nuestro equipo de profesionales con amplia
                  experiencia.
                </>
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              textAlign: "center",
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Wrong Preventive"
              title="Wrong Preventive"
              src={WrongPreventive}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: 30,
              }}
            >
              {lang === "EN" ? (
                <>Mechanical Assistance</>
              ) : (
                <>Asistencia Mecánica</>
              )}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
              {lang === "EN" ? (
                <>
                  <b>
                    If your truck is having breakdowns, leave it in the hands of
                    our experts
                  </b>
                  , they will repair it for you.{" "}
                  <a target="_blank" href="tel:4076745769">
                    Call us
                  </a>{" "}
                  for a personalized quote. We are available 24 hours a day,
                  every day. Our goal is to enhance the performance of your
                  truck. We are experts with the knowledge required to repair
                  and overhaul a diesel truck. Among the mechanical repair
                  services we offer:
                </>
              ) : (
                <>
                  <b>
                    Si tu camión está presentando averías déjalo en manos de
                    nuestros expertos
                  </b>
                  , lo repararán por ti.{" "}
                  <a target="_blank" href="tel:4076745769">
                    Llámanos
                  </a>{" "}
                  para una cotización personalizada. Tenemos disponibilidad
                  todos los días las 24 horas. Nuestro objetivo es potenciar el
                  rendimiento de tu camión. Somos expertos con los conocimientos
                  requeridos para reparar y revisar un camión diesel. Entre los
                  servicios de reparación mecánica ofrecemos:
                </>
              )}
            </Typography>

            {lang === "EN" ? (
              <>
                <li>
                  <b>Diesel engine repair</b>
                </li>
                <li>
                  <b>Oil change</b>
                </li>
                <li>
                  <b>Truck suspension repair</b>
                </li>
                <li>
                  <b>Hydraulic system repair services</b>
                </li>
                <li>
                  <b>Rest services</b>
                </li>
              </>
            ) : (
              <>
                <li>
                  <b>Reparación de motor diesel</b>
                </li>
                <li>
                  <b>Cambio de aceite</b>
                </li>
                <li>
                  <b>Arreglo de la suspensión del camión</b>
                </li>
                <li>
                  <b>Servicios de reparación del sistema hidráulico</b>
                </li>
                <li>
                  <b>Servicios de descanso</b>
                </li>
              </>
            )}

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  When contacting our operators, indicate the type of assistance
                  you need if you've had an accident on the road. The support
                  your truck needs is just a phone call away.
                </>
              ) : (
                <>
                  Al comunicarte con nuestros operadores, indica el tipo de
                  asistencia que necesitas si te has accidentado en la
                  carretera. El soporte que tu camión necesita está a la
                  distancia de una llamada.
                </>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Equip Truck"
              title="Equip Truck"
              src={EquipTruck}
            />
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: {
                xs: "none",
                sm: "none",
                md: "block",
                lg: "block",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Truck Maintenance"
              title="Truck Maintenance"
              src={TruckMaintenance}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: 30,
              }}
            >
              {lang === "EN" ? (
                <>Electrical Service</>
              ) : (
                <>Servicio Eléctrico</>
              )}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
              {lang === "EN" ? (
                <>
                  Because we know that a good electrical service provides a
                  satisfactory performance of your truck, we offer a wide range
                  of options that cover all the needs you may have:
                </>
              ) : (
                <>
                  Porque sabemos que un buen servicio eléctrico provee un
                  satisfactorio desempeño de tu camión, ofrecemos una amplia
                  gama de opciones que cubren todas las necesidades que puedas
                  tener:
                </>
              )}
            </Typography>

            {lang === "EN" ? (
              <>
                <li>
                  <b>We replace the vehicle's battery.</b>
                </li>
                <li>
                  <b>
                    If the battery needs maintenance, we repair it, extending
                    its life so you don't have to buy a new one.
                  </b>
                </li>
                <li>
                  <b>We provide detailed electrical service.</b>
                </li>
                <li>
                  <b>
                    We offer all types of electrical repairs with the assistance
                    of one of our qualified operators.
                  </b>
                </li>
                <li>
                  <b>We do computer diagnostics.</b>
                </li>
                <li>
                  <b>We have the best electrical maintenance programs.</b>
                </li>
              </>
            ) : (
              <>
                <li>
                  <b>Cambiamos la batería del vehículo.</b>
                </li>
                <li>
                  <b>
                    Si la batería necesita mantenimiento, la reparamos alargando
                    su vida útil para que no sea necesario comprar una nueva.
                  </b>
                </li>
                <li>
                  <b>Brindamos servicio eléctrico detallado.</b>
                </li>
                <li>
                  <b>
                    Ofrecemos todo tipo de reparación eléctrica con la
                    asistencia de uno de nuestros operadores calificados.
                  </b>
                </li>
                <li>
                  <b>Hacemos diagnóstico por computadora.</b>
                </li>
                <li>
                  <b>
                    Contamos con los mejores programas de mantenimiento
                    eléctrico.
                  </b>
                </li>
              </>
            )}

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  <b>Get the help you need on the road</b>. No matter if it's a
                  complicated job or if you've opted for a battery replacement,
                  <b>our mechanics will do all the work</b>.{" "}
                  <a target="_blank" href="tel:4076745769">
                    Contact us
                  </a>
                  . The maintenance of the electrical system helps you prevent
                  inconveniences in the future: improve the lighting system,
                  navigation and the ABS system.
                </>
              ) : (
                <>
                  Al comunicarte con nuestros operadores, indica el tipo de
                  asistencia que necesitas si te has accidentado en la
                  carretera. El soporte que tu camión necesita está a la
                  distancia de una llamada.
                </>
              )}
            </Typography>
          </Grid>

          <Grid
            item
            xs={12}
            sm={12}
            md={6}
            lg={6}
            xl={6}
            sx={{
              display: {
                xs: "block",
                sm: "block",
                md: "none",
                lg: "none",
              },
            }}
          >
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Truck Maintenance"
              title="Truck Maintenance"
              src={TruckMaintenance}
            />
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: 30,
              }}
            >
              {lang === "EN" ? <>Tire Change</> : <>Cambio de Llantas</>}
            </Typography>

            <Typography variant="body1" sx={{ mt: 2, mb: 2 }}>
              {lang === "EN" ? (
                <>
                  <b>
                    The condition of the tires is directly related to the
                    prevention of accidents on the road
                  </b>
                  . The better the quality of the tires, the more certainty you
                  will have of a smooth trip. However, receiving road assistance
                  in case of problems of this type is essential to avoid delays
                  on the road. We have several options for repair or attention
                  when you are on the road and have had a tire-related mishap:
                </>
              ) : (
                <>
                  <b>
                    El estado de los neumáticos está directamente relacionado
                    con la prevención de accidentes en la vía
                  </b>
                  . Mientras mayor sea la calidad de las llantas, más seguridad
                  tendrás de un viaje sin inconvenientes. Ahora bien, recibir
                  asistencia vial ante problemas de este tipo es primordial para
                  evitar atrasos en la ruta. Traemos varias opciones de
                  reparación o atención cuando estás en la carretera y has
                  tenido un percance relacionado con las llantas:
                </>
              )}
            </Typography>

            {lang === "EN" ? (
              <>
                <li>
                  <b>We have new truck tires available.</b>
                </li>
                <li>
                  <b>If you prefer, we also have used tires.</b>
                </li>
                <li>
                  <b>We make the change in just minutes.</b>
                </li>
                <li>
                  <b>We provide you with tire repair services.</b>
                </li>
              </>
            ) : (
              <>
                <li>
                  <b>Disponemos de llantas nuevas para el camión.</b>
                </li>
                <li>
                  <b>
                    Si lo prefieres, también contamos con neumáticos usados.
                  </b>
                </li>
                <li>
                  <b>Hacemos el cambio en solo minutos.</b>
                </li>
                <li>
                  <b>Te brindamos servicios de reparación de llantas.</b>
                </li>
              </>
            )}

            <Typography variant="body1" sx={{ mt: 2 }}>
              {lang === "EN" ? (
                <>
                  <b>Get the help you need on the road</b>. No matter if it's a
                  complicated job or if you've opted for a battery replacement,
                  <b>our mechanics will do all the work</b>.{" "}
                  <a target="_blank" href="tel:4076745769">
                    Contact us
                  </a>
                  . The maintenance of the electrical system helps you prevent
                  inconveniences in the future: improve the lighting system,
                  navigation and the ABS system.
                </>
              ) : (
                <>
                  Al comunicarte con nuestros operadores, indica el tipo de
                  asistencia que necesitas si te has accidentado en la
                  carretera. El soporte que tu camión necesita está a la
                  distancia de una llamada.
                </>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={6} lg={6} xl={6}>
            <Box
              component="img"
              sx={{
                width: 1,
                p: 2,
              }}
              alt="Tires"
              title="Tires"
              src={Tires}
            />
          </Grid>

          <Grid item xs={12}>
            <Typography
              variant="h2"
              sx={{
                textAlign: "center",
                fontFamily: '"Roboto","Helvetica","Arial",sans-serif',
                fontSize: 30,
              }}
            >
              {lang === "EN" ? (
                <>Your Best Choice for Mobile Truck Repair</>
              ) : (
                <>Tu Mejor Opción de Reparación Móvil para Camiones</>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
            <Box sx={{ textAlign: "center" }}>
              <Box
                component="img"
                sx={{
                  width: 0.6,
                }}
                alt="CES LOGO"
                title="CES LOGO"
                src={CESLogo}
              />
            </Box>
          </Grid>

          <Grid item xs={12} sm={12} md={8} lg={8} xl={8}>
            <Typography variant="body1">
              {lang === "EN" ? (
                <>
                  Cioffi Express Services offers you Super Duty vans that go all
                  over Orlando (FL) up to 100 miles around. Are you looking for
                  <b> immediate roadside assistance</b>? Then you've come to the
                  right place.
                  <br />
                  <br />
                  If you require a pre-quote, don't worry, it's free! On top of
                  that, our prices are competitive. We make sure that you
                  receive competent service at excellent prices.
                  <br />
                  <br />
                  Need an urgent repair? You don't have to wait any longer!
                  <b> Contact the team at Cioffi Express Services</b> and get
                  the service you're looking for. We make sure you get a
                  reliable repair with guaranteed quality.
                </>
              ) : (
                <>
                  Cioffi Express Services ofrece a tu disposición camionetas
                  Super Duty que recorren todo Orlando (FL) hasta 100 millas
                  alrededor. ¿Estás buscando{" "}
                  <b>asistencia inmediata en la carretera</b>? Entonces has
                  llegado al lugar indicado.
                  <br />
                  <br />
                  Si requieres hacer una cotización previa, no te preocupes ¡es
                  gratis! Además de eso, nuestros precios son competitivos.
                  Velamos porque recibas un servicio competente con excelentes
                  precios.
                  <br />
                  <br />
                  ¿Necesitas una reparación urgente? ¡No tienes que esperar más!
                  <b>Contacta al equipo de Cioffi Express Services</b> y recibe
                  el servicio que buscas. Nos aseguramos de que obtengas una
                  reparación confiable con calidad garantizada.
                </>
              )}
            </Typography>
          </Grid>

          <Grid item xs={12} textAlign="center">
            <Link
              to={"/"}
              style={{
                textDecoration: "none",
                color: "black",
              }}
            >
              <Button
                variant="outlined"
                sx={{
                  backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
                  color: "black",
                  fontWeight: "bold",
                  border: "1px solid #FDD383",
                  "&:hover": {
                    color: "black",
                    backgroundColor: "rgb(253,211,131,0.7)",
                    border: "1px solid #FDD383",
                  },
                }}
              >
                {lang === "EN" ? (
                  <>Go to home</>
                ) : (
                  <>Volver a página principal</>
                )}
              </Button>
            </Link>
          </Grid>
        </Grid>
      </Container>
    </>
  );
};

export default ServicesSection;
