import React from "react";
import { Grid, Typography, Button } from "@mui/material";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
const ArticleFooter = () => {
  const { lang } = useUiStore();
  return (
    <>
      <Grid item xs={12} className="mt-5">
        <hr />
        <a
          href="tel:4076745769"
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Typography variant="overline">
            {lang === "EN" ? (
              <b>
                ⚠️ If you are in Orlando, Tampa, Atlanta, Savannah or
                Jacksonville, and your truck stopped due to a mechanical or
                electrical breakdown, we can help you.
                <b style={{ color: "#D69E48" }}>
                  {" "}
                  Call Cioffi Express Services
                </b>{" "}
                at (407) 674-5769 and our technicians will come to the rescue.{" "}
                <br />
                <br />
                At Cioffi Express we provide{" "}
                <b style={{ color: "#D69E48" }}>
                  mobile repair service to trucks and trailers in Orlando,
                  Tampa, Atlanta, Savannah or Jacksonville
                </b>
                .
              </b>
            ) : (
              <b>
                ⚠️ Si estás en Orlando, Tampa, Atlanta, Savannah o Jacksonville,
                y tu camión se detuvo por una avería mecánica o eléctrica, te
                podemos ayudar.{" "}
                <b style={{ color: "#D69E48" }}>
                  Llama al servicio de atención al cliente de Cioffi Express
                  Services
                </b>{" "}
                al (407) 674-5769 y nuestros mecánicos irán al rescate. <br />
                <br />
                En Cioffi Express brindamos servicio móvil de{" "}
                <b style={{ color: "#D69E48" }}>
                  reparación a camiones y remolques en Orlando, Tampa, Atlanta,
                  Savannah y Jacksonville
                </b>
              </b>
            )}
          </Typography>
        </a>
        <hr />
      </Grid>

      <Grid item xs={12} textAlign="center" className="mt-5">
        <Link
          to={"/"}
          style={{
            textDecoration: "none",
            color: "black",
          }}
        >
          <Button
            variant="outlined"
            sx={{
              backgroundColor: "#FDD383", //FDD383 claro D69E48 oscuro
              color: "black",
              fontWeight: "bold",
              border: "1px solid #FDD383",
              "&:hover": {
                color: "black",
                backgroundColor: "rgb(253,211,131,0.7)",
                border: "1px solid #FDD383",
              },
            }}
          >
            {lang === "EN" ? (
              <>&#8592;&nbsp;&nbsp; Back to home</>
            ) : (
              <>&#8592;&nbsp;&nbsp; Volver a la página principañ</>
            )}
          </Button>
        </Link>
      </Grid>
    </>
  );
};

export default ArticleFooter;
