import React from "react";
import { Grid, Box, Typography, Container, Button } from "@mui/material";
import { Link } from "react-router-dom";
import CallButtons from "../CallButtons";
import Visa from "../../assets/webp/visa.webp";
import Mastercard from "../../assets/webp/mastercard.webp";
import Amex from "../../assets/webp/amex.webp";
import Discover from "../../assets/webp/discover.webp";
import Card from "../../assets/webp/card.webp";
import Cheque from "../../assets/webp/cheque.webp";
import Dollar from "../../assets/webp/dollar.webp";
import CESLogo from "../../assets/webp/Trucking-Logo-05.webp";
import { useUiStore } from "../../hooks/useUiStore";
import BoltTwoToneIcon from "@mui/icons-material/BoltTwoTone";
import PinDropIcon from "@mui/icons-material/PinDrop";
import ForwardToInboxIcon from "@mui/icons-material/ForwardToInbox";
import PermPhoneMsgIcon from "@mui/icons-material/PermPhoneMsg";
import FacebookIcon from "@mui/icons-material/Facebook";
import InstagramIcon from "@mui/icons-material/Instagram";

const Footer = () => {
  const { lang } = useUiStore();
  const year = new Date().getFullYear();
  return (
    <>
      {/* SEO TITLES */}
      {/* <Typography variant="h2" sx={{ display: "none" }}>
        24 Hour Road Assistance
      </Typography> */}

      <Box
        sx={{
          height: "auto",
          backgroundColor: "black",
          color: "white",
          paddingTop: 10,
          paddingBottom: 2,
        }}
      >
        <Container>
          <Grid
            container
            sx={{
              flexGrow: 1,
            }}
          >
            {/* <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={3}
              xl={3}
              sx={{
                textAlign: "center",
                mb: 5,
                display: {
                  xs: "none",
                  sm: "none",
                  md: "block",
                  lg: "block",
                },
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 200,
                  mt: -5,
                }}
                alt="CES LOGO"
                title="CES LOGO"
                src={CESLogo}
              />
            </Grid> */}

            {/* Info Section */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{
                textAlign: "center",
                display: {
                  xs: "block",
                  sm: "block",
                  md: "block",
                  lg: "none",
                },
                mb: 5,
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 200,
                  mt: -5,
                }}
                alt="CES LOGO"
                title="CES LOGO"
                src={CESLogo}
              />
              <Typography
                variant="body1"
                sx={{
                  color: "#FFCB68",
                  fontSize: 20,
                  fontFamily: "Ethnocentric",
                  mb: 3,
                }}
              >
                {lang === "EN" ? (
                  <>Leave it in our hands</>
                ) : (
                  <>Cuenta con nosotros</>
                )}
              </Typography>

              <Typography variant="body1">
                {lang === "EN" ? (
                  <>We can provide a second opinion on estimates.</>
                ) : (
                  <>Te ofrecemos una segunda opinión de presupuesto.</>
                )}
              </Typography>
              <Typography variant="body1">
                {lang === "EN" ? (
                  <>Call us today for a free quote!</>
                ) : (
                  <>¡Llámenos hoy para una cotización gratis!</>
                )}
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFCB68" }}>
                <BoltTwoToneIcon />
                {lang === "EN" ? (
                  <>24 Hour Roadside Assistance</>
                ) : (
                  <>Asistencia Vial 24 Horas</>
                )}
              </Typography>
              <Link
                to="/location"
                sx={{ textDecoration: "none", color: "#FFCB68" }}
              >
                <Typography variant="body1" sx={{ color: "#FFCB68" }}>
                  <PinDropIcon />
                  {lang === "EN" ? (
                    <>Orlando, Tampa & Jacksonville - Florida</>
                  ) : (
                    <>Orlando, Tampa y Jacksonville - Florida</>
                  )}
                </Typography>
              </Link>
              <Link
                to="/location"
                sx={{ textDecoration: "none", color: "#FFCB68" }}
              >
                <Typography variant="body1" sx={{ color: "#FFCB68" }}>
                  <PinDropIcon />
                  <>Atlanta, Savannah - Georgia</>
                </Typography>
              </Link>
            </Grid>
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{
                display: {
                  xs: "none",
                  sm: "none",
                  md: "none",
                  lg: "block",
                },
                textAlign: "center",
                mb: 5,
              }}
            >
              <Box
                component="img"
                sx={{
                  height: 200,
                  mt: -5,
                }}
                alt="CES LOGO"
                title="CES LOGO"
                src={CESLogo}
              />
              {/* <Typography
                variant="body1"
                sx={{
                  color: "#FFCB68",
                  fontSize: 20,
                  fontFamily: "Ethnocentric",
                  mb: 3,
                  textAlign: "center",
                }}
              >
                {lang === "EN" ? (
                  <>Leave it in our hands</>
                ) : (
                  <>Cuenta con nosotros</>
                )}
              </Typography> */}

              <Typography variant="body1" sx={{ mt: 1 }}>
                {lang === "EN" ? (
                  <>We can provide a second opinion on estimates.</>
                ) : (
                  <>Te ofrecemos una segunda opinión de presupuesto.</>
                )}
              </Typography>
              <Typography variant="body1" sx={{ mt: 1 }}>
                {lang === "EN" ? (
                  <>Call us today for a free quote!</>
                ) : (
                  <>¡Llámenos hoy para una cotización gratis!</>
                )}
              </Typography>
              <Typography variant="body1" sx={{ color: "#FFCB68", mt: 1 }}>
                <BoltTwoToneIcon />
                {lang === "EN" ? (
                  <>24 Hour Roadside Assistance</>
                ) : (
                  <>Asistencia Vial 24 Horas</>
                )}
              </Typography>
              <Link
                to="/location"
                sx={{ textDecoration: "none", color: "#FFCB68" }}
              >
                <Typography variant="body1" sx={{ color: "#FFCB68", mt: 1 }}>
                  <PinDropIcon />
                  {lang === "EN" ? (
                    <>
                      Orlando, Tampa & Jacksonville - Florida
                      <br />
                      Atlanta, Savannah - Georgia
                    </>
                  ) : (
                    <>
                      Orlando, Tampa y Jacksonville - Florida
                      <br />
                      Atlanta, Savannah - Georgia
                    </>
                  )}
                </Typography>
              </Link>
            </Grid>

            {/* Menu links */}
            <Grid
              item
              xs={12}
              sm={12}
              md={4}
              lg={4}
              xl={4}
              sx={{ textAlign: "center" }}
            >
              <Typography
                variant="body1"
                sx={{
                  color: "#FFCB68",
                  fontSize: 20,
                  fontFamily: "Ethnocentric",
                  mb: 2,
                }}
              >
                MENU
              </Typography>
              {/* <Typography variant="body1" sx={{ mb: 2 }}>
                Go to the following pages:
              </Typography> */}
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Home
                </Link>
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/gallery"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Gallery
                </Link>
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/about-us"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  About Us
                </Link>
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/location"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Location
                </Link>
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/blog"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Blog
                </Link>
              </Box>
              <Box sx={{ mb: 0.5 }}>
                <Link
                  to="/contact"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Instant Help
                </Link>
              </Box>
              <Box sx={{ mb: 5 }}>
                <Link
                  to="/services"
                  style={{
                    textDecoration: "none",
                    color: "white",
                    fontWeight: "bold",
                    fontSize: 20,
                  }}
                >
                  Services
                </Link>
              </Box>
            </Grid>

            {/* Social Network and contact emails */}
            <Grid item xs={12} sm={12} md={4} lg={4} xl={4}>
              <Typography
                variant="body1"
                sx={{
                  color: "#FFCB68",
                  fontSize: 20,
                  fontFamily: "Ethnocentric",
                  mb: 2,
                  textAlign: "center",
                }}
              >
                CONTACT
              </Typography>
              {/* Desktop version*/}
              <Box
                sx={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  borderRadius: 1,
                  ml: 10,
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                }}
              >
                <Box>
                  <Button
                    rel="noreferrer"
                    href="tel:4076745769"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <PermPhoneMsgIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      407-674-5769
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/people/Cioffi-Express-Services-Mobile-Truck-Repair/100089914560684/?mibextid=LQQJ4d"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <FacebookIcon sx={{ fontSize: 20, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      Facebook Profile
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/cestruckrepair"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <InstagramIcon sx={{ fontSize: 20, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      @cestruckrepair
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    href="mailto:support@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      support@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    href="mailto:info@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      info@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>
              </Box>

              {/* Mobile version*/}
              <Box
                sx={{
                  backgroundColor: "rgba(0,0,0,0.5)",
                  color: "white",
                  borderRadius: 1,
                  ml: 4,
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                }}
              >
                <Box>
                  <Button
                    rel="noreferrer"
                    href="tel:4076745769"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <PermPhoneMsgIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      407-674-5769
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.facebook.com/people/Cioffi-Express-Services-Mobile-Truck-Repair/100089914560684/?mibextid=LQQJ4d"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <FacebookIcon sx={{ fontSize: 20, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      Facebook Profile
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    target="_blank"
                    href="https://www.instagram.com/cestruckrepair"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <InstagramIcon sx={{ fontSize: 20, color: "#FFCB68" }} />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      @cestruckrepair
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    href="mailto:support@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      support@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>

                <Box>
                  <Button
                    rel="noreferrer"
                    href="mailto:info@cestruckrepair.com"
                    style={{
                      textDecoration: "none",
                      color: "white",
                      textTransform: "none",
                    }}
                  >
                    <Typography display="inline">
                      <ForwardToInboxIcon
                        sx={{ fontSize: 20, color: "#FFCB68" }}
                      />
                    </Typography>{" "}
                    <Typography
                      variant="body1"
                      display="inline"
                      sx={{ ml: 2, fontWeight: "bold" }}
                    >
                      info@cestruckrepair.com
                    </Typography>
                  </Button>
                </Box>
              </Box>
            </Grid>

            <Grid
              item
              xs={12}
              sm={12}
              md={12}
              lg={12}
              xl={12}
              sx={{ textAlign: "center", mt: 5 }}
            >
              <Box xs={{ marginBottom: 2 }}>
                <Box
                  component="img"
                  alt="visa"
                  title="visa"
                  sx={{
                    height: 30,
                    paddingRight: 1,
                  }}
                  src={Visa}
                />
                <Box
                  component="img"
                  alt="mastercard"
                  title="mastercard"
                  sx={{
                    height: 35,
                    paddingRight: 1,
                  }}
                  src={Mastercard}
                />
                <Box
                  component="img"
                  alt="amex"
                  title="amex"
                  sx={{
                    height: 30,
                    paddingRight: 1,
                  }}
                  src={Amex}
                />
                <Box
                  component="img"
                  alt="discover"
                  title="discover"
                  sx={{
                    height: 20,
                    paddingRight: 1,
                  }}
                  src={Discover}
                />
                <Box
                  component="img"
                  alt="card"
                  title="card"
                  sx={{
                    height: 35,
                    paddingRight: 1,
                  }}
                  src={Card}
                />
                <Box
                  component="img"
                  alt="cheque"
                  title="cheque"
                  sx={{
                    height: 40,
                    paddingRight: 1,
                  }}
                  src={Cheque}
                />
                <Box
                  component="img"
                  alt="dollar"
                  title="dollar"
                  sx={{
                    height: 40,
                    paddingRight: 1,
                  }}
                  src={Dollar}
                />
              </Box>
            </Grid>

            <Grid item xs={12} sx={{ paddingTop: 1 }}>
              <Container sx={{ textAlign: "center" }}>
                {lang === "EN" ? (
                  <p>
                    Copyright &copy; {year} Cioffi Express Services LLC. <br />{" "}
                    All Rights Reserved.
                  </p>
                ) : (
                  <p>
                    Copyright &copy; {year} Cioffi Express Services LLC. <br />{" "}
                    Todos los derechos reservados.
                  </p>
                )}
              </Container>
            </Grid>
          </Grid>
        </Container>
      </Box>
    </>
  );
};

export default Footer;
