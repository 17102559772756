import React, { useState, useEffect } from "react";
import AppBar from "@mui/material/AppBar";
import {
  Box,
  Button,
  Toolbar,
  Typography,
  SpeedDial,
  Menu,
  MenuItem,
} from "@mui/material";
import { Link } from "react-router-dom";
import { useUiStore } from "../../hooks/useUiStore";
import SpanishLang from "../../assets/img/spain.png";
import EnglishLang from "../../assets/img/us.png";

//Images
import CESLogo from "../../assets/webp/Trucking-Logo-03.webp";

//components
import Sidebar from "../Sidebar";

//Icons
// import WhatsAppIcon from "@mui/icons-material/WhatsApp";
import TextsmsIcon from "@mui/icons-material/Textsms";
import PhoneEnabledIcon from "@mui/icons-material/PhoneEnabled";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";

const Navbar = () => {
  const { lang, changeLang, changeBlogPage } = useUiStore();
  // const [open, setOpen] = useState(false);
  const handleOpen = () => {
    // window.href("tel:4076745769");
    document.location.href = "tel:4076745769";
    // setOpen(true);
  };

  const [anchorEl, setAnchorEl] = useState(null);
  const [openMenu, setOpenMenu] = useState(false);

  const handleClick = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handleClose = () => {
    setAnchorEl(null);
  };

  const resetBlogPage = (itemName) => {
    if (itemName === "Blog") {
      changeBlogPage(1);
    }
  };

  const navItems = [
    { name: "About Us", href: "/about-us" },
    { name: "Blog", href: "/blog" },
    { name: "Services", href: "/services" },
    { name: "Gallery", href: "/gallery" },
    { name: "Location", href: "/locations" },
    { name: "FAQS", href: "/faqs" },
    { name: "Instant Help", href: "/contact" },
  ];

  const navItemsSpanish = [
    { name: "Sobre Nosotros", href: "/about-us" },
    { name: "Blog", href: "/blog" },
    { name: "Servicios", href: "/services" },
    { name: "Galería", href: "/gallery" },
    { name: "Ubicación", href: "/locations" },
    { name: "FAQS", href: "/faqs" },
    { name: "Ayuda Inmediata", href: "/contact" },
  ];

  const submenuItems = [
    { name: "Orlando, Fl", href: "/orlando" },
    { name: "Jacksonville, Fl", href: "/jacksonville" },
    { name: "Tampa, Fl", href: "/tampa" },
    // { name: "Ocala, Fl", href: "/ocala" },
    // { name: "Lakeland, Fl", href: "/lakeland" },
    // { name: "Kissimmee, Fl", href: "/kissimmee" },
    // { name: "Melbourne, Fl", href: "/melbourne" },
    // { name: "Daytona, Fl", href: "/daytona" },
    { name: "Atlanta, GA", href: "/atlanta" },
    { name: "Savannah, GA", href: "/savannah" }
  ];

  const actions = [
    { icon: <TextsmsIcon />, name: "Chat", href: "sms:+14076745769" },
    { icon: <PhoneEnabledIcon />, name: "Call", href: "tel:4076745769" },
  ];

  return (
    <>
      {lang === "EN" ? (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ height: 100, background: "black" }}>
            <Toolbar>
              <Sidebar
                navItems={navItems}
                navItemsSpanish={navItemsSpanish}
                submenuItems={submenuItems}
              />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Box
                    component="img"
                    sx={{
                      height: 150,
                      marginTop: 3,
                      marginLeft: 9,
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                    alt="CES LOGO"
                    title="CES LOGO"
                    src={CESLogo}
                  />
                </Link>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                    textAlign: "center",
                  }}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 150,
                        marginTop: 3,
                        marginLeft: 2,
                        textAlign: "center",
                      }}
                      alt="CES LOGO"
                      title="CES LOGO"
                      src={CESLogo}
                    />
                  </Link>
                </Box>
              </Typography>
              <Button
                onClick={() => changeLang("ES")}
                sx={{
                  border: "1px solid white",
                  color: "#FDD383",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                  padding: 0.7,
                }}
              >
                <Box
                  component="img"
                  alt="spanish_lang"
                  title="spanish_lang"
                  sx={{
                    height: "23px",
                    // paddingRight: 1,
                  }}
                  src={SpanishLang}
                />
                &nbsp;ESP
              </Button>

              {/* Menu Options */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                  mr: 10,
                }}
              >
                {navItems.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link
                      to={item.href}
                      key={index}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Button
                        color="inherit"
                        className="ml-2"
                        onClick={() => resetBlogPage(item.name)}
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(0deg, rgba(214,158,72,1) 0%, rgba(253,211,131,1) 50%, rgba(214,158,72,1) 100%)",
                            color: "black",
                          },
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {item.name}
                      </Button>
                    </Link>
                    <>
                      {item.name === "Location" ? (
                        <KeyboardArrowDownIcon onClick={handleClick} />
                      ) : (
                        <></>
                      )}
                    </>
                  </React.Fragment>
                ))}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {submenuItems.map((option, index) => (
                    <Link
                      to={option.href}
                      key={index}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem key={index} onClick={handleClose}>
                        {option.name}
                      </MenuItem>
                    </Link>
                  ))}
                </Menu>
                &nbsp;|
                <Button
                  onClick={() => changeLang("ES")}
                  variant="outlined"
                  color="inherit"
                  className="ml-3"
                  sx={{
                    "&:hover": {
                      background:
                        "linear-gradient(0deg, rgba(214,158,72,1) 0%, rgba(253,211,131,1) 50%, rgba(214,158,72,1) 100%)",
                      color: "black",
                    },
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  <Box
                    component="img"
                    alt="spanish_lang"
                    title="spanish_lang"
                    sx={{
                      height: "20px",
                      // paddingRight: 1,
                    }}
                    src={SpanishLang}
                  />
                  &nbsp;ESP
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      ) : (
        <Box sx={{ flexGrow: 1 }}>
          <AppBar position="static" sx={{ height: 100, background: "black" }}>
            <Toolbar>
              <Sidebar
                navItems={navItems}
                navItemsSpanish={navItemsSpanish}
                submenuItems={submenuItems}
              />
              <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
                <Link to="/" style={{ textDecoration: "none" }}>
                  <Box
                    component="img"
                    sx={{
                      height: 150,
                      marginTop: 3,
                      marginLeft: 9,
                      display: {
                        xs: "none",
                        sm: "none",
                        md: "block",
                        lg: "block",
                      },
                    }}
                    alt="CES LOGO"
                    title="CES LOGO"
                    src={CESLogo}
                  />
                </Link>
                <Box
                  sx={{
                    display: {
                      xs: "block",
                      sm: "block",
                      md: "none",
                      lg: "none",
                    },
                    textAlign: "center",
                  }}
                >
                  <Link to="/" style={{ textDecoration: "none" }}>
                    <Box
                      component="img"
                      sx={{
                        height: 150,
                        marginTop: 3,
                        marginLeft: 2,
                        textAlign: "center",
                      }}
                      alt="CES LOGO"
                      title="CES LOGO"
                      src={CESLogo}
                    />
                  </Link>
                </Box>
              </Typography>
              <Button
                variant="outlined"
                onClick={() => changeLang("EN")}
                sx={{
                  border: "1px solid white",
                  color: "#FDD383",
                  display: {
                    xs: "block",
                    sm: "block",
                    md: "none",
                    lg: "none",
                  },
                  padding: 0.7,
                }}
              >
                <Box
                  component="img"
                  alt="english_lang"
                  title="english_lang"
                  sx={{
                    height: "23px",
                  }}
                  src={EnglishLang}
                />
                &nbsp;ENG
              </Button>

              {/* Menu Options */}
              <Box
                sx={{
                  display: {
                    xs: "none",
                    sm: "none",
                    md: "block",
                    lg: "block",
                  },
                  mr: 10,
                }}
              >
                {navItemsSpanish.map((item, index) => (
                  <React.Fragment key={index}>
                    <Link
                      key={index}
                      to={item.href}
                      style={{ textDecoration: "none", color: "white" }}
                    >
                      <Button
                        color="inherit"
                        className="ml-2"
                        onClick={() => resetBlogPage(item.name)}
                        sx={{
                          "&:hover": {
                            background:
                              "linear-gradient(0deg, rgba(214,158,72,1) 0%, rgba(253,211,131,1) 50%, rgba(214,158,72,1) 100%)",
                            color: "black",
                          },
                          fontWeight: "bold",
                          textTransform: "none",
                        }}
                      >
                        {item.name}
                      </Button>
                    </Link>
                    <>
                      {item.name === "Ubicación" ? (
                        <KeyboardArrowDownIcon onClick={handleClick} />
                      ) : (
                        <></>
                      )}
                    </>
                  </React.Fragment>
                ))}
                <Menu
                  anchorEl={anchorEl}
                  open={Boolean(anchorEl)}
                  onClose={handleClose}
                >
                  {submenuItems.map((option, index) => (
                    <Link
                      to={option.href}
                      key={index}
                      style={{ textDecoration: "none", color: "black" }}
                    >
                      <MenuItem key={index} onClick={handleClose}>
                        {option.name}
                      </MenuItem>
                    </Link>
                  ))}
                </Menu>
                &nbsp;|
                <Button
                  onClick={() => changeLang("EN")}
                  variant="outlined"
                  color="inherit"
                  className="ml-3"
                  sx={{
                    "&:hover": {
                      background:
                        "linear-gradient(0deg, rgba(214,158,72,1) 0%, rgba(253,211,131,1) 50%, rgba(214,158,72,1) 100%)",
                      color: "black",
                    },
                    fontWeight: "bold",
                    textTransform: "none",
                  }}
                >
                  <Box
                    component="img"
                    alt="english_lang"
                    title="english_lang"
                    sx={{
                      height: "20px",
                      // paddingRight: 1,
                    }}
                    src={EnglishLang}
                  />
                  &nbsp;ENG
                </Button>
              </Box>
            </Toolbar>
          </AppBar>
        </Box>
      )}

      {/* PC View */}
      <Box
        textAlign={"center"}
        sx={{
          backgroundColor: "#c03234",
          border: "4px dashed white",
          color: "white",
          display: {
            xs: "none",
            sm: "none",
            md: "block",
            lg: "block",
          },
        }}
      >
        <div id="rssBlock">
          <div className="cnnContents">
            <Typography
              className="pt-4 pb-5 marqueeStyle2"
              variant="body1"
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "white",
                fontFamily: "Ash",
              }}
            >
              15% OFF FIRST SERVICE
            </Typography>
          </div>
        </div>
      </Box>

      {/* Mobile View */}
      <Box
        textAlign={"center"}
        sx={{
          backgroundColor: "#c03234",
          border: "4px dashed white",
          color: "white",
          display: {
            xs: "block",
            sm: "block",
            md: "none",
            lg: "none",
          },
        }}
      >
        <div className="marquee">
          <div>
            <span
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "white",
                fontFamily: "Ash",
              }}
            >
              15% OFF FIRST SERVICE
            </span>
            <span
              style={{
                fontWeight: "bold",
                fontSize: 20,
                color: "white",
                fontFamily: "Ash",
              }}
            >
              15% OFF FIRST SERVICE
            </span>
          </div>
        </div>
      </Box>

      {/* Speed Dial Button */}
      {/* <Backdrop open={open} /> */}
      <SpeedDial
        ariaLabel="SpeedDial tooltip example"
        color="secondary"
        sx={{
          position: "fixed",
          bottom: 32,
          right: 32,
          zIndex: 1,
          "& .MuiFab-primary": {
            background: "linear-gradient(0deg, #ECAA46 0%, #FFCB68 100%)",
            color: "black",
            height: 70,
            width: 70,
          },
        }}
        icon={<PhoneEnabledIcon sx={{ fontSize: 35 }} />}
        onClick={() => handleOpen()}
      ></SpeedDial>
    </>
  );
};

export default Navbar;
